import React, { useState, useEffect } from 'react';
import './UserProgress.css';
import axios from 'axios';
// import Tree from '@naisutech/react-tree';
import { useLocation } from 'react-router-dom';
import { Modal } from 'antd';
import {
  CaretDownOutlined,
  CaretRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlayCircleFilled,
} from '@ant-design/icons';
// import UserPogress from '../userProgress.json';

const UsersProgress = () => {
  const location = useLocation();
  const {
    // mobile,
    token,
  } = location.state;

//   const [count, setCount] = useState(0);
  const [userProgress, setUserProgress] = useState(null);
  const [showTopics, setShowTopics] = useState(null);
  const [showConcept, setShowConcept] = useState(null);
  const [showVideo, setShowVideo] = useState(null);
  const [displayVideo, setDisplayVideo] = useState(null);
  const [watchTime, setWatchTime] = useState(null);
  const [videoList, setVideoList] = useState(null);
  const [conceptProgressCompleted, setConceptProgressCompleted] = useState(null);
  // const [
  //   _conceptProgressOngoing,
  //   setConceptProgressOngoing,
  // ] = useState(null);
  const [showQuizResult, setShowQuizResult] = useState(false);
  const [quizModalIndex, setQuizModalIndex] = useState(null);
  // const [questionBank, setQuestionBank] = useState(null);

const fetchModules = () => {
         const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/module`;
        // eslint-disable-next-line prefer-template
        const config = { headers: { Authorization: 'Bearer ' + token } };
  axios.get(url, config).then((response) => {
    if (response.status === 200 && response.data.error === false) {
      setUserProgress(response.data);
    } else {
      Modal.error({ content: 'Unable to fetch Modules' });
    }
  }).catch((err) => Modal.error({ content: err.response.data.errorMessage }));
};
        // {
        //     // console.log(err);
        // });

const getVideo = (value) => {
    const config = {
      headers: {
        // eslint-disable-next-line prefer-template
        Authorization: 'Bearer ' + token,
      },
    };
  axios
    .get(
      `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/video/${value}`,
      null,
      config,
    )
    .then((response) => {
      if (response.status === 200) {
        setShowVideo(response.data.video.conceptId);
        setShowTopics(response.data.video.moduleId);
        setShowConcept(response.data.video.topicId);
        setDisplayVideo(response.data.video);
      }
    });
      // .catch((err) => {// console.log('ERR', err?.response)});
  };

const fetchProgressSummary = () => {
         const url = `${process.env.REACT_APP_USER_URL}/progress`;
        // eslint-disable-next-line prefer-template
        const config = { headers: { Authorization: 'Bearer ' + token } };
        axios.get(url, config).then((response) => {
          // console.log('PROGRESS', response);
            if (response.status === 200) {
              // eslint-disable-next-line max-len
              //  // console.log('VIDEO ID', response.data.progressSummary[0].videos[response.data.progressSummary[0].videos.length - 1].videoId);
              // eslint-disable-next-line max-len
              getVideo(response.data.progressSummary[0].videos[response.data.progressSummary[0].videos.length - 1].videoId);
              // eslint-disable-next-line max-len
              if (response.data.progressSummary[0].videos[response.data.progressSummary[0].videos.length - 1].watchTime) {
                // eslint-disable-next-line max-len
                setWatchTime(response.data.progressSummary[0].videos[response.data.progressSummary[0].videos.length - 1].watchTime);
              }
            } else {
                Modal.error({ content: 'Unable to fetch Progress' });
            }
        }).catch((err) => Modal.error({ content: err.response.data.errorMessage }));
        // console.log(err);
};

// console.log(watchTime);

useEffect(() => {
  fetchModules();
  fetchProgressSummary();
  // getProgress();
  // getVideo();
}, []);
// console.log(mobile);

  const getVideos = () => {
    const config = {
      headers: {
        // eslint-disable-next-line prefer-template
        Authorization: 'Bearer ' + token,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/video/byconcept/${showVideo}`,
        null,
        config,
      )
      .then((response) => {
        if (response.status === 200) {
          setVideoList(response.data.videos);
        }
      });
      // .catch((err) => // console.log('ERR', err?.response));
  };

  const getConceptProgress = () => {
    const config = {
      headers: {
        // eslint-disable-next-line prefer-template
        Authorization: 'Bearer ' + token,
      },
    };
    axios
      .get(`${process.env.REACT_APP_USER_URL}/progress/concept/${showVideo}`, config)
      .then((response) => {
        // console.log(response);
        setConceptProgressCompleted(response.data.completedVideos);
        // setConceptProgressOngoing(response.data.ongoingVideo);
      });
      // .catch((err) => // console.log('ERR for Progress', err?.response?.data));
  };

  useEffect(() => {
    getVideos();
    if (showVideo) {
      getConceptProgress();
    }
  }, [showVideo]);

  // useEffect(() => {
  //   if (quizModalIndex) {
  //    setQuestionBank(conceptProgressCompleted[quizModalIndex].questionBank);
  //   }
  // }, [quizModalIndex]);

  // // console.log('vygvgyvv', conceptProgressCompleted[quizModalIndex].questionBank);
  // console.log(conceptProgressOngoing);
  // console.log(showQuizResult);
  // console.log(questionBank);
  // console.log('COMPLETED', conceptProgressCompleted);

    return (
        <div style={{
            backgroundColor: 'white',
            alignSelf: 'center',
            width: '100%',
            justifyContent: 'center',
            paddingLeft: 50,
            paddingRight: 50,
            }}>
            {userProgress && userProgress.modules.map((module, index) => <div
            /* eslint no-underscore-dangle: 0 */
            key={module._id}
            style={{
              marginLeft: 40,
              marginTop: 10,
              borderColor: 'grey',
              borderWidth: 1,
              cursor: 'pointer',
            }}
            onClick = {() => setShowTopics(module._id)}
            >
            <div style={{
              backgroundColor: '#f7f7f7',
              height: 40,
              flexDirection: 'row',
              display: 'flex',
            }}>
            {showTopics === module._id ? <CaretDownOutlined style={{
              fontSize: 20,
              paddingLeft: 10,
              alignSelf: 'center',
              color: 'tomato',
              }} /> : <CaretRightOutlined style={{
                fontSize: 20,
                paddingLeft: 10,
                alignSelf: 'center',
                color: 'tomato',
                }} />}
            <p style={{
                fontSize: 22,
                paddingLeft: 20,
                marginBottom: 10,
                fontWeight: 'bold',
                cursor: 'pointer',
                }}> {module?.name?.english ? module?.name?.english : `Module name ${index + 1}` }</p>
            </div>
            {showTopics && showTopics === module._id
            && module.topics.map((topic) => <div style={{ borderWidth: 1, cursor: 'pointer' }}
            onClick={() => setShowConcept(topic._id)}
            key={topic._id}>
                <div
                style={{
                backgroundColor: '#f7f7f7',
                height: 40,
                marginBottom: 5,
                marginTop: 5,
                flexDirection: 'row',
                display: 'flex',
              }}
                >
                {showConcept === topic._id ? <CaretDownOutlined style={{
              fontSize: 20,
              paddingLeft: 40,
              alignSelf: 'center',
              color: 'tomato',
              }} /> : <CaretRightOutlined style={{
                fontSize: 20,
                paddingLeft: 50,
                alignSelf: 'center',
                color: 'tomato',
                }} />}
                <p
                /* eslint no-underscore-dangle: 0 */
                style={{
                    fontSize: 18,
                    paddingTop: 6,
                    paddingLeft: 10,
                    fontWeight: 'bold',
                    marginBottom: 10,
                    cursor: 'pointer',
                    }}>{topic?.name?.english ? topic?.name?.english : 'N/A' }</p>
                  </div>
                {showConcept === topic._id
                && topic.concepts.map((concept) => <div key={concept}>
                <div
                onClick={() => setShowVideo(concept._id)}
                style={{
                backgroundColor: '#f7f7f7',
                // marginLeft: 40,
                height: 40,
                marginBottom: 5,
                marginTop: 5,
                flexDirection: 'row',
                display: 'flex',
              }}
                >
              {showVideo === concept._id ? <CaretDownOutlined style={{
              fontSize: 20,
              paddingLeft: 80,
              alignSelf: 'center',
              color: 'tomato',
              }} /> : <CaretRightOutlined style={{
                fontSize: 20,
                paddingLeft: 80,
                alignSelf: 'center',
                color: 'tomato',
                }} />}
                <p
                /* eslint no-underscore-dangle: 0 */
                key={concept._id}
                style={{
                    paddingTop: 5,
                    fontSize: 18,
                    paddingLeft: 10,
                    fontWeight: 'bold',
                    }}>{concept?.name?.english ? concept?.name?.english : 'N/A'}</p>
                </div>
                {/* {concept._id === showVideo &&
                <div style={{ flexDirection: 'row', marginLeft: 100, display: 'flex' }}>
                  <div>
                  <PlayCircleFilled style={{ color: 'tomato', fontSize: 22, paddingTop: 10 }}/>
                  </div>
                  {displayVideo && <p style={{
                    fontSize: 20,
                    paddingTop: 4,
                    marginLeft: 10,
                    marginRight: 10,
                    }}>{displayVideo.title}</p>}
                    {watchTime &&
                      <p style={{ fontSize: 16, fontWeight: 'bold', paddingTop: 8 }}>
                      {'('}{watchTime}{'s)'}</p>}
                  </div>
                } */}
                {conceptProgressCompleted && conceptProgressCompleted.length > 0
                && conceptProgressCompleted[0].conceptId === concept._id
                ? concept._id === showVideo && videoList && videoList.map((video, length) => <div
                key={video._id}
                style={{
                backgroundColor: '#f7f7f7',
                height: 40,
                marginBottom: 5,
                marginTop: 5,
                flexDirection: 'row',
                display: 'flex',
                }}>
                  {displayVideo && displayVideo._id === video._id ? <div
                  style={{ flexDirection: 'row', display: 'flex' }}>
                    <PlayCircleFilled style={{
                      color: 'tomato',
                      fontSize: 18,
                      paddingTop: 9,
                      paddingLeft: 150,
                      }}/>
                    <p style={{
                    fontSize: 16,
                    paddingTop: 4,
                    width: 550,
                    marginLeft: 10,
                    marginRight: 10,
                    }}>{video.title}
                    </p>
                    {conceptProgressCompleted && conceptProgressCompleted[length]
                    && length <= conceptProgressCompleted.length
                    && <p style={{
                    fontSize: 14,
                    paddingTop: 7,
                    width: 200,
                    marginLeft: 10,
                    marginRight: 10,
                    fontWeight: 'bold',
                    justifyContent: 'flex-end',
                    }}>( {conceptProgressCompleted[length].startTime} )</p>
                    }
                    {watchTime && <p style={{ fontSize: 16, fontWeight: 'bold', paddingTop: 8 }}>{'('}{watchTime}{'s)'}</p>}
                  </div>
                  : <div
                  style={{ flexDirection: 'row', display: 'flex' }}>
                    <PlayCircleFilled style={{
                      color: 'lightgrey',
                      fontSize: 18,
                      paddingTop: 9,
                      paddingLeft: 150,
                      }}/>
                    <p style={{
                    fontSize: 16,
                    width: 550,
                    overflow: 'hidden',
                    paddingTop: 4,
                    marginLeft: 10,
                    marginRight: 10,
                    }}>{video.title}</p>
                    {conceptProgressCompleted && conceptProgressCompleted[length]
                    && length <= conceptProgressCompleted.length
                    && <p style={{
                    fontSize: 14,
                    paddingTop: 7,
                    width: 200,
                    marginLeft: 10,
                    marginRight: 10,
                    fontWeight: 'bold',
                    justifyContent: 'flex-end',
                    }}>( {conceptProgressCompleted[length].startTime} )</p>
                    }
                    {conceptProgressCompleted && conceptProgressCompleted[length + 1]
                    && (length < conceptProgressCompleted.length
                      || length === conceptProgressCompleted.length)
                    && <p
                    onClick={() => {
                        setShowQuizResult(true);
                        setQuizModalIndex(length + 1);
                      }
                    }
                    style={
                    {
                    fontSize: 14,
                    paddingTop: 7,
                    fontWeight: 'bold',
                    marginLeft: 10,
                    marginRight: 10,
                    justifyContent: 'flex-end',
                    }}>Quiz Result: ( {conceptProgressCompleted[length].score}/
                    {conceptProgressCompleted[length].questionBank.length} )</p>
                    }
                    </div>}
                  </div>)
                 : concept._id === showVideo && videoList && videoList.map((video) => <div
                key={video._id}
                style={{
                backgroundColor: '#f7f7f7',
                height: 40,
                marginBottom: 5,
                marginTop: 5,
                flexDirection: 'row',
                display: 'flex',
                }}>
                  {displayVideo && displayVideo._id === video._id ? <div
                  style={{ flexDirection: 'row', display: 'flex' }}>
                    <PlayCircleFilled style={{
                      color: 'tomato',
                      fontSize: 18,
                      paddingTop: 9,
                      paddingLeft: 150,
                      }}/>
                    <p style={{
                    fontSize: 16,
                    paddingTop: 4,
                    marginLeft: 10,
                    marginRight: 10,
                    }}>{video.title}
                    </p>
                    {watchTime && <p style={{ fontSize: 16, fontWeight: 'bold', paddingTop: 8 }}>{'('}{watchTime}{'s)'}</p>}
                  </div>
                  : <div
                  style={{ flexDirection: 'row', display: 'flex' }}>
                    <PlayCircleFilled style={{
                      color: 'lightgrey',
                      fontSize: 18,
                      paddingTop: 9,
                      paddingLeft: 150,
                      }}/>
                    <p style={{
                    fontSize: 16,
                    paddingTop: 4,
                    marginLeft: 10,
                    marginRight: 10,
                    }}>{video.title}</p>
                    </div>}
                  </div>)}
                </div>)}
              </div>)}
        </div>)}
            {/* <p onClick = {() => setCount(count + 1)}> { count } User Progress</p> */}
            <Modal
            title="Quiz Result"
              visible={showQuizResult}
              onCancel={() => setShowQuizResult(false)}
              footer={[]}
            >
              {quizModalIndex && conceptProgressCompleted[quizModalIndex].questionBank
              && conceptProgressCompleted[quizModalIndex].questionBank.map((p) => <div
              key={p}
              style={{ flexDirection: 'row', display: 'flex' }}
              >
                <p style={{ width: '90%', fontSize: 18 }}>{p.question}</p>
                <p>{p.isAnsweredRight === true ? <CheckCircleOutlined style={{ color: 'green', fontSize: 20 }}/>
                : <CloseCircleOutlined style={{ color: 'red', fontSize: 20 }}/>}</p>
                </div>)}
            </Modal>
        </div>
    );
};

export default UsersProgress;
