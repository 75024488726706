/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable comma-dangle */
/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React, {
  useState,
  useEffect,
} from 'react';
import {
  Table,
  Form,
  Typography,
  Modal,
  Button,
  Select,
  Tooltip,
  Input,
  DatePicker, TimePicker,
  Tag,
  Upload,
  Row, Col,
  Switch,
  AutoComplete,
} from 'antd';
import './Tableheader.css';
import axios from 'axios';
import moment from 'moment';
import ReactPlayer from 'react-player';
import {
  DeleteFilled, EditFilled, LinkOutlined, CopyOutlined, UploadOutlined,
} from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Swal from 'sweetalert2';
import FormData from 'form-data';
import availableLanguages from '../../Components/AvailableLanguages/AvailableLanguages';

const AUTH_TOKEN = localStorage.getItem('token');
const { Option } = Select;
const props = {
  // accept: 'audio/*,video/*,image/*',
  accept: 'video/*',
  beforeUpload: () => false,
  listType: 'picture',
};
const thumbnailProps = {
  accept: 'image/*',
  beforeUpload: () => false,
  listType: 'picture',
};

// eslint-disable-next-line object-curly-newline
function Tableheader({ sessions, roles, fetchSessions, businessIdeas, mentorDetails, }) {
  const [groupsKeywords, setGroupsKeywords] = useState([]);
  const [fileForDeletion, setFileForDeletion] = useState(null);
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [selectedFile, setselectedFile] = useState(false);
  const [fileVisible, setfileVisible] = useState(false);
  const [eventVisible, setEventVisible] = useState(false);
  const [fileConfirmLoading, setfileConfirmLoading] = useState(false);
  const [formValVisibility, setFormValVisibility] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [eventDate, setEventDate] = useState(null);
  const [sessionId, setSessionId] = useState('');
  const [duration, setDuration] = useState('');
  const [selectedkeywords, setSelectedKeywords] = useState([]);
  const [isUploadVideo, setisUploadVideo] = useState(false);
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);

  const { TextArea } = Input;

  const fileHandleCancel = () => {
    setfileVisible(false);
    setEventVisible(false);
  };

  async function fetchKeywords() {
    const url = `${process.env.REACT_APP_LIBRARY_URL}/synonyms/keywords`;
    axios.get(url).then((response) => {
      if (response.status) {
        setGroupsKeywords(response.data.keywords);
      }
    });
  }

  const mockVal = (str, repeat = 1) => ({
    value: str.repeat(repeat),
  });

  const onSearch = (searchText) => {
    setOptions(
      !searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)],
    );
  };

  const onSelect = (data) => {
    console.log('onSelect', data);
  };

  const onChange = (data) => {
    setValue(data);
  };

  const deleteFile = (row) => {
    const { _id } = row;
    const url = `${process.env.REACT_APP_SESSION_API_URL}/sessions/${_id}`;
    const config = { headers: { Authorization: AUTH_TOKEN, 'x-access-token': localStorage.getItem('token') } };

    axios.delete(url, config).then((response) => {
      if (response.status === 200 && response.data.error === false) {
        fetchSessions();
        setConfirmDeletion(false);
        Modal.success({ content: `${response.data.message}` });
      }
    }).catch((err) => {
      Modal.error({ content: err.response.data.errorMessage });
    });
  };
  const getTimeDiff = (sTime, eTime) => {
    let time = '';
    const hours = parseInt(
      moment
        .duration(moment(eTime, 'HH:mm').diff(moment(sTime, 'HH:mm')))
        .asHours(),
      10,
    );
    if (hours > 1) time = `${hours * 60}  hours `;
    else if (hours === 1) time = `${hours * 60}  hour`;
    const mins = parseInt(
      moment
        .duration(moment(eTime, 'HH:mm').diff(moment(sTime, 'HH:mm')))
        .asMinutes() % 60,
      10,
    );
    if (mins > 1) time += `${mins} minutes `;
    else if (mins === 1) time += `${mins} minute `;
    if (time === '') return false;
    return time;
  };
  const onFinish = (values) => {
    setfileConfirmLoading(true);
    const { _id } = selectedFile;
    const url = `${process.env.REACT_APP_SESSION_API_URL}/sessions/${_id}`;
    const config = { headers: { Authorization: AUTH_TOKEN } };
    // eslint-disable-next-line quotes, no-useless-escape
    const sessionName = new RegExp(`^[\.a-zA-Z0-9_:' +-]*$`);
    const name = values.eventName;
    if (sessionName.test(name) === false) {
      Modal.error({ content: `${'Special characters are not allowed'}` });
      return;
    }
    const fileForm = new FormData();
    const durationEvent = getTimeDiff(startTime, endTime);
    fileForm.append('eventName', values.eventName);
    fileForm.append('description', values.description);
    if (values.keywords) {
      fileForm.append('keywords', selectedkeywords);
    }
    fileForm.append('startTime', startTime);
    fileForm.append('endTime', endTime);
    fileForm.append('eventDuration', durationEvent.split(' ')[0]);
    fileForm.append('eventDate', eventDate);
    fileForm.append('businessIdeas', values.businessIdeas);
    fileForm.append('language', values.language);

    if (values.thumbnail && values.thumbnail.file) {
      if (values.thumbnail.file.type.includes('audio')) fileForm.append('thumbnail', 'audio');
      else if (values.thumbnail.file.type.includes('video')) fileForm.append('thumbnail', 'video');
      else fileForm.append('thumbnail', values.thumbnail.file.type);
      fileForm.append('thumbnail', values.thumbnail.file);
    } else fileForm.append('thumbnail', selectedFile.thumbnail || '');
    axios.put(url, fileForm, config).then((response) => {
      if (response) {
        Modal.success({ content: `${response.data.message}` });
        fetchSessions();
        fileHandleCancel();
      } else {
        Modal.error({ content: 'Event Not Updated, Please Try Again' });
      }
    }).catch((err) => {
      console.log(err);
      Modal.error({ content: 'Error in Update, Please Try Again' });
    });
  };
  useEffect(() => {
    fetchSessions();
    fetchKeywords();
  }, []);
  // ------------------------- CONFIRMATION MODAL -------------------------
  const showFileModal = (row) => {
    setfileVisible(true);
    setselectedFile(row);
    setStartTime(row.startTime);
    setEndTime(row.endTime);
    setEventDate(row.date);
    setDuration(row.duration);
    setSelectedKeywords(row.keywords);
    setFormValVisibility(row.visibility === 'Public');
  };
  const showEventModal = (row) => {
    setEventVisible(true);
    setselectedFile(row);
    setEventDate(row.date);
    setSessionId(row.sessionId);
    setFormValVisibility(row.visibility === 'Public');
  };
  // eslint-disable-next-line no-trailing-spaces
  const onChangeEndTime = (t) => {
    const durationEvent = getTimeDiff(startTime, t);
    setEndTime(t);
    setDuration(durationEvent.split(' ')[0]);
  };
  const columns = [
    {
      title: 'Event Name',
      dataIndex: 'eventName',
      align: 'center',
      width: '30vw',
      ellipsis: true,
      render: (_, record) => (
        <div style={{ cursor: 'pointer', color: 'blue' }}>
          <p className='event-description'>{_}</p>
          <span style={{ marginLeft: '25px', float: 'right' }} title='Delete session' >
            <Typography.Link
              onClick={() => { setFileForDeletion(record); setConfirmDeletion(true); }} >
              <DeleteFilled
                style={{ color: 'tomato', fontSize: 20 }}
              />
            </Typography.Link>
          </span>
          <span style={{ marginLeft: '20px', float: 'right' }} title='Event link'>
            <Typography.Link onClick={() => showEventModal(record)}>
              <LinkOutlined style={{ color: 'tomato', fontSize: 20 }} />
            </Typography.Link>
          </span>
          <span style={{ marginLeft: '15px', float: 'right' }} title='Edit'><Typography.Link
            onClick={() => showFileModal(record)}
          >
            <EditFilled style={{ color: 'tomato', fontSize: 20 }} />
          </Typography.Link>
          </span>
          <span style={{ color: 'tomato', marginLeft: '10px', float: 'right' }} title={record.description || 'No description to show!'}>
            &#9432;
          </span>
        </div>
      ),
    },
    {
      title: 'Time',
      align: 'center',
      ellipsis: true,
      render: (_, record) => (
        <span><Tag color='magenta'>{record.startTime}-{record.endTime}</Tag>
          <Tag color='blue'>{record.date}</Tag>
          <Tag color='geekblue'>{record.duration} minutes</Tag></span>
      ),
    },
    {
      title: 'Last Updated',
      dataIndex: ['metaInfo', 'updated', 'by'],
      align: 'center',
      // eslint-disable-next-line react/display-name
      // eslint-disable-next-line no-trailing-spaces
      render: (_, record) => {
        const { metaInfo } = record;
        return (
          <span>
            on <Tag color='cyan'>{moment(metaInfo.updated.at).format('DD/MM/YYYY')}</Tag>at <Tag color='orange'>{moment(metaInfo.updated.at).format('HH:mm')}</Tag>by <Tag color='lime'>{metaInfo.updated.by.name}</Tag>
          </span>
        );
      },
    },
  ];
  function onUploadChecked(checked) {
    setisUploadVideo(checked);
  }

  // SEARCH FILTER
  // eslint-disable-next-line no-unused-vars
  return (
    <div className='questionTableContainer'>
      <Form component={false}>
        <Table
          style={{ width: '100%', paddingRight: 20 }}
          bordered
          showHeader={true}
          className='questionBankTable'
          dataSource={sessions}
          columns={columns}
          rowClassName="editable-row"
          pagination={{ position: ['bottomRight'], pageSize: ['500'] }}
          rowKey='id'
        />
      </Form>
      <Modal
        visible={confirmDeletion}
        onCancel={() => setConfirmDeletion(false)}
        onOk={() => deleteFile(fileForDeletion)}
        width={'40%'}
        style={{ marginTop: 150 }}
        getContainer={false}
      >
        <p> Sure want to delete
          <strong>{' '}{fileForDeletion && fileForDeletion.id}</strong> ?
        </p>
      </Modal>
      <Modal
        title="Edit Event"
        onCancel={fileHandleCancel}
        width="60%"
        visible={fileVisible}
        confirmLoading={fileConfirmLoading}
        footer={[]}
        getContainer={false}
      >
        <Form
          name="basic-event"
          onFinish={onFinish}
          initialValues={selectedFile}
          layout={'vertical'}
        >
          <div className="test" style={{ padding: 10 }}>
            <Form.Item
              name="eventName"
              label="Event Title (Max 200 characters. No special characters.)"
              rules={[
                {
                  required: true,
                  message: 'Enter a Event Title!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description Description (Max 2000 characters. No special characters.)"
              rules={[
                {
                  required: true,
                  message: 'Enter a Event Description!',
                },
              ]}
            >
              <TextArea className='textArea' rows={6} maxLength={2000} />
            </Form.Item>
            <div className='event-box'>
              <Form.Item
                label="Event Date"
                rules={[
                  {
                    required: true,
                    message: 'Enter a  Event Date!',
                  },
                ]}
              >
                <DatePicker defaultValue={moment(eventDate, 'DD-MM-YYYY')} onChange={(d) => setEventDate(moment(d).format('DD-MM-YYYY'))} />
              </Form.Item>
              <Form.Item
                label="Start Time"
                rules={[
                  {
                    required: true,
                    message: 'Enter a Start Time!',
                  },
                ]}
              >
                <TimePicker use12Hours format="h:mm a"
                  onChange={(t) => setStartTime(moment(t).format('HH:mm'))}
                  picker='time'
                  defaultValue={moment(startTime, 'HH:mm a')} />
              </Form.Item>
              <Form.Item
                label="End Time"
                rules={[
                  {
                    required: true,
                    message: 'Enter a End Time!',
                  },
                ]}
              >
                <TimePicker use12Hours format="h:mm a" picker='time'
                  onChange={(t) => onChangeEndTime(moment(t).format('HH:mm'))}
                  defaultValue={moment(endTime, 'HH:mm a')} />
              </Form.Item>
              {endTime && startTime && startTime < endTime ? <Form.Item
                label="Event Duration"
                rules={[
                  {
                    required: true,
                    message: 'Enter a Event Duration!',
                  },
                ]}
              >
                <Input style={{ color: 'black' }} disabled={true} defaultValue={duration} />
              </Form.Item> : null}
            </div>
            {selectedFile.thumbnail || '' ? <Form.Item label="Thumbnail">
              <Row><Col>
                <img width={300} height={200} src={selectedFile.thumbnail || ''} /></Col>
                <Button className='button-remove-thumbnail' onClick={() => {
                  Swal.fire({
                    title: 'Are you sure you want to remove thumnail for this session?',
                    focusConfirm: false,
                    showCancelButton: true,
                    cancelButtonText: 'No, Cancel',
                    confirmButtonText: 'Yes, Delete',
                    cancelButtonAriaLabel: 'No, Cancel',
                    confirmButtonAriaLabel: 'Yes, Delete',
                  }).then((result) => {
                    if (result.value) {
                      setselectedFile({ ...selectedFile, thumbnail: '' });
                    }
                  });
                }}><i className="fa fa-trash" aria-hidden="true" /> Remove Thumbnail
                </Button></Row>
            </Form.Item> : <Form.Item
              label="Thumbnail (800 x 800)"
              name="thumbnail" >
              <Upload {...thumbnailProps} maxCount={1} >
                <Button icon={<UploadOutlined />}>Upload Thumbnail</Button>
              </Upload>
            </Form.Item>}
            <div>
              <Form.Item name="language" label="Language"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select a option and change input text above"
                  allowClear
                >
                  {
                    availableLanguages.map((language, index) => <Option
                      value={language.value}
                      key={index}>{language.text}</Option>)
                  }
                </Select>
              </Form.Item>
              <Form.Item
                name="keywords"
                label="Groups"
                rules={[{ required: true, message: 'Add Groups' }]}
              >
                <Select
                  placeholder="Add more Groups"
                  allowClear
                  mode="multiple"
                >
                  {groupsKeywords.map((word) => (
                    // eslint-disable-next-line no-underscore-dangle
                    <Option key={word._id} value={word._id}>
                      {word.keyword}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="businessIdeas" label="Business Ideas"
                rules={[{ required: true, message: 'Please select at least one business idea that goes with this video.' }]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select one or more business ideas that apply"
                  allowClear
                >
                  {businessIdeas.map((idea) => (
                    <Option key={idea.combine} label={idea._inEnglish}
                      title={idea._inEnglish}
                      value={idea._id}>{idea.inEnglish}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="level" label="Level"
                rules={[{ required: true, message: 'Please select a level' }]}
              >
                <Select
                  placeholder="Select business level"
                  allowClear
                >
                  {[1, 2, 3, 4, 5, 6, 7].map((level) => (
                    <Option key={level} label={level}
                      title={level}
                      value={level}>{`Level - ${level}`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <Form.Item name="attendees"
          label="Mentor Name"
              rules={[{ required: true }]}
            >
              <Select
              mode="multiple"
                placeholder="Select an option "
                allowClear
              >
                {
                  mentorDetails.map((mentor, index) => <Option
                    value={mentor._id}
                    key={index}>{mentor.name}</Option>)
                }
              </Select>
            </Form.Item>
            <div className="mt-4 align-right">
              <Button
                shape="round"
                htmlType="submit"
                style={{
                  background: 'tomato',
                  color: 'white',
                  alignSelf: 'flex-end',
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
      <Modal
        title="Event Link"
        onCancel={fileHandleCancel}
        width="60%"
        visible={eventVisible}
        confirmLoading={fileConfirmLoading}
        footer={[]}
        getContainer={false}>
        <Form
          name="basic-event"
          layout={'vertical'}
          initialValues={selectedFile}
        >
          {roles.map((data) => <>
            <div style={{ padding: 10 }}>
              <Form.Item
                name={data}
                label={data}
              >
                <Input className='link-input' disabled={true} defaultValue={`https://letsndorse.app.100ms.live/preview/${sessionId}/${data}`} />
                <span style={{ cursor: 'pointer', marginLeft: '2vw', fontSize: '18px' }} title='Click to copy event link'>
                  <CopyToClipboard onCopy={() => Modal.success({ content: 'Event link is copied' })}
                    text={`https://letsndorse.app.100ms.live/preview/${sessionId}/${data}`}>
                    <CopyOutlined style={{ color: 'orange' }} /></CopyToClipboard>
                </span>
              </Form.Item>
            </div>
          </>)}
        </Form>
      </Modal>
      {/* <ToastContainer/> */}
    </div>
  );
}

export default Tableheader;
