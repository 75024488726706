/* eslint-disable no-undef */
/* eslint-disable no-useless-escape */
/* eslint-disable no-underscore-dangle */
import {
 Button, Modal, Select, Upload, Form, Input,
} from 'antd';
import React, { useEffect, useState } from 'react';
import './QuotationScreen.css';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import FormData from 'form-data';
import availableLanguages from '../../Components/AvailableLanguages/AvailableLanguages';

const AUTH_TOKEN = localStorage.getItem('token');
const { Option } = Select;
function QuotationModal({ show, close }) {
  const [groupsKeywords, setGroupsKeywords] = useState([]);
   const [fileConfirmLoading, setfileConfirmLoading] = useState(false);
  const quotesProps = {
    accept: 'image/*',
    beforeUpload: () => false,
    listType: 'picture',
  };
  async function fetchKeywords() {
    const url = `${process.env.REACT_APP_LIBRARY_URL}/synonyms/keywords`;
    axios.get(url).then((response) => {
      if (response.status) {
        setGroupsKeywords(response.data.keywords);
      }
    });
  }
  useEffect(() => {
    fetchKeywords();
  }, []);

   const onFinish = (values) => {
    setfileConfirmLoading(true);

    const url = `${process.env.REACT_APP_LIBRARY_URL}/quotes`;
     const config = { headers: { Authorization: AUTH_TOKEN } };
     const fileForm = new FormData();
      fileForm.append('title', values.title);
      fileForm.append('language', values.language);
      if (values.keywords) {
        fileForm.append('keywords', values.keywords);
        }
        if (values.Quotes && values.Quotes.file) {
            if (values.Quotes.file.type.includes('audio')) fileForm.append('thumbnail', 'audio');
            else if (values.Quotes.file.type.includes('video')) fileForm.append('thumbnail', 'video');
            else fileForm.append('thumbnail', values.Quotes.file.type);
            fileForm.append('thumbnail', values.Quotes.file);
        } else fileForm.append('thumbnail', values.Quotes || '');
          axios.post(url, fileForm, config).then((response) => {
            if (response) {
                Modal.success({ content: `${response.data.message}` });
                window.location.reload();
            } else {
                Modal.error({ content: 'File Not Added, Please Try Again' });
            }
          }).catch((err) => {
            console.log(err);
            Modal.error({ content: 'Error in Post, Please Try Again' });
          });
        };

   return (
    <Modal
      title="Add Quotes"
      visible={show}
      onCancel={close}
      width="60%"
      confirmLoading ={fileConfirmLoading}
      footer={[]}
      getContainer={false}
    >
      <Form
        name="basic-quote"
        onFinish={onFinish}
        layout={'vertical'}
      >
        <div className="test" style={{ padding: 10 }}>
          <div>
            <Form.Item label="Quotes Thumbnail (800 x 800)" name="Quotes">
              {/* <h6>Image</h6> */}
              <Upload listType="picture-card" {...quotesProps}>
                <Button icon={<UploadOutlined />}>Upload Image</Button>
              </Upload>
            </Form.Item>
          </div>
          <div className="mt-4">
            <Form.Item
              name="language"
              label="Language"
              rules={[
                {
                  required: true,
                  message: 'Select a language!',
                },
              ]}
            >
              <Select placeholder="Select an option" allowClear>
                {availableLanguages.map((language, index) => (
                  <Option value={language.value} key={index}>
                    {language.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="keywords"
              label="Group"
              rules={[{ required: true, message: 'Add Groups' }]}
            >
              <Select placeholder="Select one or more Groups" allowClear>
                {groupsKeywords.map((word) => (
                  // eslint-disable-next-line no-underscore-dangle
                  <Option key={word._id} value={word._id}>
                    {word.keyword}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item
            name="title"
            label="Quote"
            rules={[
              {
                required: true,
                message: 'Enter a Quotes!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className="mt-4 align-right">
            <Button
              // onClick={() => handleSubmit(keyword, synonyms)}
              shape="round"
              htmlType="submit"
              style={{
                background: 'tomato',
                color: 'white',
                alignSelf: 'flex-end',
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default QuotationModal;
