/* eslint-disable no-undef */
import { EditOutlined } from '@ant-design/icons';
import {
    Button,
    Input,
    Modal,
    Popover,
    Table,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import SynonymModal from './SynonymModal';

const AUTH_TOKEN = localStorage.getItem('token');

function Synonyms() {
    const [showModal, setShowModal] = useState(false);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [filter, setFilter] = useState({ filterTable: null });

    useEffect(() => setGroups([]), []);

    const fetchSynonyms = () => {
        const url = `${process.env.REACT_APP_LIBRARY_URL}/synonyms`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setGroups(response.data.synonyms);
            }
        }).catch((err) => {
            // console.log(err);
            // console.log(err.response.data);
            Modal.error({ content: err.response.data.errorMessage });
        });
    };

    useEffect(() => {
        fetchSynonyms();
    }, []);

    const addSynonyms = (data) => {
        const url = `${process.env.REACT_APP_LIBRARY_URL}/synonyms`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.post(url, data, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                fetchSynonyms();
                setSelectedGroup(null);
                setShowModal(false);
            }
        }).catch((err) => {
            // console.log(err.response);
            Modal.error({ content: err.response.data.errorMessage });
        });
    };

    const updateSynonyms = (data) => {
        /* eslint no-underscore-dangle: 0 */
        const url = `${process.env.REACT_APP_LIBRARY_URL}/synonyms/${selectedGroup._id}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.put(url, data, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                fetchSynonyms();
                setSelectedGroup(null);
                setShowModal(false);
            }
        }).catch((err) => {
            // console.log(err.response);
            Modal.error({ content: err.response.data.errorMessage });
        });
    };

    const handleSubmit = (keyword, synonyms) => {
        if (keyword && synonyms.length > 0) {
            const data = {
                keyword,
                synonyms,
            };
            if (selectedGroup) {
                updateSynonyms(data);
            } else addSynonyms(data);
        }
    };

    const search = (value) => {
        const filterTable = groups.filter((o) => Object.keys(o).some((k) => String(o[k])
            .toLowerCase().includes(value.toLowerCase())));
        setFilter({ filterTable });
    };

    const columns = [
        {
            title: 'Groups',
            dataIndex: 'keyword',
            align: 'center',
        },
        {
            title: 'Number Of Synonyms',
            dataIndex: 'synonyms',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: (cell) => <Popover content={cell.join(', ')}>
                <h6 className='mt-2 synonymCount'>{cell.length}</h6>
            </Popover>,
        },
        {
            title: 'Last updated on',
            dataIndex: 'updatedAt',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: (cell) => <span>{moment(cell).format('MMMM Do YYYY, h:mm:ss a')}</span>,
        },
        {
            title: 'Edit',
            dataIndex: '_id',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: (_, record) => <div
                className='edit-btn'
                onClick={() => {
                    setSelectedGroup(record);
                    setShowModal(true);
                }}
            >
                <EditOutlined style={{ color: 'white', alignSelf: 'center' }} />
            </div>,
        },
    ];

    return (
        <div>
            <Content className="site-layout-background header" style={{ boxShadow: '2px 1px 10px lightgrey' }}>
                <p></p>
                <h3>Synonyms</h3>
                <Button
                    onClick={() => { setSelectedGroup(null); setShowModal(true); }}
                    shape="round"
                    style={{ background: 'tomato', color: 'white' }}
                >
                    Add Synonym
                </Button>
            </Content>
            <Content className="site-layout-background content" style={{ boxShadow: '2px 1px 10px lightgrey' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Input.Search
                        className='search'
                        placeholder="Search Group/Synonyms"
                        enterButton
                        onChange={(e) => search(e.target.value)}
                    />
                </div>
                <Table
                    bordered
                    showSorterTooltip
                    showHeader={true}
                    className='fileLibraryTable'
                    dataSource={filter.filterTable === null ? groups : filter.filterTable}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={{
                        position: ['bottomLeft'],
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        pageSizeOptions: ['5', '10', '20', '50', '100'],
                    }}
                    rowKey='id' // fileId maybe
                />
            </Content>
            <SynonymModal
                close={() => { setSelectedGroup(null); setShowModal(false); }}
                show={showModal}
                editGroup={selectedGroup}
                handleSubmit={handleSubmit}
            />
        </div>
    );
}

export default Synonyms;
