import React, { useState } from 'react';
import './ResourcesScreen.css';
import {
    Button,
    Breadcrumb,
    Modal,
    Form,
    Input,
    Select,
    // Checkbox,
    Layout,
    Row,
} from 'antd';
import axios from 'axios';
// import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import ResourceTable from './ResourceTable';
import availableLanguages from '../../Components/AvailableLanguages/AvailableLanguages';

// eslint-disable-next-line no-undef
const AUTH_TOKEN = localStorage.getItem('token');

export default function ResourcesScreen() {
    const { Option } = Select;
    const { Header, Content } = Layout;
    const [resourceVisible, setResourceVisible] = useState(false);
    const [resourceConfirmLoading, setResourceConfirmLoading] = useState(false);
    const onResourceFinish = (values) => {
        setResourceConfirmLoading(true);

        const url = `${process.env.REACT_APP_QUESTION_URL}/categories`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        const newResource = { name: values.categoryName };
        axios.post(url, newResource, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setResourceVisible(false);
                setResourceConfirmLoading(false);
                // fetchResources();
                Modal.success({ content: `${response.data.message}` });
            } else {
                Modal.error({ content: 'Resource Not Added, Please Try Again' });
            }
        }).catch((err) => {
            // console.log(err.response);
            Modal.error({ content: err.response.data.errorMessage });
        });
    };
    const resourceHandleCancel = () => setResourceVisible(false);
    const onResourceFinishFailed = (errorInfo) => Modal.error({ content: errorInfo });

    // const fetchResources = () => {
    //     const url = `${process.env.REACT_APP_QUESTION_URL}/resources`;
    //     const config = { headers: { Authorization: AUTH_TOKEN } };
    //     axios.get(url, config).then((response) => {
    //         if (response.status === 200 && !response.error) {
    //             setResources(response.data.resources || []);
    //         }
    //     })
    // }
    const showResourceModal = () => setResourceVisible(true);

    return <div>
        <Header
            style={{
                padding: 0,
                textAlign: 'center',
                fontSize: 30,
                fontWeight: 300,
                background: 'white',
            }} >
            {'Resources'}
        </Header>
        <Content style={{
            margin: '24px 16px 0',
            overflow: 'initial',
            background: '#fff',
            borderRadius: 10,
            boxShadow: '2px 1px 10px lightgrey',
        }}>
            <div className='questionBankScreenContainer'>
                <div className='questionBankTop'>
                    <div className='questionBankTopBreadcrumbContainer'>
                        <Breadcrumb>
                            <Breadcrumb.Item><a href="/">Home</a></Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a href="">Resources</a>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className='questionBankTopButtonContainer'>
                        <Button
                            onClick={showResourceModal}
                            shape="round"
                            style={{ background: 'tomato', color: 'white' }}
                        >
                            Add Resource
                        </Button>
                    </div>
                </div>

                <div className='questionBankMiddle'>
                    <ResourceTable />
                </div>
            </div>
        </Content>

        {/* ADDING A NEW RESOURCE */}
        <Modal
            title="Add Question"
            visible={resourceVisible}
            confirmLoading={resourceConfirmLoading}
            onCancel={resourceHandleCancel}
            footer={[]}
            getContainer={false}
        >
            <Form
                name="basic"
                onFinish={onResourceFinish}
                onFinishFailed={onResourceFinishFailed}
                layout={'vertical'}
            >

                <Form.Item name="selectLanguage" label="Select Language"
                    rules={[{ required: true }]}
                >
                    <Select
                        placeholder="Select a option and change input text above"
                        allowClear
                    >
                        {
                            availableLanguages.map((language, index) => <Option
                                value={language.value}
                                key={index}>{language.text}</Option>)
                        }
                    </Select>
                </Form.Item>

                <Form.Item name="question" label="Question"
                    rules={[{
                        required: true,
                        message: 'Enter a new Question!',
                    }]}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item label="Help Text" name="helpText">
                    <Input />
                </Form.Item>
                {/* <Form.Item name="optionFormat" label="Options Format"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    placeholder="Select a option and change input text above"
                                    allowClear
                                >
                                    <Option value="Radio">Radio</Option>
                                    <Option value="Dropdown">Dropdown</Option>
                                    <Option value="Checkbox">Checkbox</Option>
                                </Select>
                            </Form.Item> */}

                <Form.Item>
                    <Row justify="end">
                        <Button
                            htmlType="submit"
                            shape="round"
                            style={{ background: 'tomato', color: 'white' }}
                        >
                            Save
                        </Button>
                    </Row>
                </Form.Item>

            </Form>
        </Modal>

    </div>;
}
