import {
    Button,
    Input,
    Modal,
    Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import './Synonyms.css';

function SynonymModal({
    show,
    close,
    editGroup,
    handleSubmit,
}) {
    const [synonyms, setSynonyms] = useState([]);
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        if (editGroup) {
            setSynonyms(editGroup.synonyms);
            setKeyword(editGroup.keyword);
        } else {
            setSynonyms([]);
            setKeyword('');
        }
    }, [editGroup]);

    return (
        <Modal
            title="Add Synonyms"
            visible={show}
            onCancel={close}
            width='60%'
            footer={[]}
        >
            <div className='test' style={{ padding: 10 }}>
                <div>
                    <h6>Group</h6>
                    <Input
                        style={{ width: '50%', fontSize: 16 }}
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                    />
                </div>
                <div className='mt-4'>
                    <h6>Synonyms</h6>
                    <Select
                        aria-multiline
                        mode="tags"
                        style={{ width: '100%' }}
                        onChange={(value) => setSynonyms(value)}
                        tokenSeparators={[',']}
                        value={synonyms}
                    >
                    </Select>
                </div>
                <div className='mt-4 align-right'>
                    <Button
                        onClick={() => handleSubmit(keyword, synonyms)}
                        shape="round"
                        style={{
                            background: keyword && synonyms.length > 0 ? 'tomato' : 'lightgrey',
                            color: 'white',
                            alignSelf: 'flex-end',
                        }}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default SynonymModal;
