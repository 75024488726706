/* eslint-disable no-undef */
/* eslint-disable no-useless-escape */
/* eslint-disable no-underscore-dangle */
import {
        Button, Modal, Select, Form, Input, Upload, InputNumber,
        } from 'antd';
  import React, { useState, useEffect } from 'react';
  import axios from 'axios';
  import FormData from 'form-data';
  import { UploadOutlined } from '@ant-design/icons';
  import availableLanguages from '../../Components/AvailableLanguages/AvailableLanguages';

  const { Option } = Select;
  const AUTH_TOKEN = localStorage.getItem('token');
  // eslint-disable-next-line comma-dangle, object-curly-newline
  function MentorDetailsModal({ show, close, fetchMentorDetails, businessIdeas, }) {
    const [fileConfirmLoading, setfileConfirmLoading] = useState(false);
    const [groupsKeywords, setGroupsKeywords] = useState([]);
    const [selectedBids, setSelectedBids] = useState([]);

    // eslint-disable-next-line no-trailing-spaces
    const { TextArea } = Input;

    const sessionProps = {
      accept: 'image/*',
      beforeUpload: () => false,
      listType: 'picture',
    };
    const props = {
      accept: 'video/*',
      beforeUpload: () => false,
      listType: 'picture',
    };

    async function fetchKeywords() {
      const url = `${process.env.REACT_APP_LIBRARY_URL}/synonyms/keywords`;
      axios.get(url).then((response) => {
        if (response.status) {
          setGroupsKeywords(response.data.keywords);
        }
      });
    }
    // eslint-disable-next-line no-trailing-spaces
  
    useEffect(() => {
      fetchKeywords();
    }, []);

      const onFinish = (values) => {
      setfileConfirmLoading(true);
      // eslint-disable-next-line quotes

      const url = `${process.env.REACT_APP_SESSION_API_URL}/session-users/register-mentor`;
      const config = { headers: { Authorization: AUTH_TOKEN } };
      const fileForm = new FormData();
      fileForm.append('name', values.name);
      fileForm.append('designation', values.designation);
      fileForm.append('keywords', values.keywords);
      fileForm.append('mentorBio', values.mentorBio);
      fileForm.append('businessIdeas', (selectedBids || []));
      fileForm.append('language', values.language);
      fileForm.append('email', values.email);
      fileForm.append('mobileNumber', values.mobileNumber);
      fileForm.append('organisation', values.organisation);
      fileForm.append('mentorFee', values.mentorFee);
      fileForm.append('internalBio', values.internalBio);

      if (values.mentorVideo && values.mentorVideo.file) {
        if (values.mentorVideo.file.type.includes('audio')) fileForm.append('fileType', 'audio');
        else if (values.mentorVideo.file.type.includes('video')) fileForm.append('fileType', 'video');
        else fileForm.append('mentorVideo', values.mentorVideo.file.type);
        fileForm.append('mentorVideo', values.mentorVideo.file);
      }

      if (values.profilePicture && values.profilePicture.file) {
        if (values.profilePicture.file.type.includes('audio')) fileForm.append('profilePicture', 'audio');
        else if (values.profilePicture.file.type.includes('video')) fileForm.append('profilePicture', 'video');
        else fileForm.append('profilePicture', values.profilePicture.file.type);
        fileForm.append('profilePicture', values.profilePicture.file);
      }
        axios.post(url, fileForm, config).then(async (response) => {
          if (response) {
            await fetchMentorDetails();
            Modal.success({ content: `${response.data.message}` });
            close();
          } else {
            Modal.error({ content: 'File Not Added, Please Try Again' });
          }
        }).catch((err) => {
          console.log('Error :', err);
          Modal.error({ content: 'Error in Post, Please Try Again' });
        });
    };

    const handleSelectAll = (value) => {
      if (value.includes('all')) {
          const selected = businessIdeas?.map((x) => x._id);
          setSelectedBids(selected);
      } else if (value.includes('none')) {
          setSelectedBids([]);
      } else setSelectedBids(value);
  };
    return (
      <Modal
        title="Add Mentor"
        visible={show}
        onCancel={close}
        width="60%"
        confirmLoading={fileConfirmLoading}
        footer={[]}
        getContainer={false}
      >
        <Form
          name="basic-event"
          onFinish={onFinish}
          layout={'vertical'}
        >
          <div className="test" style={{ padding: 10 }}>
            <Form.Item
              name="name"
              label="Mentor Name"
              rules={[
                {
                  required: true,
                  message: 'Enter a Mentor Name!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <div>
              <Form.Item name="language" label="Language"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select a option and change input text above"
                  allowClear
                  mode="multiple"
                > {availableLanguages.map((language, index) => <Option
                      value={language.value}
                      key={index}>{language.text}</Option>)}
                </Select>
              </Form.Item>
              <Form.Item name="businessIdeas" label="Specialities"
                rules={[{ required: true, message: 'Please select at least one business idea that goes with this video.' }]}
              >
                <Select
                  mode= 'multiple'
                  placeholder='Select one or more business ideas that apply'
                  allowClear
                  value={selectedBids}
                  filterOption={(input, option) => {
                    console.log('🚀 ~ file: LibraryScreen.js:492 ~ LibraryScreen ~ input, option:', input, option);
                    return ((option?.label)?.toLowerCase() ?? '').includes((input).toLowerCase());
                  }}
                  onChange={handleSelectAll} >
                    <Option value='all' key='all' >{'Select All'}</Option>
                    <Option value='none' key='none' >{'Deselect All'}</Option>
                  { businessIdeas.map((idea) => (
                    <Option key={idea.combine} label={idea._inEnglish}
                      title={idea._inEnglish}
                      value={idea._id}>{idea?.inEnglish || 'Others'} </Option>
                  )) }
                </Select>
              </Form.Item>
              <Form.Item
                name="keywords"
                label="Topic"
                rules={[{ required: true, message: 'Add Groups' }]} >
                <Select
                  placeholder="Select one or more groups"
                  allowClear
                  mode="multiple"
                >
                  { groupsKeywords.map((word) => (
                    // eslint-disable-next-line no-underscore-dangle
                    <Option key={word._id} value={word._id}>
                      {word.keyword}
                    </Option>
                  )) }
                </Select>
              </Form.Item>
            </div>
            <p>Mentor Details</p>
            <div className='event-box' >
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Enter an Email!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="mobileNumber"
                label="Mobile Number"
                rules={[
                  {
                    required: true,
                    message: 'Enter a Mobile number',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="organisation"
                label="Organisation Details"
                rules={[
                  {
                    required: true,
                    message: 'Enter an Organisation Details!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="designation"
                label="Designation"
                rules={[
                  {
                    required: true,
                    message: 'Enter Mentor Designation details!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="mentorBio"
                label="Mentor Bio (max 1000 characters are allowed)"
                rules={[
                  {
                    required: true,
                    message: 'Enter a Mentor Bio!',
                  },
                ]}
              >
                <TextArea rows={6} maxLength = {1000}/>
              </Form.Item>
            </div>
            {/* <p className='label'>Mentor Fee (in INR)
            <InputNumber
              min='0'
              style={{ marginRight: 20, marginLeft: 20 }}
              placeholder={'Enter course fee'}
              // value={formValues.pricingPlan?.courseFee}
              // onChange={(value) => setFormValues({
              //   ...formValues,
              //   pricingPlan: { courseFee: value },
              // })}
            />
          </p> */}
               <Form.Item
                name="mentorFee"
                label="Mentor Fee (in INR)"
              >
                <InputNumber
              min='0'
            />
              </Form.Item>
              <Form.Item
                name="internalBio"
                label="For Internal Use"
              >
                <TextArea rows={6} maxLength = {1000}/>
              </Form.Item>
            <div>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Upload a Mentor Profile Picture!',
                  },
                ]}
                label="Profile Picture (800 x 800)"
                name="profilePicture">
                <Upload listType="picture-card" {...sessionProps}>
                  <Button icon={<UploadOutlined />}>Upload Mentor Profile Picture</Button>
                </Upload>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Mentor Introduction video"
                name="mentorVideo"
                rules={[
                  {
                    required: true,
                    message: 'Enter a new video!',
                  },
                ]}
              >
                <Upload
                  {...props}
                  onChange={(e) => {
                    const { file } = e;
                    if (file && file.status !== 'removed') {
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                    }
                  }}>
                  <Button icon={<UploadOutlined />}>Upload Mentor Video</Button>
                </Upload>
              </Form.Item>
            </div>

            <div className="mt-4 align-right">
              <Button
                // onClick={() => handleSubmit(keyword, synonyms)}
                shape="round"
                htmlType="submit"
                style={{
                  background: 'tomato',
                  color: 'white',
                  alignSelf: 'flex-end',
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    );
  }
  export default MentorDetailsModal;
