/* eslint-disable no-undef */
/* eslint-disable no-useless-escape */
/* eslint-disable no-underscore-dangle */
import {
  Button, Modal, Select, Upload, Form,
  Input,
  Row,
  Col,
} from 'antd';
import Swal from 'sweetalert2';
import React, { useEffect, useState } from 'react';
import './DailyTipsScreen.css';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import FormData from 'form-data';
import availableLanguages from '../../Components/AvailableLanguages/AvailableLanguages';
import JsonToFormdata from 'json-form-data';

const AUTH_TOKEN = localStorage.getItem('token');
const { Option } = Select;

function DailyTipsModal({ show, close ,fetchBusinessIdeas,businessIdeas}) {
  const INITIAL_QUOTES_STATE = [
    { language: null, text: '' },
  ];
  const [groupsKeywords, setGroupsKeywords] = useState([]);
  const [fileConfirmLoading, setfileConfirmLoading] = useState(false);
  const [quotes, setQuotes] = useState([...INITIAL_QUOTES_STATE,]);

  const quotesProps = {
    accept: 'image/*',
    beforeUpload: () => false,
    listType: 'picture',
  };
  async function fetchKeywords() {
    const url = `${process.env.REACT_APP_LIBRARY_URL}/synonyms/keywords`;
    axios.get(url).then((response) => {
      if (response.status) {
        setGroupsKeywords(response.data.keywords);
      }
    });
  }
  useEffect(() => {
    fetchKeywords();
  }, []);

  const onFinish = (values) => {
    setfileConfirmLoading(true);

    const url = `${process.env.REACT_APP_LIBRARY_URL}/quotes`;
    const config = { headers: { Authorization: AUTH_TOKEN } };
    const quoteToSend = {
      keywords: values.keywords,
      quotes:quotes,
      businessIdeas: values.businessIdeas

    };
    if (values.fileUpload && values.fileUpload.file) {
      quoteToSend.backgroundImage = values.fileUpload.file;
    } else quoteToSend.backgroundImage = '';

    const fileForm = JsonToFormdata(quoteToSend);

    axios.post(url, fileForm, config).then((response) => {
      if (response) {
        Modal.success({ content: `${response.data.message}` });
        window.location.reload();
      } else {
        Modal.error({ content: 'Tip Not Added, Please Try Again' });
      }
    }).catch((err) => {
      console.log(err);
      Modal.error({ content: 'Error while Post, Please Try Again' });
    });


  };


  return (
    <Modal
      title="Add Tips"
      visible={show}
      onCancel={close}
      width="60%"
      confirmLoading={fileConfirmLoading}
      footer={[]}
      getContainer={false}
    >
      <Form
        name="basic-quote"
        onFinish={onFinish}
        layout={'vertical'}
      >
        <div className="test" style={{ padding: 10 }}>
          <div>
            <Form.Item label="Tips Thumbnail (Background Image - 800 x 800)" name="fileUpload">
              {/* <h6>Image</h6> */}
              <Upload listType="picture-card" {...quotesProps}>
                <Button icon={<UploadOutlined />}>Upload Image</Button>
              </Upload>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="keywords"
              label="Group(s)"
              rules={[{ required: true, message: 'Add Groups' }]}
            >
              <Select mode='multiple' placeholder="Select one or more Groups" allowClear>
                {groupsKeywords.map((word) => (
                  // eslint-disable-next-line no-underscore-dangle
                  <Option key={word._id} value={word._id}>
                    {word.keyword}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="businessIdeas" label="Business Idea(s)"
            rules={[{ required: true, message: 'Add business Ideas' }]}
            >
                            <Select
                                mode="multiple"
                                placeholder="Select one or more business ideas that apply"
                                allowClear
                                showSearch
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                onSearch={(searchText) => {
                                    console.log('🚀 ~ file: LibraryTable.js:564 ~ searchText:', searchText);
                                }}
                            >
                                {businessIdeas.map((idea) => (
                                    <Option key={idea.combine} label={idea._inEnglish}
                                        title={idea._inEnglish}
                                        value={idea._id}>{idea.inEnglish}</Option>
                                ))}
                            </Select>
                        </Form.Item>
          </div>
          {/* <div ></div> */}
          <Form.Item name="quotes" label="Daily Tip(s)"
          //  rules={[{ required: true, message: 'Add daily tips' }]}
           >
          {
            quotes && quotes.length && quotes.map((x, qIndex) => {
              const { language, text } = x;
              const selectedLanguages = quotes.map(x => x.language);
              return <Row key={qIndex} style={{marginTop:5}}>
                <Col span={4}>
                  <Select value={language} style={{ width: '100%' }} onChange={(value) => {
                    let quotesToSave = [...quotes];
                    quotesToSave[qIndex]['language'] = value;
                    setQuotes(quotesToSave);
                  }} placeholder="Select language"
                  >
                    {availableLanguages.filter(lang => !selectedLanguages.includes(lang.value)).map((language, index) => (
                      <Option value={language.value} key={index}>
                        {language.text}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col offset={1} />
                <Col span={14}>
                  <Input value={text} placeholder='Enter the tip here' onChange={(event) => {
                    let quotesToSave = [...quotes]; quotesToSave[qIndex]['text'] = event.target.value; setQuotes(quotesToSave);
                  }} />
                </Col>
                <Col offset={1} />
                <Col span={3}>
                  <Button
                    shape="round"
                    disabled={quotes.length === 1}
                    style={{
                      background: quotes.length === 1 ? 'grey' : 'tomato',
                      cursor: quotes.length === 1 ? 'not-allowed' : 'pointer',
                      color: 'white',
                      alignSelf: 'flex-end',
                    }}
                    onClick={
                      () => {
                        Swal.fire({
                          title: 'Are you sure you want to remove this row of quote?',
                          focusConfirm: false,
                          showCancelButton: true,
                          cancelButtonText: 'No, Cancel',
                          confirmButtonText: 'Yes, Delete',
                          cancelButtonAriaLabel: 'No, Cancel',
                          confirmButtonAriaLabel: 'Yes, Delete',
                        }).then((result) => {
                          if (result.value) {
                            let quotesToSave = [...quotes].filter((quote, ind) => ind !== qIndex);
                            setQuotes(quotesToSave.length > 0 ? quotesToSave : INITIAL_QUOTES_STATE);
                          }
                        });
                      }
                    }
                  >
                    Delete
                  </Button>
                  {quotes.length - 1 === qIndex ? <Button
                    shape="round"
                    style={{
                      background: !(language && text) ? 'grey' : 'tomato',
                      cursor: !(language && text) ? 'not-allowed' : 'pointer',
                      color: 'white',
                      // alignSelf: 'flex-end',
                    }}
                    disabled={!(language && text)}

                    onClick={
                      () => {
                        let quotesToSave = [...quotes, ...INITIAL_QUOTES_STATE];
                        setQuotes(quotesToSave)
                      }
                    }
                  >
                    Add
                  </Button> : null}
                </Col>
              </Row>
            })
          }
          <div className="mt-4 align-right">
            <Button
              disabled={!(quotes[quotes.length - 1]['language'] && quotes[quotes.length - 1]['text'])}
              // onClick={() => handleSubmit(keyword, synonyms)}
              shape="round"
              htmlType="submit"
              style={{
                background: !(quotes[quotes.length - 1]['language'] && quotes[quotes.length - 1]['text']) ? 'grey' : 'tomato',
                cursor: !(quotes[quotes.length - 1]['language'] && quotes[quotes.length - 1]['text']) ? 'not-allowed' : 'pointer',
                color: 'white',
                alignSelf: 'flex-end',
              }}
            >
              Save
            </Button>
          </div>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}

export default DailyTipsModal;
