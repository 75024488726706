/* eslint-disable no-undef */
import React, {
    useState,
    useEffect,
} from 'react';
import {
    Table,
    Input,
    Form,
    Typography,
    // Modal,
    // Button,
    // Select,
    // Checkbox,
    // Row,
} from 'antd';
import './QuestionTable.css';
import axios from 'axios';
import moment from 'moment';
import {
    EditFilled,
    // PlusOutlined,
    // MinusOutlined,
} from '@ant-design/icons';
// import availableLanguages from '../../Components/AvailableLanguages/AvailableLanguages';

const AUTH_TOKEN = localStorage.getItem('token');

function QuestionTable() {
    // const { Option } = Select;

    const [resources, setResources] = useState([]);
    async function fetchResources() {
        const url = `${process.env.REACT_APP_QUESTION_URL}/resources`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                // console.log(response.data.Resources);
                setResources(response.data.resources);
            }
        });
    }

    useEffect(() => {
        fetchResources();
    }, []);

    // ------------------------- CONFIRMATION MODAL -------------------------
    // const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
    const [selectedQuestion, setselectedQuestion] = useState(false);
    // const showConfirmationModal = () => setIsConfirmationModalVisible(true);

    // ------------------------- EDIT QUESTION MODAL -------------------------
    // const [questionVisible, setQuestionVisible] = useState(false);
    // const [questionConfirmLoading, setQuestionConfirmLoading] = useState(false);
    // const [correctOptionsIndices, setcorrectOptionsIndices] = useState([]);

    const showQuestionModal = () => setQuestionVisible(true);
    // const questionHandleCancel = () => {
    //     setQuestionVisible(false);
    //     setselectedQuestion(false);
    // };
    // const onQuestionFinishFailed = (errorInfo) => Modal.error({ content: errorInfo });

    // const onQuestionFinish = (values) => {
    //     const correctOptionsList = [];
    //     for (let i = 0; i < values.options.length; i += 1) {
    //         const tempOptionObj = {
    //             option: values.options[i],
    //             isTrue: correctOptionsIndices.includes(i),
    //         };
    //         correctOptionsList.push(tempOptionObj);
    //     }
    //     const editedQuestion = {
    //         question: values.question,
    //         language: values.language,
    //         categoryId: values.categoryId,
    //         helpText: values.helpText,
    //         optionFormat: values.optionFormat,
    //         options: correctOptionsList,
    //     };
    //     setQuestionConfirmLoading(true);
    //     seteditingQuestion(editedQuestion);

    //     showConfirmationModal();

    //     setQuestionVisible(false);
    //     setQuestionConfirmLoading(false);
    // };

    // function onCheckedOptions(e, fieldName) {
    //     let tempIndices = [...correctOptionsIndices];
    //     if (e.target.checked === true) tempIndices.push(fieldName);
    //     else {
    //         tempIndices = tempIndices.filter((i) => i !== fieldName);
    //     }
    //     setcorrectOptionsIndices(tempIndices);
    // }
    // const formItemLayoutWithOutLabel = {
    //     wrapperCol: {
    //         xs: { span: 24, offset: 0 },
    //         sm: { span: 20, offset: 4 },
    //     },
    // };

    const columns = [
        {
            title: 'Serial No.',
            dataIndex: 'slNo',
        },
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'Resource Language',
            dataIndex: 'language',
            // editable: true,
            // filters: availableLanguages,
            // onFilter: (value, record) => record.language.indexOf(value) === 0,
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Updated On',
            dataIndex: ['metaInfo', 'updated', 'at'],
            // sorter: {
            //     compare: (a, b) => a.metaInfo.updated.at - b.metaInfo.updated.at,
            //     multiple: 1,
            // },
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                const { metaInfo } = record;
                return (
                    <span>
                        {moment(metaInfo.updated.at).format('DD/MM/YYYY HH:mm')}
                    </span>
                );
            },
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            // eslint-disable-next-line react/display-name
            render: (_, record) => (<Typography.Link
                onClick={() => {
                    setselectedQuestion(record);
                }}
            >
                <EditFilled style={{ color: 'tomato', fontSize: 20 }} />
            </Typography.Link>),
        },
    ];
    useEffect(() => {
        if (selectedQuestion !== false) showQuestionModal();
    }, [selectedQuestion]);

    // SEARCH FILTER
    const [filter, setFilter] = useState({ filterTable: null });
    function search(value) {
        const filterTable = resources.filter((o) => Object.keys(o).some((k) => String(o[k])
            .toLowerCase().includes(value.toLowerCase())));
        setFilter({ filterTable });
    }

    // function selectedQuestionOptions() {
    //     const options = [];
    //     if (selectedQuestion) {
    //         for (let index = 0; index < selectedQuestion.options.length; index += 1) {
    //             options.push(selectedQuestion.options[index].option);
    //         }
    //     }
    //     return options;
    // }
    return (
        <div className='questionTableContainer'>
            <div className='tableSearchContainer'>
                <Input.Search
                    style={{
                        width: 300,
                    }}
                    placeholder="Search any field..."
                    enterButton
                    onChange={(e) => search(e.target.value)}
                />
            </div>
            <Form component={false}>
                <Table
                    bordered
                    showHeader={true}
                    className='resourcesTable'
                    dataSource={filter.filterTable === null ? resources : filter.filterTable}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={{ position: ['bottomLeft'] }}
                    rowKey='id'
                />
            </Form>

        </div>
    );
}

export default QuestionTable;
