/* eslint-disable consistent-return */
/* eslint-disable no-undef */
import React, {
  Fragment,
  // useEffect
} from 'react';
// import React from 'react';
import './App.css';
import {
  BrowserRouter as Router, Switch, Route,
  // Redirect,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Home from './Screens/Home/Home';
import QuestionBankScreen from './Screens/QuestionBankScreen/QuestionBankScreen';
import ResourcesScreen from './Screens/ResourcesScreen/ResourcesScreen';
// import ModuleScreen from './Screens/ModulesScreen/ModuleScreen';
import TopicsScreen from './Screens/TopicsScreen/TopicsScreen';
// import ConceptsScreen from './Screens/ConceptsScreen/ConceptsScreen';
import VideosScreen from './Screens/VideosScreen/VideosScreen';
import MomentsScreen from './Screens/MomentsScreen/MomentsScreen';
import LibraryScreen from './Screens/LibraryScreen/LibraryScreen';
import UsersScreen from './Screens/UsersScreen/UsersScreen';
import UserProgress from './Screens/UserProgress/UserProgress';
import LanguageProvider from './helpers/LangContext';
import TranscriptScreen from './Screens/TranscriptsScreen/TranscriptsScreen';
import Login from './Screens/Login/Login';
import ChangePassword from './Screens/Login/ChangePassword';
import Admins from './Screens/UsersScreen/Admin';
import ForgotPassword from './Screens/Login/ForgotPassword';
import Synonyms from './Screens/SynonymsScreen/Synonyms';
import CourseScreen from './Screens/CourseScreen/CourseScreen';
import QuotationScreen from './Screens/QuotationScreen/QuotationScreen';
import DirectoryScreen from './Screens/DirectoryScreen/DirectoryScreen';
import MentorshipEventScreen from './Screens/MentorshipEvents/MentorshipEventScreen';
import MentorDetailsScreen from './Screens/MentorDetailsScreen/MentorDetailsScreen';
import DailyTipsScreen from './Screens/DailyTipsScreen/DailyTipsScreen';

const HomePageLayout = ({ children }) => <Fragment>{children}</Fragment>;

function App() {
  // console.log(localStorage.getItem('token'));
  // if (!token) {
  // }
  const history = createBrowserHistory();
  const DefaultLayout = ({ children }) => (
    <Fragment>
      {/* <Header /> */}
      {/* <Container fluid> */}
      {/* <Row>
        <Col md={2}>
          <Sidebar />
        </Col>
        <Col md={10}>
          <Row>
            <Header />
          </Row>
          <Row>{children}</Row>
        </Col>
      </Row> */}
      {/* <Row>
          <Header />
          <Col md={2} id="sidebar">
            <Sidebar />
          </Col> */}
      {/* <Col md={10} id="content"> */}
      {/* <Row> */}
      {children}
      {/* </Row> */}
      {/* </Col>
        </Row>
      </Container> */}
    </Fragment>
  );
  // const HomePageLayout = ({ children }) => <Fragment>{children}</Fragment>;
  const RouteComponent = ({
    render: Component,
    layout: Layout = DefaultLayout,
    path,
    ...rest
  }) => {
    const token = localStorage.getItem('token');
    if (path === '/forgot-password') {
      return <ForgotPassword />;
    }
    if (token) {
      return <Route
        {...rest}
        render={(props) => (
          <Layout>
            <Component {...props} />
            {console.log(props)}
          </Layout>
        )
        }
      />;
    }
    return <Login />;
  };
  return (
    <Router>
      <LanguageProvider>
        <Switch>
        <RouteComponent path='/quotes'>
            <Home content ={<QuotationScreen/>}/>
          </RouteComponent>
          <RouteComponent path='/daily-tips'>
            <Home content={<DailyTipsScreen />} />
          </RouteComponent>
          <RouteComponent path="/session">
            <Home content ={<MentorshipEventScreen/>} />
          </RouteComponent>
          <RouteComponent path="/mentors">
            <Home content ={<MentorDetailsScreen/>} />
          </RouteComponent>
          <RouteComponent path='/videomoments'>
            <Home content={<MomentsScreen />} />
          </RouteComponent>
          <RouteComponent path='/transcripts'>
            <Home content={<TranscriptScreen />} />
          </RouteComponent>
          <RouteComponent path='/videos/:videosId'>
            <Home content={<VideosScreen />} />
          </RouteComponent>
          {/* <RouteComponent path='/topics/:topicId'>
            <Home content={<ConceptsScreen />} />
          </RouteComponent> */}
          <RouteComponent path='/topics/:topicId'>
            <Home content={<VideosScreen />} />
          </RouteComponent>
          <RouteComponent path='/modules/:moduleId'>
            <Home content={<TopicsScreen />} />
          </RouteComponent>
          <RouteComponent path='/library'>
            <Home content={<LibraryScreen />} />
          </RouteComponent>
          {/* <RouteComponent path='/modules'>
            <Home content={<ModuleScreen />} />
          </RouteComponent> */}
          <RouteComponent path='/directory/:directoryPath'>
            <Home
              history={history}
              content={<DirectoryScreen />} />
          </RouteComponent>
          <RouteComponent path='/course'>
            <Home content={<CourseScreen />} />
          </RouteComponent>
          <RouteComponent path='/questions'>
            <Home content={<QuestionBankScreen />} />
          </RouteComponent>
          <RouteComponent path='/users'>
            <Home content={<UsersScreen />} />
          </RouteComponent>
          <RouteComponent path='/resources'>
            <Home content={<ResourcesScreen />} />
          </RouteComponent>
          <RouteComponent path='/user/progress/:mobile'>
            <Home content={<UserProgress />} />
          </RouteComponent>

          {/* <RouteComponent path='/login'> */}
          <RouteComponent
            path="/login"
            content={<Login />}
            layout={HomePageLayout}
          >
            <Login />
          </RouteComponent>
          <RouteComponent
            path="/forgot-password"
            content={<ForgotPassword />}
            layout={HomePageLayout}
          >
            <Login />
          </RouteComponent>
          <RouteComponent
            path="/change-password"
            component={ChangePassword}
            layout={HomePageLayout}
          ></RouteComponent>
          <RouteComponent path='/admins'>
            <Home content={<Admins />} />
          </RouteComponent>
          <RouteComponent path='/synonyms'>
            <Home content={<Synonyms />} />
          </RouteComponent>

          {/* </RouteComponent> */}
          <RouteComponent path='/'>
            <Home content={<CourseScreen />} />
          </RouteComponent>

        </Switch>
      </LanguageProvider>
    </Router>
  );
}

export default App;
