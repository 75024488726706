/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import './UserList.css';
import {
    Input,
    // Card,
    Row,
    Col,
    Table,
    Modal,
} from 'antd';
import moment from 'moment';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const UserList = () => {
    const [showProgress, setShowProgress] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [moduleLevelProgress, setModuleLevelProgress] = useState(null);
    const [topics, setTopics] = useState(null);
    const [filter, setFilter] = useState({ filterTable: null });

    const [upcomingTopics, setUpcomingTopics] = useState({ filterTable: null });
    const [completedTopics, setCompletedTopics] = useState({ filterTable: null });
    const [ongoingTopics, setOngoingTopics] = useState({ filterTable: null });

    const AUTH_TOKEN = localStorage.getItem('token');

    const getAllUsers = () => {
        const url = `${process.env.REACT_APP_USER_URL}/user`;
        const config = {
            headers: {
                Authorization: AUTH_TOKEN,
            },
        };
        axios.get(url, config).then((response) => {
            setUsers(response.data.users);
        }).catch((err) => {
            Modal.error({ content: err.data.errorMessage });
        });
    };

    useEffect(() => {
        getAllUsers();
    }, []);

    //     const filterTopicsWithProgress = () => {
    //     if (topics && moduleLevelProgress) {
    //       const array1 = [];
    //       const array2 = [];
    //       const array3 = [];
    //       for (let i = 0; i < topics?.length; i += 1) {
    //         if (moduleLevelProgress.completedTopics.length === 0) {
    //           i === 0 ? array2.push(topics[i]) : array3.push(topics[i]);
    //         } else {
    //           for (let j = 0; j < moduleLevelProgress?.completedTopics?.length; j += 1) {
    //             if (topics[i]?._id === moduleLevelProgress?.completedTopics[i]?.topicId) {
    //                 /*eslint no-unused-expressions: ["error", { "allowTernary": true }]*/
    //               moduleLevelProgress?.completedTopics[i]?.status === 'completed'
    //                 ? array1.includes(topics[i])
    //                   ? null
    //                   : array1.push(topics[i])
    //                 : array2.includes(topics[i])
    //                 ? null
    //                 : array2.push(topics[i]);
    //             } else {
    //               array3.includes(topics[i]) ? null : array3.push(topics[i]);
    //             }
    //           }
    //         }
    //       }
    //       setUpcomingTopics(array3);
    //       setCompletedTopics(array1);
    //       setOngoingTopics(array2);
    //     }
    //   };
    useEffect(() => {
        setOngoingTopics(null);
        setUpcomingTopics(null);
        setCompletedTopics(null);
    }, []);

    const getModuleProgress = (moduleId) => {
        const url = `${process.env.REACT_APP_USER_URL}/progress/module/${moduleId}`;
        const config = {
            headers: {
                Authorization: AUTH_TOKEN,
            },
        };
        axios.get(url, config).then((response) => {
            setModuleLevelProgress(response.data);
            // filterTopicsWithProgress();
            setShowProgress(true);
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
            // console.log(err);
        });
    };

    const getTopicsByModule = (moduleId) => {
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/topic/${moduleId}`;
        const config = {
            headers: {
                Authorization: AUTH_TOKEN,
            },
        };
        axios.get(url, config).then((response) => {
            setTopics(response.data);
            getModuleProgress(moduleId);
            // setShowProgress(true);
        }).catch((err) => {
            // Modal.error({ content: err.response });
            // console.log(err);
        });
    };

    const history = useHistory();
    const columns = [
        {
            title: 'Mobile',
            dataIndex: ['userInfo', 'mobileNumber'],
            key: 'mobile',
            // eslint-disable-next-line react/display-name
            render: (mobile, currentUser) => <p style={{ cursor: 'pointer', color: 'blue' }}
                onClick={
                    () => {
                        history.push({
                            // eslint-disable-next-line no-underscore-dangle
                            pathname: `/user/progress/${mobile}`,
                            state: {
                                // eslint-disable-next-line no-underscore-dangle
                                token: currentUser.userInfo.token,
                                // eslint-disable-next-line object-shorthand
                                mobile: mobile,
                            },
                        });
                    }
                }>
                {mobile}
            </p>,
        },
        {
            title: 'Last Login Time',
            dataIndex: ['userInfo', 'loginTime'],
            key: 'loginTime',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                const { userInfo } = record;
                return (
                    <span>
                        {moment(userInfo.loginTime).format('DD/MM/YYYY HH:mm')}
                    </span>
                );
            },
        },
        {
            title: 'Preferred Language',
            dataIndex: ['userInfo', 'preferredLanguage'] !== '' ? ['userInfo', 'preferredLanguage'] : 'N/A',
            key: 'preferredLanguage',
        },
        {
            title: 'Progress (%)',
            dataIndex: ['currentProgress', 'progress'],
            key: 'progress',
            // eslint-disable-next-line react/display-name
            render: (progress, currentProgress) => <p onClick={
                () => {
                    // console.log(currentProgress);
                    setSelectedUser(currentProgress);
                    getTopicsByModule(currentProgress.currentProgress.moduleId);
                    // getModuleProgress(currentProgress.currentProgress.moduleId);
                }
            }>
                {progress === '' ? 'NA' : progress}
            </p>,
        },
    ];

    function search(value) {
        const filterTable = users.filter((o) => Object.keys(o).some((k) => String(o[k])
            .toLowerCase().includes(value.toLowerCase())));
        setFilter({ filterTable });
    }

    // console.log(showProgress);
    // console.log(selectedUser);
    // console.log('Module Level Progress', moduleLevelProgress);
    // console.log('Module Level TOPICS', topics);
    // console.log('UPC', upcomingTopics);
    // console.log(ongoingTopics);
    // console.log(completedTopics);

    return (
        <div className='userListContainer'>
            <div className='userSearchContainer'>
                <Input.Search
                    style={{
                        width: 300,
                    }}
                    placeholder="Search any field..."
                    enterButton
                    onChange={(e) => search(e.target.value)}
                />
            </div>
            <Row>
                <Table
                    style={{ width: '100%', paddingRight: 20 }}
                    pagination={{
                        position: ['bottomLeft'],
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        pageSizeOptions: ['5', '20', '50', '100'],
                    }}
                    columns={columns}
                    dataSource={filter.filterTable === null ? users : filter.filterTable}
                />
                {/* {users && users.map((user) => <Col
                          span={8}
                          key={ user.userInfo.mobileNumber }
                          >
                        <Card
                          size="small"
                          title={ user.userInfo.mobileNumber }
                          style={{ width: 350, margin: 10, minHeight: 160 }}
                        >
                          {user.progress.length < 1 && <p style={{
                            textAlign: 'center',
                            fontSize: 20,
                            color: 'lightgray',
                            fontWeight: 'bold',
                            marginTop: 20,
                            }}>Not Registered to any modules </p>}
                          {user.progress.length > 0
                            && user.progress.map((prog) => <Row key={ prog.moduleId }>
                              <Col span={20}>{prog.moduleId}</Col>
                              <Col span={4}>{prog.progress} %</Col>
                          </Row>)
                        }
                        </Card>
                        </Col>)} */}
            </Row>
            {selectedUser && topics && <Modal
                title="Current Progress"
                visible={showProgress}
                // confirmLoading={addQuestionsConfirmLoading}
                onCancel={() => setShowProgress(false)}
                footer={[]}
                getContainer={false}
            >
                { /* eslint no-underscore-dangle: 0 */
                    topics.topics.map((topic, index) => <Row key={topic._id}>
                        <Col span={18}>
                            <p style={{ fontSize: 18 }}>{topic?.name?.english}</p>
                        </Col>
                        <Col span={6}>{index === 0 ? <p>Ongoing</p> : <p>Locked</p>}</Col>
                    </Row>)
                }
            </Modal>}
        </div>
    );
};

export default UserList;
