/* eslint-disable react/react-in-jsx-scope */
/**  this are the components designed to be used
 with any screen of the app just import these provide necessary values
*/

import { Alert, Button, Modal } from 'react-bootstrap';
import { AiOutlineCloseSquare } from 'react-icons/ai';

export const AlertMessage = ({ message, close }) => (
  <div className="alerts">
    <Alert variant="success">{message}</Alert>
    {close && (
      <button className="alert-close-btn" onClick={close}>
        <AiOutlineCloseSquare />
      </button>
    )}
  </div>
);

export const ConfirmAlert = ({
  confirmMessage,
  name,
  show,
  setshowConfirmationMessage,
  setAnswer,
}) => (
  <>
    <Modal show={show}>
      <Modal.Header>{confirmMessage} </Modal.Header>

      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => {
              setAnswer(true);
              setshowConfirmationMessage(false);
            }}
            variant="outline-danger"
          >
            {name}
          </Button>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => {
              setAnswer(false);
              setshowConfirmationMessage(false);
            }}
            variant="primary"
          >
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  </>
);
