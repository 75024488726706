/* eslint-disable no-undef */
import React, { useState } from 'react';

import axios from 'axios';
import { AiOutlineLogin } from 'react-icons/ai';

import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import { message, Row } from 'antd';
// import { setCookie } from '../../cookie';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const submitHandler = (e) => {
    e.preventDefault();
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY_SECRET;
    // // console.log(
    //   `Encryption key is ${process.env.REACT_APP_ENCRYPTION_KEY_SECRET}`,
    // );
    // Encrypt
    const encryptedPassword = CryptoJS.AES.encrypt(
      password,
      encryptionKey,
    ).toString();

    // // console.log(`Encrypted password is ${encryptedPassword}`,
    // `${process.env.REACT_APP_API_URL}login`);
    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, {
        email,
        encryptedPassword,
      })
      .then((response) => {
        // // console.log('response is : ', response.data);
        if (!response.data.status) {
          // Display the message (response.message)
          // // console.log(`Error is : ${response.data.message}`);
          message.error(response.data.message);
          return;
        }
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userDetails', JSON.stringify(response.data.userDetails));
        message.success('Login successful!');
        // setCookie('role', JSON.stringify(response.data.role), 10);
        // setCookie('id', JSON.stringify(response.data.id), 10);
        // setCookie('name', JSON.stringify(response.data.name), 10);

        // <Redirect to="/" />;
        // // console.log(`Token is ${localStorage.getItem('token')}`);
        if (localStorage.getItem('token') !== '') {
          window.location = '/';
        }
      });
      // .catch((error) => {
      //   // console.log(`response is : ${error}`);
      // });
  };

  const forgotPassword = async () => {
    const { value: emailId } = await Swal.fire({
      title: 'Input registered email address',
      input: 'email',
      inputLabel: 'We\'ll send you a link to reset your password on this email ID!',
      inputPlaceholder: 'abc@xyz.com',
    });

    if (emailId) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/forgot-password`, {
          email: emailId,
        })
        .then((response) => {
          if (response.data.error) {
            message.error(response.data.message);
          }
          if (response.data.status) {
            message.success(response.data.message);
          }
        })
        .catch((error) => {
          message.error(error);
          // console.log(`response is : ${error}`);
        });
    }
  };

  return (
    <div className="login-div">
      <form className="login-form">
      <h2>Login :</h2>
        <div>
          <input
            id="email"
            type="email"
            placeholder="Enter email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div>
          <input
            id="password"
            placeholder="Enter Password"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <button type="submit" onClick={submitHandler}>
          Submit <AiOutlineLogin />
        </button>
        <Row className='forgot-password' onClick={forgotPassword}>Forgot password?</Row>
      </form>
    </div>
  );
};

export default Login;
