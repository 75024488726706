/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable comma-dangle */
/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React, {
    useState,
    useEffect,
  } from 'react';
  import {
    Table,
    Form,
    Typography,
    Modal,
    Button,
    Select,
    Tooltip,
    Input,
    DatePicker, TimePicker,
    Tag,
    Upload,
    Row, Col,
    Switch,
    AutoComplete,
    InputNumber,
  } from 'antd';
  import './MentorDetailsTableHeader.css';
  import axios from 'axios';
  import moment from 'moment';
  import ReactPlayer from 'react-player';
  import {
    DeleteFilled, EditFilled, LinkOutlined, CopyOutlined, UploadOutlined,
  } from '@ant-design/icons';
  import { CopyToClipboard } from 'react-copy-to-clipboard';
  import Swal from 'sweetalert2';
  import FormData from 'form-data';
  import availableLanguages from '../../Components/AvailableLanguages/AvailableLanguages';

  const AUTH_TOKEN = localStorage.getItem('token');
  const { Option } = Select;
  const { TextArea } = Input;
  const props = {
    // accept: 'audio/*,video/*,image/*',
    accept: 'video/*',
    beforeUpload: () => false,
    listType: 'picture',
  };
  const thumbnailProps = {
    accept: 'image/*',
    beforeUpload: () => false,
    listType: 'picture',
  };

  // eslint-disable-next-line object-curly-newline
  function MentorDetailsTableheader({ mentorDetails, fetchMentorDetails, businessIdeas }) {
    const [groupsKeywords, setGroupsKeywords] = useState([]);
    const [fileForDeletion, setFileForDeletion] = useState(null);
    const [confirmDeletion, setConfirmDeletion] = useState(false);
    const [selectedFile, setselectedFile] = useState(false);
    const [fileConfirmLoading, setfileConfirmLoading] = useState(false);
    const [fileVisible, setfileVisible] = useState(false);
    const [eventVisible, setEventVisible] = useState(false);
    const [selectedkeywords, setSelectedKeywords] = useState([]);
    const [isUploadVideo, setisUploadVideo] = useState(false);
    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);

    const fileHandleCancel = () => {
      setfileVisible(false);
      setEventVisible(false);
    };

    async function fetchKeywords() {
      const url = `${process.env.REACT_APP_LIBRARY_URL}/synonyms/keywords`;
      axios.get(url).then((response) => {
        if (response.status) {
          setGroupsKeywords(response.data.keywords);
        }
      });
    }
    const onFinish = (values) => {
        setfileConfirmLoading(true);
        // eslint-disable-next-line quotes
        const { _id } = selectedFile;
        const url = `${process.env.REACT_APP_SESSION_API_URL}/session-users/${_id}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        const fileForm = new FormData();
        fileForm.append('name', values.name);
        fileForm.append('designation', values.designation);
        fileForm.append('keywords', values.keywords);
        fileForm.append('mentorBio', values.mentorBio);
        fileForm.append('businessIdeas', values.businessIdeas);
        fileForm.append('language', values.language);
        fileForm.append('email', values.email);
        fileForm.append('mobileNumber', values.mobileNumber);
        fileForm.append('organisation', values.organisation);
        fileForm.append('mentorFee', values.mentorFee);
        fileForm.append('internalBio', values.internalBio);

        if (values.mentorShortVideoUrl) {
            fileForm.append('fileType', 'link');
            fileForm.append('fileUrl', values.mentorShortVideoUrl);
          } else {
            console.log('video Uplaoded', values.mentorVideo);
            if (values.mentorVideo.file.type.includes('audio')) fileForm.append('fileType', 'audio');
            else if (values.mentorVideo.file.type.includes('video')) fileForm.append('fileType', 'video');
            else fileForm.append('fileType', values.mentorVideo.file.type);
            fileForm.append('fileUrl', values.mentorVideo.file);
          }

          if (values.profilePicture && values.profilePicture.file) {
            if (values.profilePicture.file.type.includes('audio')) fileForm.append('profilePicture', 'audio');
            else if (values.profilePicture.file.type.includes('video')) fileForm.append('profilePicture', 'video');
            else fileForm.append('profilePicture', values.profilePicture.file.type);
            fileForm.append('profilePicture', values.profilePicture.file);
          } else fileForm.append('profilePicture', selectedFile.profilePicture || '');
          axios.put(url, fileForm, config).then(async (response) => {
            if (response) {
              await fetchMentorDetails();
              Modal.success({ content: `${response.data.message}` });
              fileHandleCancel();
            } else {
              Modal.error({ content: 'File Not Added, Please Try Again' });
            }
          }).catch((err) => {
            console.log('Error :', err);
            Modal.error({ content: 'Error in Post, Please Try Again' });
          });
      };

    const deleteFile = (row) => {
      const { _id } = row;
      const url = `${process.env.REACT_APP_SESSION_API_URL}/session-users/${_id}`;
      const config = { headers: { Authorization: AUTH_TOKEN, 'x-access-token': localStorage.getItem('token') } };

      axios.delete(url, config).then((response) => {
        if (response.status === 200 && response.data.error === false) {
            fetchMentorDetails();
          setConfirmDeletion(false);
          Modal.success({ content: `${response.data.message}` });
        }
      }).catch((err) => {
        Modal.error({ content: err.response.data.errorMessage });
      });
    };

    useEffect(() => {
      fetchMentorDetails();
      fetchKeywords();
    }, []);
    // ------------------------- CONFIRMATION MODAL -------------------------
    const showFileModal = (row) => {
      setfileVisible(true);
      setselectedFile(row);
      setSelectedKeywords(row.keywords);
      // setFormValVisibility(row.visibility === 'Public');
    };
    // eslint-disable-next-line no-trailing-spaces
    
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        align: 'center',
        width: '30vw',
        ellipsis: true,
        render: (_, record) => (
          <div style={{ cursor: 'pointer', color: 'blue' }}>
            <p className='event-description'>{_}</p>
            <span style={{ marginLeft: '15px', float: 'right' }} title='Delete session' >
              <Typography.Link
                onClick={() => { setFileForDeletion(record); setConfirmDeletion(true); }} >
                <DeleteFilled
                  style={{ color: 'tomato', fontSize: 20 }}
                />
              </Typography.Link>
            </span>
            <span style={{ marginLeft: '10px', float: 'right' }} title='Edit'><Typography.Link
              onClick={() => showFileModal(record)}>
              <EditFilled style={{ color: 'tomato', fontSize: 20 }} />
            </Typography.Link>
            </span>
            <span style={{ color: 'tomato', marginLeft: '10px', float: 'right' }} title={record.mentorBio || 'No Mentor Bio to show!'}>
              &#9432;
            </span>
          </div>
        ),
      },
      {
        title: 'Details',
        align: 'center',
        ellipsis: true,
        render: (_, record) => (
          <span><Tag color='blue'>{record.designation}</Tag>
            <Tag color='blue'>{record.mobileNumber}</Tag>
            <Tag color='blue'>{record.language}</Tag>
            </span>
        ),
      },
      {
        title: 'Last Updated',
        dataIndex: ['metaInfo', 'updated', 'by'],
        align: 'center',
        // eslint-disable-next-line react/display-name
        // eslint-disable-next-line no-trailing-spaces
        render: (_, record) => {
          const { metaInfo } = record;
          return (
            <span>
              on <Tag color='blue'>{moment(metaInfo.updated.at).format('DD/MM/YYYY')}</Tag>
              at <Tag color='blue'>{moment(metaInfo.updated.at).format('HH:mm')}</Tag>
              by <Tag color='blue'>{metaInfo.updated.by.name}</Tag>
            </span>
          );
        },
      },
    ];
    function onUploadChecked(checked) {
      setisUploadVideo(checked);
    }

    // SEARCH FILTER
    // eslint-disable-next-line no-unused-vars
    return (
      <div className='questionTableContainer'>
        <Form component={false}>
          <Table
            style={{ width: '100%', paddingRight: 20 }}
            bordered
            showHeader={true}
            className='questionBankTable'
            dataSource={mentorDetails}
            columns={columns}
            rowClassName="editable-row"
            pagination={{ position: ['bottomRight'], pageSize: ['500'] }}
            rowKey='id'
          />
        </Form>
        <Modal
          visible={confirmDeletion}
          onCancel={() => setConfirmDeletion(false)}
          onOk={() => deleteFile(fileForDeletion)}
          width={'40%'}
          style={{ marginTop: 150 }}
          getContainer={false}
        >
          <p> Sure want to delete
            <strong>{' '}{fileForDeletion && fileForDeletion.id}</strong> ?
          </p>
        </Modal>
        <Modal
          title="Edit Mentor Details"
          onCancel={fileHandleCancel}
          width="60%"
          visible={fileVisible}
          confirmLoading={fileConfirmLoading}
          footer={[]}
          getContainer={false}
        >
          <Form
            name="basic-event"
            onFinish={onFinish}
            initialValues={selectedFile}
            layout={'vertical'}
          >
            <div className="test" style={{ padding: 10 }}>
              <Form.Item
                name="name"
                label="Mentor Name"
                rules={[
                  {
                    required: true,
                    message: 'Enter a Event Title!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <div>
                <Form.Item name="language" label="Language"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Select a option and change input text above"
                    allowClear
                    mode="multiple"
                  >
                    {
                      availableLanguages.map((language, index) => <Option
                        value={language.value}
                        key={index}>{language.text}</Option>)
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  name="keywords"
                  label="Groups"
                  rules={[{ required: true, message: 'Add Groups' }]}
                >
                  <Select
                    placeholder="Add more Groups"
                    allowClear
                    mode="multiple"
                  >
                    {groupsKeywords.map((word) => (
                      // eslint-disable-next-line no-underscore-dangle
                      <Option key={word._id} value={word._id}>
                        {word.keyword}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="businessIdeas" label="Business Ideas"
                  rules={[{ required: true, message: 'Please select at least one business idea that goes with this video.' }]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select one or more business ideas that apply"
                    allowClear
                  >
                    {businessIdeas.map((idea) => (
                      <Option key={idea.combine} label={idea._inEnglish}
                        title={idea._inEnglish}
                        value={idea._id}>{idea.inEnglish}</Option>
                    ))}
                  </Select>
                </Form.Item>
               </div>
              <div className='event-box' >
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Enter an Email!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="mobileNumber"
                label="Mobile Number"
                rules={[
                  {
                    required: true,
                    message: 'Enter a Mobile number',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="organisation"
                label="Organisation Details"
                rules={[
                  {
                    required: true,
                    message: 'Enter an Organisation Details!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="designation"
                label="Designation"
                rules={[
                  {
                    required: true,
                    message: 'Enter Mentor Designation details!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
              <div>
              <Form.Item
                name="mentorBio"
                label="Mentor Bio (max 1000 characters are allowed)"
                rules={[
                  {
                    required: true,
                    message: 'Enter a Mentor Bio!',
                  },
                ]}
              >
                <TextArea rows={6} maxLength = {1000}/>
              </Form.Item>

                <Form.Item
                name="mentorFee"
                label="Mentor Fee (in INR)"
              >
                <InputNumber
              min='0'
            />
              </Form.Item>
              <Form.Item
                name="internalBio"
                label="For Internal Use"
              >
                <TextArea rows={6} maxLength = {1000}/>
              </Form.Item>
              </div>
              <div>{selectedFile.profilePicture ? <Form.Item label="Mentor Profile Picture">
                <Row><Col>
                  <img width={300} height={200} src={selectedFile.profilePicture || ''} /></Col>
                  <Button className='button-remove-thumbnail' onClick={() => {
                    Swal.fire({
                      title: 'Are you sure you want to remove mentor profile picture for this session?',
                      focusConfirm: false,
                      showCancelButton: true,
                      cancelButtonText: 'No, Cancel',
                      confirmButtonText: 'Yes, Delete',
                      cancelButtonAriaLabel: 'No, Cancel',
                      confirmButtonAriaLabel: 'Yes, Delete',
                    }).then((result) => {
                      if (result.value) {
                        setselectedFile({ ...selectedFile, profilePicture: '' });
                      }
                    });
                  }}><i className="fa fa-trash" aria-hidden="true" /> Remove Mentor Image
                  </Button></Row>
              </Form.Item> : <Form.Item
                label="Mentor Image (800 x 800)"
                name="profilePicture" >
                <Upload {...thumbnailProps} maxCount={1}>
                  <Button icon={<UploadOutlined />}>Upload Mentor Profile Picture</Button>
                </Upload>
              </Form.Item>}
                <Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div style={{ paddingRight: 10 }}>File Url</div>
                    <Switch onChange={onUploadChecked} />
                    <div style={{ paddingLeft: 10 }}>File Upload</div>
                  </div>
                </Form.Item>
              </div>
              <div>
                {
                  isUploadVideo
                    ? <><Form.Item
                      label="Mentor Introduction video"
                      name="mentorVideo"
                      rules={[
                        {
                          required: false,
                          message: 'Enter a new video!',
                        },
                      ]}
                    >
                      <Upload
                        {...props}
                        onChange={(e) => {
                          const { file } = e;
                          if (file && file.status !== 'removed') {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                          }
                        }}
                        maxCount={1}
                      >
                        <Button icon={<UploadOutlined />}>Upload Mentor Video</Button>
                      </Upload>
                    </Form.Item>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <ReactPlayer
                          url={selectedFile.mentorShortVideoUrl}
                          controls={true}
                        />
                      </div></>
                    : <Form.Item
                      label="Mentor Introduction Video Url"
                      name="mentorShortVideoUrl"
                      rules={[
                        {
                          required: true,
                          message: 'Enter a new file url!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                }
              </div>
              <div className="mt-4 align-right">
                <Button
                  shape="round"
                  htmlType="submit"
                  style={{
                    background: 'tomato',
                    color: 'white',
                    alignSelf: 'flex-end',
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }

  export default MentorDetailsTableheader;
