/* eslint-disable no-undef */
/* eslint-disable react/display-name */
/* eslint-disable no-underscore-dangle */
/* eslint-disable object-shorthand */
/* eslint-disable no-useless-computed-key */
import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import './VideosScreen.css';
import {
    // Upload,
    Button,
    Breadcrumb,
    Modal,
    Form,
    Input,
    Select,
    Table,
    // Typography,
    Layout,
    Progress,
    Row,
} from 'antd';
// import FormData from 'form-data';
import {
    LeftOutlined,
    // UploadOutlined,
    // EditFilled,
    // PlusCircleFilled,
    // DeleteFilled,
    PlayCircleFilled,
    // FieldTimeOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import {
    // Link,
    useHistory,
    useLocation,
} from 'react-router-dom';
import ReactPlayer from 'react-player';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';

// import availableLanguages from '../../Components/AvailableLanguages/AvailableLanguages';

const AUTH_TOKEN = localStorage.getItem('token');

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({
    index,
    moveRow,
    className,
    style,
    ...restProps
}) => {
    const ref = useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: (item) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));

    DraggableBodyRow.propTypes = {
        index: PropTypes.any,
        moveRow: PropTypes.any,
        className: PropTypes.any,
        style: PropTypes.any,
        ...restProps,
    };

    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{ cursor: 'move', ...style }}
            {...restProps}
        />
    );
};

function VideosScreen() {
    const history = useHistory();
    const location = useLocation();
    const { Option } = Select;
    const { Header, Content } = Layout;
    const [avalableQuestions, setavalableQuestions] = useState([]);
    const [videoBank, setvideoBank] = useState([]);
    const [videoList, setVideoList] = useState([]);
    const [enableReorder, setEnableReorder] = useState(false);
    const [availableLanguages, setAvailableLanguages] = useState([]);
    const [videoVisible, setvideoVisible] = useState(false);
    const [availableVideoForUpload, setavailableVideoForUpload] = useState(false);
    const [videoConfirmLoading, setVideoConfirmLoading] = useState(false);
    const [playVideo, setplayVideo] = useState(false);
    const [selectedRow, setselectedRow] = useState(false);
    const [addQuestions, setaddQuestions] = useState(false);
    const [addQuestionsConfirmLoading, setaddQuestionsConfirmLoading] = useState(false);
    const [deleteQuestions, setdeleteQuestions] = useState(false);
    const [deleteQuestionsConfirmLoading, setdeleteQuestionsConfirmLoading] = useState(false);
    const [editVideoVisible, seteditVideoVisible] = useState(false);
    const [editVideoConfirmLoading, seteditVideoConfirmLoading] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState('');
    const [filter, setfilter] = useState({ filterTable: null });

    async function fetchVideos() {
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/video/bytopic/${location.state.topicId}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setvideoBank(response.data.videos);
            }
        }).catch((err) => {
            Modal.error({ content: err });
        });
    }

    async function fetchList() {
        const url = `${process.env.REACT_APP_LIBRARY_URL}/media/list`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setVideoList(response.data.files);
            }
        });
    }

    async function fetchQuestions() {
        const url = `${process.env.REACT_APP_QUESTION_URL}/questions`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setavalableQuestions(response.data.questions);
            }
        }).catch((err) => {
            // console.log(err.error);
            Modal.error({ content: err.response.data.errorMessage });
        });
    }

    async function fetchLanguages() {
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/languages`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        await axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setAvailableLanguages(response.data.languages);
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    }

    useEffect(() => {
        fetchQuestions();
        fetchVideos();
        fetchLanguages();
        fetchList();
    }, []);

    // const showEditVideoModal = () => seteditVideoVisible(true);
    const editVideoHandleCancel = () => seteditVideoVisible(false);
    const onEditVideoFinishFailed = (errorInfo) => Modal.error({ content: errorInfo });
    const deleteQuestionsHandleCancel = () => setdeleteQuestions(false);
    const onDeleteQuestionsFinishFailed = (errorInfo) => Modal.error({ content: errorInfo });
    const addQuestionsHandleCancel = () => setaddQuestions(false);
    const onAddQuestionsFinishFailed = (errorInfo) => Modal.error({ content: errorInfo });
    const playVideoHandleCancel = () => setplayVideo(false);
    const showAddVideoModal = () => setvideoVisible(true);
    const videoHandleCancel = () => { setSelectedVideo(''); setvideoVisible(false); };
    const onVideoFinishFailed = (errorInfo) => Modal.error({ content: errorInfo });

    async function reorderVideos() {
        const data = { videos: videoBank };
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/video/update-sequence/${location.state.conceptId}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.put(url, data, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                fetchVideos();
            }
        }).catch((err) => {
            Modal.error({ content: err });
        });
    }

    const components = {
        body: {
            row: (enableReorder) ? DraggableBodyRow : null,
        },
    };

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = videoBank[dragIndex];
            setvideoBank(
                update(videoBank, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );
        },
        [videoBank],
    );

    const onVideoFinish = () => {
        if (selectedVideo.title && selectedVideo._id) {
            const data = {
                title: selectedVideo.title,
                videoId: selectedVideo._id,
                moduleId: location.state.moduleId,
                topicId: location.state.topicId,
                courseId: location.state.courseId,
            };
            const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/video`;
            const config = {
                headers: { Authorization: AUTH_TOKEN },
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                        const uploadPercentage = (progressEvent.loaded / progressEvent.total) * 100;
                        setavailableVideoForUpload(uploadPercentage);
                    }
                },
            };

            axios.post(url, data, config).then((response) => {
                if (response.status === 200 && response.data.error === false) {
                    setvideoVisible(false);
                    setVideoConfirmLoading(false);
                    Modal.success({ content: `${response.data.message}` });
                    setSelectedVideo('');
                    fetchVideos();
                } else {
                    Modal.error({ content: response.data.errorMessage });
                    setavailableVideoForUpload(false);
                    setSelectedVideo('');
                }
            }).catch((err) => {
                Modal.error({ content: err.response.data.errorMessage });
                setavailableVideoForUpload(false);
                setSelectedVideo('');
            });
        }
    };

    // const props = {
    //     accept: 'video/mp4,video/x-m4v,video/*',
    //     beforeUpload: () => false,
    //     listType: 'picture',
    // };

    const showplayVideoModal = (row) => {
        setplayVideo(true);
        setselectedRow(row);
    };

    // const showaddQuestionsModal = (row) => {
    //     setaddQuestions(true);
    //     setselectedRow(row);
    // };

    const onAddQuestionsFinish = (values) => {
        setaddQuestionsConfirmLoading(true);
        const updatedQuestions = [...selectedRow.questionBank.questions];
        updatedQuestions.push(values.selectQuestion);
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/video/updatequestions/${selectedRow._id}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        const updatedQuestionsObj = {
            questionBank: updatedQuestions,
        };
        axios.put(url, updatedQuestionsObj, config).then((response) => {
            // console.log(response);
            if (response.status === 200 && response.data.error === false) {
                Modal.success({ content: `${response.data.message}` });
                fetchQuestions();
                fetchVideos();
            }
        }).catch((err) => {
            Modal.error({ content: err });
        });
    };

    // const showdeleteQuestionsModal = (row) => {
    //     setdeleteQuestions(true);
    //     setselectedRow(row);
    // };

    const onDeleteQuestionsFinish = (values) => {
        setdeleteQuestionsConfirmLoading(true);
        const updatedQuestions = [...selectedRow.questionBank.questions];
        const index = updatedQuestions.indexOf(values.selectQuestion);
        updatedQuestions.splice(index, 1);
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/video/updatequestions/${selectedRow._id}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        const updatedQuestionsObj = {
            questionBank: updatedQuestions,
        };
        axios.put(url, updatedQuestionsObj[0], config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                Modal.success({ content: `${response.data.message}` });
                fetchQuestions();
                fetchVideos();
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    };

    const onEditVideoFinish = () => {
        const data = {
            title: selectedVideo.title,
            videoId: selectedVideo._id,
            moduleId: location.state.moduleId,
            topicId: location.state.topicId,
        };
        axios.put(url, data, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                seteditVideoVisible(false);
                seteditVideoConfirmLoading(false);
                Modal.success({ content: `${response.data.message}` });
                fetchVideos();
            } else {
                Modal.error({ content: 'Video Not Edited, Please Try Again' });
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    };

    const columns = [
        {
            title: 'Video Preview',
            dataIndex: '_id',
            align: 'center',
            render: (_, record) => (
                <PlayCircleFilled
                    style={{ color: 'tomato', fontSize: 20 }}
                    onClick={() => showplayVideoModal(record)}
                />
            ),
        },
        {
            title: 'Video Title',
            dataIndex: 'title',
            align: 'center',
        },
        {
            title: 'Language',
            dataIndex: 'language',
            align: 'center',
            filters: availableLanguages,
            onFilter: (value, record) => record.language.indexOf(value) === 0,
        },
        {
            title: 'Related Questions',
            align: 'center',
            dataIndex: ['questionBank', 'length'],
        },
        // {
        //     title: 'Views',
        //     align: 'center',
        //     dataIndex: 'views',
        // },
        // {
        //     title: 'Description',
        //     align: 'description',
        //     dataIndex: 'description',
        // },
        {
            title: 'Created At',
            dataIndex: ['metaInfo', 'created', 'at'],
            align: 'center',
            render: (_, record) => {
                const { metaInfo } = record;
                return (
                    <span>
                        {moment(metaInfo.created.at).format('DD/MM/YYYY HH:mm')}
                    </span>
                );
            },
        },
        {
            title: 'Last Updated',
            dataIndex: ['metaInfo', 'updated', 'at'],
            align: 'center',
            render: (_, record) => {
                const { metaInfo } = record;
                return (
                    <span>
                        {moment(metaInfo.created.at).format('DD/MM/YYYY HH:mm')}
                    </span>
                );
            },
        },
        // {
        //     title: 'Edit',
        //     dataIndex: 'edit',
        //     align: 'center',
        //     render: (_, record) => (
        //         <Typography.Link onClick={() => {
        //             // setselectedVideo(record);
        //             setselectedRow(record);
        //             setSelectedVideo(record);
        //             showEditVideoModal();
        //         }}
        //         >
        //             <EditFilled style={{ color: 'tomato', fontSize: 20 }} />
        //         </Typography.Link>),
        // },
        // {
        //     title: 'Add Question',
        //     dataIndex: 'addQuestion',
        //     align: 'center',
        //     render: (_, row) => (
        //         <Typography.Link>
        //             <PlusCircleFilled
        //                 style={{ color: 'tomato', fontSize: 20 }}
        //                 onClick={() => {
        //                     showaddQuestionsModal(row);
        //                 }}
        //             />
        //         </Typography.Link>
        //     ),
        // },
        // {
        //     title: 'Add Moments',
        //     dataIndex: 'addMoments',
        //     align: 'center',
        //     render: (_, row) => (
        //         <Typography.Link>
        //             <Link
        //                 to={{
        //                     pathname: '/videomoments',
        //                     state: {
        //                         file: row,
        //                     },
        //                 }}
        //             >
        //                 <FieldTimeOutlined style={{ color: 'tomato', fontSize: 20 }} />
        //             </Link>
        //         </Typography.Link>
        //     ),
        // },
        // {
        //     title: 'Tarnscripts',
        //     dataIndex: 'transcripts',
        //     align: 'center',
        //     render: (_, row) => (
        //         <Typography.Link>
        //             <Link
        //                 to={{
        //                     pathname: '/transcripts',
        //                     state: { videoId: row._id },
        //                 }}
        //             >
        //                 <p className='transcriptIcon m-0' >Abc</p>
        //             </Link>
        //         </Typography.Link>
        //     ),
        // },
        // {
        //     title: 'Delete Video',
        //     dataIndex: 'deleteQuestion',
        //     align: 'center',
        //     render: (_, row) => (
        //         <Typography.Link>
        //             <DeleteFilled
        //                 style={{ color: 'tomato', fontSize: 20 }}
        //                 onClick={() => {
        //                     showdeleteQuestionsModal(row);
        //                 }}
        //             />
        //         </Typography.Link>
        //     ),
        // },
    ];

    function search(value) {
        const filterTable = videoBank.filter((o) => Object.keys(o).some((k) => String(o[k])
            .toLowerCase().includes(value.toLowerCase())));
        setfilter({ filterTable });
    }

    return (
        <div>
            <Header
                style={{
                    padding: 0,
                    textAlign: 'center',
                    fontSize: 30,
                    fontWeight: 300,
                    background: 'white',
                }} >
                {'Videos'}
            </Header>
            <Content style={{
                margin: '24px 16px 0',
                overflow: 'initial',
                background: '#fff',
                borderRadius: 10,
                boxShadow: '2px 1px 10px lightgrey',
            }}>
                <div className='videosScreenContainer'>
                    <div className='videosScreenTop'>
                        <div className='videosScreenTopBreadcrumbContainer'>
                            <LeftOutlined
                                onClick={() => history.goBack()}
                                style={{ paddingRight: 10 }}
                            />
                            <Breadcrumb>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item
                                    onClick={() => history.go(-2)}
                                >
                                    <a>Modules</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    onClick={() => history.go(-1)}
                                >
                                    <a>Topics</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Videos</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Button
                            onClick={showAddVideoModal}
                            shape="round"
                            style={{ background: 'tomato', color: 'white' }}
                        >
                            Add File
                        </Button>
                    </div>
                    <div className='videosScreenMiddle'>
                        <div className='videoSearchContainer'>
                            <Input.Search
                                style={{
                                    width: 300,
                                }}
                                placeholder="Search any field..."
                                enterButton
                                onSearch={search}
                            />
                        </div>
                        <Form component={false}>
                            <DndProvider backend={HTML5Backend}>
                                <Table
                                    size='large'
                                    showHeader={true}
                                    dataSource={filter.filterTable === null
                                        ? videoBank
                                        : filter.filterTable
                                    }
                                    columns={columns}
                                    rowClassName="editable-row"
                                    rowKey='_id'
                                    pagination={{ position: ['bottomLeft'] }}
                                    components={components}
                                    onRow={(record, index) => ({
                                        index,
                                        moveRow,
                                    })}
                                />
                            </DndProvider>
                        </Form>
                        <Row style={{ justifyContent: 'flex-end' }}>
                            <Button
                                onClick={() => setEnableReorder(!enableReorder)}
                                shape="round"
                                style={{
                                    background: 'tomato',
                                    color: 'white',
                                }}
                            >
                                {!enableReorder ? 'Enable reorder' : 'Disable reorder'}
                            </Button>
                            <Button
                                onClick={() => reorderVideos()}
                                shape="round"
                                style={{
                                    background: 'tomato',
                                    color: 'white',
                                    marginLeft: 20,
                                    marginBottom: 0,
                                }}
                            >
                                Save changes
                            </Button>
                        </Row>
                    </div>
                    <Modal
                        title="Edit Video"
                        visible={editVideoVisible}
                        confirmLoading={editVideoConfirmLoading}
                        onCancel={editVideoHandleCancel}
                        footer={[]}
                        getContainer={false}
                    >
                        <Form
                            name="basic"
                            initialValues={selectedRow}
                            onFinish={onEditVideoFinish}
                            onFinishFailed={onEditVideoFinishFailed}
                            layout={'vertical'}
                        >
                            <Label >Select Video <span style={{ color: 'red' }}>*</span></Label>
                            <Select
                                placeholder="Select a option and change input text above"
                                allowClear
                                searchValue='title'
                                value={selectedVideo._id}
                                onChange={(_, i) => i && setSelectedVideo(videoList[i.key])}
                                style={{ width: '100%' }}
                            >
                                {
                                    videoList.map((item, i) => <Option
                                        style={{ textTransform: 'capitalize' }}
                                        value={item._id}
                                        key={i}>{item.title}</Option>)
                                }
                            </Select>
                            <Label style={{ marginTop: 30 }}>Video title <span style={{ color: 'red' }}>*</span></Label>
                            <Input
                                value={selectedVideo.title}
                                onChange={(e) => setSelectedVideo(
                                    { ...selectedVideo, title: e.target.value },
                                )}
                            />

                            <Form.Item>
                                <Row justify="end">
                                    <Button
                                        htmlType="submit"
                                        shape="round"
                                        style={{
                                            background: 'tomato',
                                            color: 'white',
                                            marginTop: 20,
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Modal>

                    {/* Upload video modal */}
                    <Modal
                        title="Upload Video"
                        visible={videoVisible}
                        confirmLoading={videoConfirmLoading}
                        onCancel={videoHandleCancel}
                        footer={null}
                        getContainer={false}
                    >
                        <Form
                            name="basic"
                            // initialValues={{ remember: false }}
                            initialValues={{
                                ['title']: selectedVideo.title,
                            }}
                            onFinish={onVideoFinish}
                            onFinishFailed={onVideoFinishFailed}
                            layout={'vertical'}
                        >
                            <Label >Select Video <span style={{ color: 'red' }}>*</span></Label>
                            <Select
                                placeholder="Select a option and change input text above"
                                allowClear
                                searchValue='title'
                                onChange={(_, i) => i && setSelectedVideo(videoList[i.key])}
                                style={{ width: '100%' }}
                            >
                                {
                                    videoList.map((item, i) => <Option
                                        style={{ textTransform: 'capitalize' }}
                                        value={item._id}
                                        key={i}>{item.title}</Option>)
                                }
                            </Select>
                            <Label style={{ marginTop: 30 }}>Video title <span style={{ color: 'red' }}>*</span></Label>
                            <Input
                                value={selectedVideo.title}
                                onChange={(e) => setSelectedVideo(
                                    { ...selectedVideo, title: e.target.value },
                                )}
                            />
                            <Form.Item>
                                {
                                    availableVideoForUpload && <Progress
                                        strokeColor={{
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                        }}
                                        percent={availableVideoForUpload}
                                        format={() => ''}
                                    />
                                }
                            </Form.Item>

                            <Form.Item>
                                <Row justify="end">
                                    <Button
                                        htmlType="submit"
                                        shape="round"
                                        style={{ background: 'tomato', color: 'white' }}
                                    >
                                        Submit
                                    </Button>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Modal>

                    {/* Add questions to video */}
                    <Modal
                        title="Add Question"
                        visible={addQuestions}
                        confirmLoading={addQuestionsConfirmLoading}
                        onCancel={addQuestionsHandleCancel}
                        footer={[]}
                        getContainer={false}
                    >
                        <Form
                            name="basic"
                            initialValues={{ remember: false }}
                            onFinish={onAddQuestionsFinish}
                            onFinishFailed={onAddQuestionsFinishFailed}
                            layout={'vertical'}
                        >
                            <Form.Item
                                name="selectQuestion"
                                label="Select Question"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a Question!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select a option and change input text above"
                                    allowClear
                                    mode="multiple"
                                >
                                    {
                                        avalableQuestions.map((question, index) => <Option
                                            value={question._id}
                                            key={index}
                                        >{question.question}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Row justify="end">
                                    <Button
                                        htmlType="submit"
                                        shape="round"
                                        style={{ background: 'tomato', color: 'white' }}
                                    >
                                        Add
                                    </Button>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Modal>

                    {/* delete questions */}
                    <Modal
                        title="Delete Question"
                        visible={deleteQuestions}
                        confirmLoading={deleteQuestionsConfirmLoading}
                        onCancel={deleteQuestionsHandleCancel}
                        footer={[]}
                        getContainer={false}
                    >
                        <Form
                            name="basic"
                            initialValues={{ remember: false }}
                            onFinish={onDeleteQuestionsFinish}
                            onFinishFailed={onDeleteQuestionsFinishFailed}
                            layout={'vertical'}
                        >
                            <Form.Item
                                name="selectQuestion"
                                label="Select Question"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a Question!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select a option and change input text above"
                                    allowClear
                                >
                                    {
                                        selectedRow && selectedRow.questionBank.questions.map(
                                            (question, index) => <Option
                                                value={question._id}
                                                key={index}
                                            >{question.question}</Option>,
                                        )
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Row justify="end">
                                    <Button
                                        htmlType="submit"
                                        shape="round"
                                        style={{ background: 'tomato', color: 'white' }}
                                    >
                                        Delete
                                    </Button>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Modal>

                    {/* Preview video */}
                    <Modal
                        title="Video Preview"
                        visible={playVideo}
                        onCancel={playVideoHandleCancel}
                        footer={[]}
                        width={1000}
                        getContainer={false}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ReactPlayer
                                url={selectedRow.videoUrl}
                                controls={true}
                            />
                        </div>
                    </Modal>
                </div>
            </Content>
        </div>
    );
}

export default VideosScreen;
