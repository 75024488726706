import { React, useState,useEffect } from 'react';
import { Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import DailyTipsModal from './DailyTipsModal';
import TableHeader from './TableHeader';
import axios from 'axios';


function DailyTipsScreen() {

    const [showModal, setShowModal] = useState(false);
    const [businessIdeas,setBusinessIdeas] = useState([]);

    async function fetchBusinessIdeas() {
        const url = `${process.env.REACT_APP_SWATI_API_URL}/api/v1/bussinessIdeas/all-detailed`;
        axios.get(url).then((response) => {
            if (response.status) {
                setBusinessIdeas(response.data.bussinessIdeas || []);
            }
        });
    }

    useEffect(()=>{
        fetchBusinessIdeas();
    },[]);
    
    return (

        <div >
        <Content className="site-layout-background header" style={{ boxShadow: '2px 1px 10px lightgrey' }}>
                <p></p>
                <h3>Daily Tips</h3>
                <Button
                    onClick={() => { setShowModal(true); }}
                    shape="round"
                    style={{ background: 'tomato', color: 'white' }}
                >
                    Add Tip
                </Button>
            </Content>

     <div className="libraryScreenMiddle">
  <TableHeader 
        close={() => {
            setShowModal(false);
        }}
        show={showModal}
        fetchBusinessIdeas={fetchBusinessIdeas}
        businessIdeas={businessIdeas}
  />

     </div>

            <DailyTipsModal
                close={() => {
                    setShowModal(false);
                }}
                show={showModal}
                fetchBusinessIdeas={fetchBusinessIdeas}
                businessIdeas={businessIdeas}
                // editGroup={selectedGroup}
                // handleSubmit={handleSubmit}
            />
        </div>
        );
}

export default DailyTipsScreen;
