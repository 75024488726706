/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import './CourseScreen.css';
import {
  Breadcrumb,
  Layout,
  Button,
  Modal,
  // Form,
  Input,
  InputNumber,
  Row,
  Table,
  Select,
  Switch,
  Col,
  Image,
  Upload,
} from 'antd';
import { DeleteTwoTone, EditTwoTone, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { languageContext } from '../../helpers/LangContext';

const AUTH_TOKEN = localStorage.getItem('token');

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  DraggableBodyRow.propTypes = {
    index: PropTypes.any,
    moveRow: PropTypes.any,
    className: PropTypes.any,
    style: PropTypes.any,
    ...restProps,
  };

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

const requiredField = <span style={{ color: 'red' }}>*</span>;

function CourseScreen() {
  const { Header, Content } = Layout;
  const [courses, setCourses] = useState([]);
  const [courseVisible, setCourseVisible] = useState(false);
  const [courseConfirmLoading, setCourseConfirmLoading] = useState(false);
  const [filter, setFilter] = useState({ filterTable: null });
  const [enableReorder, setEnableReorder] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('english');
  const [availableLanguages, setAvailableLanguages] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  <p style={{ marginTop: 20 }}>Course Description </p>;
  const [formValues, setFormValues] = useState({
    language: '',
    name: '',
    description: '',
    pricingPlan: {
      planType: '',
      courseFee: '',
    },
    modulesSkippable: false,
    hasOnlyVideos: false,
    thumbnail: '',
    status: 'draft',
  });

  const { Option } = Select;
  const history = useHistory();
  const context = useContext(languageContext);

  const resetForm = () => {
    setFormValues({
      language: '',
      name: '',
      description: '',
      pricingPlan: {
        planType: '',
        courseFee: '',
      },
      modulesSkippable: false,
      hasOnlyVideos: false,
      thumbnail: '',
      status: 'draft',
    });
    setImageUrl(null);
  };

  async function fetchCourse() {
    const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/course`;
    const config = { headers: { Authorization: AUTH_TOKEN } };
    axios.get(url, config).then((response) => {
      if (response.status === 200) {
        console.log('Response;', response.data.courses);
        setCourses(response?.data?.courses);
      }
    }).catch((err) => {
      console.log('err', err);
      Modal.error({ content: err?.response?.data?.errorMessage });
    });
  }

  const showAddCourseModal = () => setCourseVisible(true);
  const courseHandleCancel = () => {
    setCourseVisible(false);
    resetForm();
  };

  async function fetchLanguages() {
    const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/languages`;
    const config = { headers: { Authorization: AUTH_TOKEN } };
    axios.get(url, config).then((response) => {
      if (response.status === 200 && response.data.error === false) {
        setAvailableLanguages(response.data.languages);
      }
    }).catch((err) => {
      Modal.error({ content: err.response.data.errorMessage });
    });
  }

  useEffect(() => {
    fetchCourse();
    fetchLanguages();
    setSelectedLanguage(context.state.selectedLanguage);
  }, [context]);

  const onFetchFailure = (errorMessage) => Modal.error({ content: errorMessage });

  const courseFetchSuccess = (response) => {
    setCourseVisible(false);
    setShowDeleteModal(false);
    setCourseConfirmLoading(false);
    resetForm();
    Modal.success({ content: `${response.data.message}` });
    fetchCourse();
  };

  const onCourseFinish = () => {
    setCourseConfirmLoading(true);
    // const data = formValues;
    // const config = { headers: { Authorization: AUTH_TOKEN } };
    const data = new FormData();
    data.append('language', formValues.language);
    data.append('name', formValues.name);
    data.append('description', formValues.description);
    data.append('courseFee', formValues.pricingPlan.courseFee);
    data.append('modulesSkippable', formValues.modulesSkippable);
    data.append('hasOnlyVideos', formValues.hasOnlyVideos);
    data.append('status', formValues.status);
    if (formValues?.thumbnail?.file) {
      data.append('thumbnail', formValues.thumbnail.file);
    } else data.append('thumbnail', formValues.thumbnail);
    const config = { headers: { Authorization: AUTH_TOKEN } };
    const { _id } = formValues;
    if (_id) {
      const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/course/${_id}`;
      axios.put(url, data, config).then((response) => {
        if (response.status === 200 && response.data.error === false) {
          courseFetchSuccess(response);
        } else {
          onFetchFailure('Something went wrong, Please Try Again');
        }
      }).catch((err) => onFetchFailure(err.response.data.errorMessage));
    } else {
      const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/course`;
      axios.post(url, data, config).then((response) => {
        if (response.status === 200 && response.data.error === false) {
          courseFetchSuccess(response);
        } else {
          onFetchFailure('Something went wrong, Please Try Again');
        }
      }).catch((err) => onFetchFailure(err.response.data.errorMessage));
    }
  };

  const deleteCourse = (_id) => {
    const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/course/${_id}`;
    const config = { headers: { Authorization: AUTH_TOKEN } };
    axios.delete(url, config).then((response) => {
      if (response.status === 200 && response.data.error === false) {
        courseFetchSuccess(response);
      } else {
        onFetchFailure('Something went wrong, Please Try Again');
      }
    }).catch((err) => onFetchFailure(err.response.data.errorMessage));
  };

  const columns = [
    {
      title: 'Course Id',
      dataIndex: ['referenceId'],
    },
    {
      title: 'Course Name',
      dataIndex: ['name'],
      key: 'name',
      render: (course, currentCourse) => <p style={{ cursor: 'pointer', color: 'blue' }}
        onClick={
          () => {
            history.push({
              pathname: `/directory/${currentCourse._id}`,
              state: {
                courseId: currentCourse._id,
                modules: currentCourse.modules,
                lang: selectedLanguage,
              },
            });
          }
        }>
        {course} <span style={{ marginLeft: '10px', float: 'right' }} title={currentCourse.description || 'No description to show!'}>
          &#9432;
        </span>
      </p>,
    },
    // {
    //   title: 'Description',
    //   dataIndex: ['description'],
    //   key: 'description',
    // },
    {
      title: 'Pricing Plan Type',
      dataIndex: ['pricingPlan, planType'],
      // key: 'pricingPlan.planType',
      render: (tab, row) => <span style={{ textTransform: 'capitalize' }}>{row?.pricingPlan?.planType || 'Free'} </span>,
    },
    {
      title: 'Course Fee',
      dataIndex: ['pricingPlan, courseFee'],
      // key: 'pricingPlan.courseFee',
      render: (tab, row) => <span style={{ textTransform: 'capitalize' }}>{row?.pricingPlan?.courseFee || '-'} </span>,
    },
    {
      title: 'Contents',
      dataIndex: ['modulesCount'],
      align: 'center',
    },
    {
      title: 'Are videos skippable',
      dataIndex: ['modulesSkippable'],
      key: 'modulesSkippable',
      align: 'center',
      render: (tab) => <span>{tab ? 'Yes' : 'No'} </span>,
    },
    {
      title: 'Created At',
      dataIndex: ['created'],
      key: 'modulesSkippable',
      render: (created) => <span title={moment(created?.at).format('MMMM Do YYYY, h:mm:ss a')}>
        {created ? moment(created?.at).format('MMM Do YY') : ''}
      </span>,
    },
    {
      title: 'Last Updated',
      dataIndex: ['updated'],
      key: 'modulesSkippable',
      render: (updated) => <span title={moment(updated?.at).format('MMMM Do YYYY, h:mm:ss a')}>
        {updated ? moment(updated?.at).format('MMM Do YY') : ''}
      </span>,
    },
    {
      title: 'Edit',
      dataIndex: ['edit'],
      align: 'center',
      render: (_, row) => <EditTwoTone
        twoToneColor='tomato'
        style={{ fontSize: 22 }}
        onClick={() => {
          setFormValues(row);
          setImageUrl(row?.thumbnail);
          showAddCourseModal(true);
        }}
      />,
    },
    {
      title: 'Delete',
      dataIndex: ['delete'],
      align: 'center',
      render: (_, row) => <DeleteTwoTone
        twoToneColor='tomato'
        style={{ fontSize: 22 }}
        className='iconButton'
        onClick={() => {
          setFormValues(row);
          setShowDeleteModal(true);
        }}
      />,
    },
    // {
    //   title: 'Has only videos',
    //   dataIndex: ['hasOnlyVideos'],
    //   key: 'hasOnlyVideos',
    // },
    // {
    //   title: 'Description',
    //   dataIndex: ['description'],
    //   key: 'description',
    // },
    // {
    //   title: 'Modules',
    //   dataIndex: ['modulesCount'],
    //   key: 'modulesCount',
    //   render: (modules, currentCourse) => <p style={{ cursor: 'pointer', color: 'blue' }}
    //     onClick={
    //       () => {
    //         history.push({
    //           pathname: `/directory/${currentCourse._id}`,
    //           state: {
    //             courseId: currentCourse._id,
    //             modules: currentCourse.modules,
    //             lang: selectedLanguage,
    //           },
    //         });
    //       }
    //     }>
    //     {modules}
    //   </p>,
    // },
  ];

  const components = {
    body: {
      row: (enableReorder) ? DraggableBodyRow : null,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = courses[dragIndex];
      setCourses(
        update(courses, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [courses],
  );

  const reorderCourses = () => {
    const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/course/update-sequence`;
    const config = { headers: { Authorization: AUTH_TOKEN } };
    const data = { courses };
    axios.put(url, data, config).then((response) => {
      if (response.status === 200 && response.data.error === false) {
        setCourses(response.data.courses);
        fetchCourse();
        setEnableReorder(false);
      }
    }).catch((err) => {
      Modal.error({ content: err.response.data.errorMessage });
    });
  };

  function search(value) {
    const filterTable = courses.filter((o) => Object.keys(o).some((k) => String(o[k])
      .toLowerCase().includes(value.toLowerCase())));
    setFilter({ filterTable });
  }

  const handleDeleteThumbnail = () => {
    setFormValues({ ...formValues, thumbnail: '' });
    setImageUrl(null);
  };

  const props = {
    accept: 'image/*',
    beforeUpload: () => false,
    listType: 'picture',
  };

  return <div>
    <Header className='courseHeader'>
      {'Course'}
    </Header>
    <Content className='courseContent'>
      <div className='courseScreenContainer'>
        <div className='courseScreenTop'>
          <div className='courseScreenTopBreadcrumbContainer'>
            <Breadcrumb>
              {/* <Breadcrumb.Item>Home</Breadcrumb.Item> */}
              <Breadcrumb.Item>Course</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Button
            onClick={showAddCourseModal}
            shape="round"
            style={{ background: 'tomato', color: 'white' }}
          >
            Add Course
          </Button>
        </div>
        <div className='courseSearchContainer' style={{ width: '100%' }}>
          {availableLanguages && <Select
            defaultValue={'select language'}
            style={{ width: 120, marginRight: 20 }}
            onChange={(value) => {
              setSelectedLanguage(value);
              context.saveSelectedLanguage(value);
            }}
          >
            {availableLanguages.map((language, index) => <Option
              style={{ textTransform: 'capitalize' }}
              key={index}
              value={language.label.toLocaleLowerCase()}
            >{language.label}
            </Option>)}
          </Select>}
          <Input.Search
            style={{ width: 300 }}
            placeholder="Search any field..."
            enterButton
            onChange={(e) => search(e.target.value)}
          />
        </div>
        <div style={{ width: '100%', padding: 10 }}>
          <Row>
            <DndProvider backend={HTML5Backend}>
              <Table
                style={{ width: '100%', paddingRight: 20 }}
                pagination={{
                  position: ['bottomLeft'],
                  defaultPageSize: 20,
                  showSizeChanger: true,
                  pageSizeOptions: ['20', '50', '100'],
                }}
                columns={columns}
                components={components}
                onRow={(record, index) => ({
                  index,
                  moveRow,
                })}
                dataSource={filter.filterTable === null
                  ? courses : filter.filterTable}
              />
            </DndProvider>
          </Row>
          <Row style={{ justifyContent: 'flex-end', paddingRight: 20, marginTop: 10 }}>
            <Button
              onClick={() => setEnableReorder(!enableReorder)}
              shape="round"
              style={{
                background: 'tomato',
                color: 'white',
              }}
            >
              {!enableReorder ? 'Enable reorder' : 'Disable reorder'}
            </Button>
            <Button
              onClick={() => reorderCourses()}
              shape="round"
              style={{
                background: 'tomato',
                color: 'white',
                marginLeft: 20,
              }}
            >
              Save changes
            </Button>
          </Row>
        </div>
        <Modal
          title="Add Course"
          visible={courseVisible}
          confirmLoading={courseConfirmLoading}
          onCancel={courseHandleCancel}
          footer={[]}
          getContainer={false}
          width={800}
        >
          <p>
            <span style={{ width: '40%' }}>Select Language {requiredField}</span>
            {availableLanguages && <Select
              defaultValue={'Select language'}
              className='selectLang'
              value={formValues?.language}
              onChange={(value) => setFormValues({ ...formValues, language: value })}
            >
              <Option value={''} disabled>Select language</Option>
              {availableLanguages.map((language, index) => <Option
                style={{ textTransform: 'capitalize' }}
                key={index}
                value={language.label.toLowerCase()}
              > {language.label}
              </Option>)}
            </Select>}</p>
          <p className='label'>Course Name {requiredField}</p>
          <input
            className='input'
            type='text'
            name='name'
            value={formValues.name}
            onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
          />
          <p className='label'>Course Description </p>
          <textarea
            className='textArea'
            name='description'
            value={formValues.description}
            onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
          />
          {/* <p className='label'>Pricing plan type {requiredField}
            <Select
              defaultValue={'Select Pricing Plan Type'}
              value={formValues?.pricingPlan?.planType}
              className='selectLang'
              onChange={(value) => setFormValues({
                ...formValues,
                pricingPlan: { planType: value, courseFee: value === 'free'
                ? '' : formValues?.pricingPlan?.courseFee },
              })}
            >
              <Option value={''} disabled>Select Pricing Plan Type</Option>
              {['Free', 'Paid'].map((planType, index) => <Option
                style={{ textTransform: 'capitalize' }}
                key={index}
                value={planType.toLowerCase()}
              > {planType}
              </Option>)}
            </Select></p>
          {formValues.pricingPlan?.planType === 'paid'
            && <> */}
          <p className='label'>Course Fee (in INR) {requiredField}
            <InputNumber
              min='0'
              style={{ marginRight: 20, marginLeft: 20 }}
              placeholder={'Enter course fee'}
              value={formValues.pricingPlan?.courseFee}
              onChange={(value) => setFormValues({
                ...formValues,
                pricingPlan: { ...formValues.pricingPlan, courseFee: value },
              })}
            />
          </p>
          {/* </>} */}
          <Row>
            <Col md={12} lg={12}>
              <p className='label'>Are Videos skippable: {requiredField}
                <Switch
                  className='switch'
                  defaultChecked={formValues?.modulesSkippable}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={(checked) => setFormValues({
                    ...formValues, modulesSkippable: checked,
                  })}
                />
              </p>
            </Col>
            <Col md={12} lg={12}>
              <p className='label'>Status: {requiredField}
                <Switch
                  className='switch'
                  defaultChecked={formValues?.status === 'live'}
                  checkedChildren="Live"
                  unCheckedChildren="Draft"
                  onChange={() => setFormValues({
                    ...formValues,
                    status: formValues?.status === 'live' ? 'draft' : 'live',
                  })}
                />
              </p>
            </Col>
            {/* <Col>
              <p className='label'>Course has only videos: {requiredField}
                <Switch
                  className='switch'
                  defaultChecked={formValues?.hasOnlyVideos}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={(checked) => setFormValues({ ...formValues, hasOnlyVideos: checked })}
                />
              </p>
            </Col> */}
          </Row>

          {/* <p className='label'>Are modules skippable: <span style={{ color: 'red' }}>*</span>
            <Switch
              className='switch'
              defaultChecked={formValues.modulesSkippable}
              checkedChildren="Y"
              unCheckedChildren="N"
              onChange={(checked) => setFormValues({ ...formValues, modulesSkippable: checked })}
            />
          </p> */}

          {/* <p style={{ marginTop: 10 }}>Course has only videos:
           <span style={{ color: 'red' }}>*</span>
            <Switch style={{ width: '10%', marginRight: 20, marginLeft: 20 }}
            defaultChecked={formValues.hasOnlyVideos} checkedChildren="Y" unCheckedChildren="N"
              onChange={(checked) => setFormValues({ ...formValues, hasOnlyVideos: checked })}
            /></p> */}
          <p className='label'>Thumbnail: {requiredField}</p>
          <Row>
            <Col md={18} lg={18}>
              {imageUrl
                ? <Image src={imageUrl} style={{ height: 150, width: 200 }} />
                : <Upload
                  {...props}
                  accept='.png, .jpg, .jpeg'
                  listType="picture-card"
                  fileList={formValues?.thumbnail?.fileList}
                  onPreview={() => console.log('Preview')}
                  onChange={(file) => {
                    setFormValues({ ...formValues, thumbnail: file });
                    setImageUrl(URL.createObjectURL(file.file));
                  }}
                >
                  {formValues?.thumbnail?.fileList?.length > 0 ? null : <div>
                    <PlusOutlined />
                    <div className="ant-upload-text">Upload</div>
                  </div>}
                </Upload>}
            </Col>
            <Col>
              {imageUrl
                && <Button shape="round" className='delete-btn' onClick={handleDeleteThumbnail}>
                  Delete
                </Button>}
            </Col>
          </Row>
          <Row justify="end">
            <Button
              onClick={() => onCourseFinish()}
              htmlType="submit"
              shape="round"
              disabled={(formValues.language === '' || formValues.name === '' || formValues?.pricingPlan?.courseFee === '' || formValues.modulesSkippable === undefined || formValues.hasOnlyVideos === undefined || !imageUrl)}
              style={{
                pointerEvents: (formValues.language === '' || formValues.name === '' || formValues?.pricingPlan?.courseFee === '' || formValues.modulesSkippable === undefined || formValues.hasOnlyVideos === undefined || !imageUrl) ? 'none' : 'auto',
                background: (formValues.language === '' || formValues.name === '' || formValues?.pricingPlan?.courseFee === '' || formValues.modulesSkippable === undefined || formValues.hasOnlyVideos === undefined || !imageUrl) ? 'lightgrey' : 'tomato',
                color: 'white',
              }}
            >
              Submit
            </Button>
          </Row>
        </Modal>
        <Modal
          title={'Delete Course 😲'}
          centered
          visible={showDeleteModal}
          onOk={() => deleteCourse(formValues._id)}
          onCancel={() => setShowDeleteModal(false)}
        >
          <p>Are you sure you want to delete course <strong>{formValues?.name}</strong>?</p>
        </Modal>
      </div>
    </Content>
  </div>;
}

export default CourseScreen;
