/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import './QuestionBankScreen.css';
import {
    Button,
    Breadcrumb,
    Modal,
    Form,
    Input,
    Select,
    Checkbox,
    Layout,
    Row,
} from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import QuestionTable from '../../Components/QuestionTable/QuestionTable';
import availableLanguages from '../../Components/AvailableLanguages/AvailableLanguages';

const AUTH_TOKEN = localStorage.getItem('token');

function QuestionBankScreen() {
    const { Option } = Select;
    const { Header, Content } = Layout;

    const [availableCatagories, setavailableCatagories] = useState([]);
    async function fetchCategories() {
        const url = `${process.env.REACT_APP_QUESTION_URL}/categories`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setavailableCatagories(response.data.categories);
            }
        }).catch((err) => {
            // console.log(err);
            Modal.error({ content: err.response.data.errorMessage });
        });
    }
    useEffect(() => {
        fetchCategories();
    }, []);

    // ------------------------------- ADDING CATEGORY MODAL ---------------------------------
    const [categoryVisible, setCategoryVisible] = useState(false);
    const [categoryConfirmLoading, setCategoryConfirmLoading] = useState(false);

    const showCategoryModal = () => setCategoryVisible(true);
    const categoryHandleCancel = () => setCategoryVisible(false);
    const onCategoryFinishFailed = (errorInfo) => Modal.error({ content: errorInfo });

    const onCategoryFinish = (values) => {
        setCategoryConfirmLoading(true);

        const url = `${process.env.REACT_APP_QUESTION_URL}/categories`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        const newCategory = { name: values.categoryName };
        axios.post(url, newCategory, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setCategoryVisible(false);
                setCategoryConfirmLoading(false);
                fetchCategories();
                Modal.success({ content: `${response.data.message}` });
            } else {
                Modal.error({ content: 'Category Not Added, Please Try Again' });
            }
        }).catch((err) => {
            // console.log(err.response);
            Modal.error({ content: err.response.data.errorMessage });
        });
    };

    // --------------------------- ADDING QUESTION MODAL ------------------------------
    const [questionVisible, setQuestionVisible] = useState(false);
    const [questionConfirmLoading, setQuestionConfirmLoading] = useState(false);
    // const [correctOptionsIndices, setcorrectOptionsIndices] = useState([]);
    const [correctOptionsIndices, setcorrectOptionsIndices] = useState(null);

    const showQuestionModal = () => setQuestionVisible(true);
    const questionHandleCancel = () => setQuestionVisible(false);
    const onQuestionFinishFailed = (errorInfo) => Modal.error({ content: errorInfo });

    const onQuestionFinish = (values) => {
        const correctOptionsList = [];
        for (let i = 0; i < values.options.length; i += 1) {
            const tempOptionObj = {
                option: values.options[i],
                isTrue: i === correctOptionsIndices,
            };
            correctOptionsList.push(tempOptionObj);
        }
        setQuestionConfirmLoading(true);
        const url = `${process.env.REACT_APP_QUESTION_URL}/questions`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        const newQuestion = {
            question: values.question,
            language: values.selectLanguage,
            categoryId: values.selectCategory,
            helpText: values.helpText,
            optionFormat: 'Radio',
            options: correctOptionsList,
            difficulty: values.difficulty,
        };
        axios.post(url, newQuestion, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setQuestionVisible(false);
                setQuestionConfirmLoading(false);
                Modal.success({ content: `${response.data.message}` });
            } else {
                Modal.error({ content: 'Question Not Added, Please Try Again' });
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    };

    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 20, offset: 4 },
        },
    };

    // function onCheckedOptions(e, fieldName) {
    //     setcorrectOptionsIndices(tempIndices);
        // const tempIndices = [...correctOptionsIndices];
        // if (e.target.checked === true) tempIndices.push(fieldName);
        // else {
        //     const index = tempIndices.indexOf(fieldName);
        //     tempIndices.splice(index, 1);
        // }
        // setcorrectOptionsIndices(tempIndices);
    // }

    const difficulty = ['Easy', 'Medium', 'Hard'];

    return (
        <div>
            <Header
                style={{
                    padding: 0,
                    textAlign: 'center',
                    fontSize: 30,
                    fontWeight: 300,
                    background: 'white',
                }} >
                { 'Question Bank' }
            </Header>
            <Content style={{
                    margin: '24px 16px 0',
                    overflow: 'initial',
                    background: '#fff',
                    borderRadius: 10,
                    boxShadow: '2px 1px 10px lightgrey',
                }}>
                <div className='questionBankScreenContainer'>
                    <div className='questionBankTop'>
                        <div className='questionBankTopBreadcrumbContainer'>
                            <Breadcrumb>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <a href="">Question Bank</a>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <div className='questionBankTopButtonContainer'>
                            <Button
                                onClick={showCategoryModal}
                                shape="round"
                                style={{ background: 'tomato', color: 'white' }}
                            >
                                Add Category
                            </Button>
                            <Button
                                onClick={showQuestionModal}
                                shape="round"
                                style={{ background: 'tomato', color: 'white' }}
                            >
                                Add Question
                            </Button>
                        </div>
                    </div>

                    <div className='questionBankMiddle'>
                        <QuestionTable/>
                    </div>
                    {/* ADDING A NEW CATEGORY */}
                    <Modal
                        title="Add Category"
                        visible={categoryVisible}
                        confirmLoading={categoryConfirmLoading}
                        onCancel={categoryHandleCancel}
                        footer={[]}
                    >
                        <Form
                            name="basic"
                            initialValues={false}
                            onFinish={onCategoryFinish}
                            onFinishFailed={onCategoryFinishFailed}
                            layout={'vertical'}

                        >
                            <Form.Item
                                label="Category Name"
                                name="categoryName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter a new category!',
                                    },
                                    () => ({
                                        validator(_, value) {
                                        let alreadyThere = false;
                                        for (let i = 0; i < availableCatagories.length; i += 1) {
                                            if (value === availableCatagories[i].name) {
                                                alreadyThere = true;
                                            }
                                        }
                                        if (!value || !alreadyThere) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Another Category with the same name already exists.'));
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item>
                                <Row justify="end">
                                    <Button
                                        htmlType="submit"
                                        shape="round"
                                        style={{ background: 'tomato', color: 'white' }}
                                    >
                                        Submit
                                    </Button>
                                </Row>
                            </Form.Item>

                        </Form>
                    </Modal>
                    {/* ADDING A NEW QUESTION */}
                    <Modal
                        title="Add Question"
                        visible={questionVisible}
                        confirmLoading={questionConfirmLoading}
                        onCancel={questionHandleCancel}
                        footer={[]}
                        getContainer={false}
                    >
                        <Form
                            name="basic"
                            onFinish={onQuestionFinish}
                            onFinishFailed={onQuestionFinishFailed}
                            layout={'vertical'}
                        >

                            <Form.Item name="selectLanguage" label="Select Language"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    placeholder="Select a option and change input text above"
                                    allowClear
                                >
                                    {
                                        availableLanguages.map((language, index) => <Option
                                            value={language.value}
                                            key={index}>{language.text}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="selectCategory" label="Select Category"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    placeholder="Select a option and change input text above"
                                    allowClear
                                >
                                    {
                                        availableCatagories.map((category, index) => <Option
                                            // eslint-disable-next-line no-underscore-dangle
                                            value={category._id}
                                            key={index}>{category.name}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="question" label="Question"
                                rules={[{
                                    required: true,
                                    message: 'Enter a new Question!',
                                }]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item name="difficulty" label="Difficulty"
                                rules={[{
                                    required: true,
                                    message: 'Select difficulty level',
                                }]}
                            >
                            <Select
                                    placeholder="Select difficulty level for question"
                                    allowClear
                                >
                                    {
                                        difficulty.map((level, index) => <Option
                                            // eslint-disable-next-line no-underscore-dangle
                                            value={level}
                                            key={index}>{level}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Help Text" name="helpText">
                                <Input />
                            </Form.Item>
                            {/* <Form.Item name="optionFormat" label="Options Format"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    placeholder="Select a option and change input text above"
                                    allowClear
                                >
                                    <Option value="Radio">Radio</Option>
                                    <Option value="Dropdown">Dropdown</Option>
                                    <Option value="Checkbox">Checkbox</Option>
                                </Select>
                            </Form.Item> */}

                            <Form.List
                                name="options"
                                rules={[
                                {
                                    // eslint-disable-next-line consistent-return
                                    validator: async (_, options) => {
                                    if (!options || options.length < 2) {
                                        return Promise.reject(new Error('At least 2 options'));
                                    }
                                    },
                                },
                                ]}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            { ...formItemLayoutWithOutLabel }
                                            label={index === 0 ? 'Options ( Select the correct options )' : ''}
                                            required={false}
                                            key={field.key}
                                        >
                                            <Checkbox
                                                onChange={() => {
                                                    setcorrectOptionsIndices(field.name);
                                                }}
                                                checked={ correctOptionsIndices === field.name }
                                                style={{ marginRight: 10 }}
                                            />
                                            <Form.Item
                                                {...field}
                                                validateTrigger={['onChange', 'onBlur']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        whitespace: true,
                                                        message: 'Please enter an option or delete this field.',
                                                    },
                                                ]}
                                                noStyle
                                            >
                                                <Input placeholder="Option" style={{ width: '60%' }} />
                                            </Form.Item>
                                            {
                                                fields.length > 1
                                                ? (
                                                    <Button
                                                        // type="primary"
                                                        className="dynamic-delete-button"
                                                        style={{ background: 'tomato', color: 'white' }}
                                                        onClick={() => remove(field.name)}
                                                        icon={<MinusOutlined />}
                                                        shape="circle"
                                                    />
                                                ) : null
                                            }
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            onClick={() => add()}
                                            style={{
                                                background: 'tomato',
                                                color: 'white',
                                                justifySelf: 'center',
                                                alignSelf: 'center',
                                            }}
                                            icon={<PlusOutlined />}
                                            shape="circle"
                                        />
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </>
                                )}
                            </Form.List>

                            <Form.Item>
                                <Row justify="end">
                                    <Button
                                        htmlType="submit"
                                        shape="round"
                                        style={{ background: 'tomato', color: 'white' }}
                                    >
                                        Save
                                    </Button>
                                </Row>
                            </Form.Item>

                        </Form>
                    </Modal>

                </div>
            </Content>
        </div>
    );
}

export default QuestionBankScreen;
