/* eslint-disable no-useless-computed-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
/* eslint-disable no-undef */
import {
    Layout,
    Modal,
    Breadcrumb,
    Button,
    Progress,
    Input,
    Row,
    Table,
    Select,
    Form,
    notification,
    Switch,
} from 'antd';
import {
    DeleteTwoTone,
    EditTwoTone,
    PlayCircleFilled,
} from '@ant-design/icons';
import { Label } from 'reactstrap';
import ReactPlayer from 'react-player';
// import {
//     PlayCircleFilled,
// } from '@ant-design/icons';
import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useContext,
} from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import {
    useHistory,
} from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from 'axios';
import moment from 'moment';
import './DirectoryScreen.css';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { languageContext } from '../../helpers/LangContext';

const AUTH_TOKEN = localStorage.getItem('token');

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({
    index,
    moveRow,
    className,
    style,
    ...restProps
}) => {
    const ref = useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: (item) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));

    DraggableBodyRow.propTypes = {
        index: PropTypes.any,
        moveRow: PropTypes.any,
        className: PropTypes.any,
        style: PropTypes.any,
        ...restProps,
    };

    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{ cursor: 'move', ...style }}
            {...restProps}
        />
    );
};

export default function DirectoryScreen() {
    const { Header, Content } = Layout;
    const history = useHistory();
    const [folderId, setFolderId] = useState('');
    const [descendants, setDescendants] = useState([]);
    const [ancestors, setAncestors] = useState([]);
    const [courseInfo, setCourseInfo] = useState({});
    const [folderModalVisible, setFolderModalVisible] = useState(false);
    const [fileModalVisible, setFileModalVisible] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState({});
    const [selectedRow, setselectedRow] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [videoVisible, setvideoVisible] = useState(false);
    // const showAddVideoModal = () => setvideoVisible(true);
    const [playVideo, setplayVideo] = useState(false);

    const [videoUrl, setVideoUrl] = useState('');
    const playVideoHandleCancel = () => { setplayVideo(false); setVideoUrl(''); };

    const showplayVideoModal = (row) => {
        setplayVideo(true);
        setVideoUrl(row.fileId.fileUrl);
    };
    // const videoHandleCancel = () => {
    //     setSelectedVideo({ freeToWatch: false }); setvideoVisible(false);
    // };

    // const [editVideoVisible, seteditVideoVisible] = useState(false);
    // const [editVideoConfirmLoading, seteditVideoConfirmLoading] = useState(false);

    // const editVideoHandleCancel = () => seteditVideoVisible(false);

    // const showEditVideoModal = () => seteditVideoVisible(true);
    // const location = useLocation();
    const [videoConfirmLoading, setVideoConfirmLoading] = useState(false);
    const fileHandleCancel = () => setFileModalVisible(false);
    const [folderConfirmLoading, setFolderConfirmLoading] = useState(false);
    const [fileConfirmLoading, setFileConfirmLoading] = useState(false);
    const [filter, setFilter] = useState({ filterTable: null });
    const [enableReorder, setEnableReorder] = useState(false);
    const [
        // selectedLanguage,
        ,
        setSelectedLanguage,
    ] = useState('english');
    const [availableLanguages, setAvailableLanguages] = useState(null);
    const [formValues, setFormValues] = useState({
        title: '',
        description: '',
        type: 'folder',
        // freeToWatch,
        // fileId: '',
        // description,
    });
    const { Option } = Select;
    const [videoList, setVideoList] = useState([]);
    const onVideoFinishFailed = (errorInfo) => Modal.error({ content: errorInfo });
    const [availableVideoForUpload, setavailableVideoForUpload] = useState(false);

    const context = useContext(languageContext);

    const resetForm = () => {
        setFormValues({
            title: '',
            description: '',
            type: 'folder',
        });
        setselectedRow(null);
        setSelectedVideo({});
        setavailableVideoForUpload(false);
    };

    const showAddVideoModal = () => setvideoVisible(true);
    const videoHandleCancel = () => {
        setSelectedVideo({ freeToWatch: false });
        setvideoVisible(false);
        resetForm();
    };

    const showAddFolderModal = () => setFolderModalVisible(true);
    const folderHandleCancel = () => {
        setFolderModalVisible(false);
        resetForm();
    };

    async function fetchLanguages() {
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/languages`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setAvailableLanguages(response.data.languages);
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    }

    useEffect(() => {
        setFolderId(history.location.pathname.split('/').at(-1) || '');
    }, [history]);

    const getFolderInfo = () => {
        if (folderId && folderId !== 'course') {
            const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/document/${folderId}`;
            const config = { headers: { Authorization: `Bearer ${AUTH_TOKEN}` } };

            axios.get(url, config).then((response) => {
                if (response.status === 200 && !response.data.error) {
                    setDescendants(response.data.descendants);
                    if (response.data.descendants?.length) {
                        setAncestors(response.data.descendants[0]?.ancestors || []);
                    } else if (response.data.parentInfo?.ancestors) {
                        setAncestors([...response.data.parentInfo?.ancestors,
                            { ...response.data.parentInfo }] || []);
                            // setCourseInfo(response.data.courseDoc || {});
                        }
                        setCourseInfo(response.data.courseDoc || {});
                    // setDisallowModuleAddition(response.data.disallowModuleAddition || false);
                }
            }).catch((err) => {
                console.error(err);
                Modal.error({ content: err.errorMessage });
            });
        } else {
            setDescendants([]);
        }
    };
    // useEffect(getFolderInfo, [folderId]);

    const folderUpdateSuccess = (response) => {
        if (response.status === 200 && response.data.error === false) {
            setFolderModalVisible(false);
            setFolderConfirmLoading(false);
            Modal.success({ content: `${response.data.message}` });
            getFolderInfo();
            resetForm();
        } else Modal.error({ content: 'Module Not Added, Please Try Again' });
    };

    const onFolderFinish = () => {
        setFolderConfirmLoading(true);
        const data = formValues;
        data.courseId = courseInfo?._id || folderId;
        data.parent = courseInfo?._id === folderId ? '' : folderId;
        const config = { headers: { Authorization: `Bearer ${AUTH_TOKEN}` } };
        const { _id } = formValues;
        if (_id) {
            const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/document/${_id}`;
            axios.put(url, data, config)
                .then((response) => folderUpdateSuccess(response))
                .catch((err) => Modal.error({ content: err.response.data.errorMessage }));
        } else {
            const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/document`;
            axios.post(url, data, config)
                .then((response) => folderUpdateSuccess(response))
                .catch((err) => Modal.error({ content: err.response.data.errorMessage }));
        }
    };

    async function fetchList() {
        let url = `${process.env.REACT_APP_LIBRARY_URL}/media/list`;
        if (courseInfo && courseInfo.language) {
            url += `?language=${courseInfo.language}`;
        }
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setVideoList(response.data.files);
            }
        });
    }

    useEffect(() => {
        getFolderInfo();
        fetchLanguages();
        setSelectedLanguage(context.state.selectedLanguage);
    }, [context, folderId]);
    useEffect(() => {
        // fetchQuestions();
        // fetchVideos();
        fetchLanguages();
        fetchList();
    }, [courseInfo]);
    const onFileFinish = () => {
        setFolderConfirmLoading(true);
        const data = formValues;
        data.courseId = courseInfo?._id || folderId;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        const { _id } = formValues;
        if (_id) {
            const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/document/${_id}`;
            axios.put(url, data, config).then((response) => {
                if (response.status === 200 && response.data.error === false) {
                    setFileModalVisible(false);
                    setFileConfirmLoading(false);
                    Modal.success({ content: `${response.data.message}` });
                    getFolderInfo();
                } else Modal.error({ content: 'Module Not Added, Please Try Again' });
            }).catch((err) => Modal.error({ content: err.response.data.errorMessage }));
        } else {
            const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/descendant`;
            axios.post(url, data, config).then((response) => {
                if (response.status === 200 && response.data.error === false) {
                    setFileModalVisible(false);
                    setFileConfirmLoading(false);
                    Modal.success({ content: `${response.data.message}` });
                    getFolderInfo();
                } else Modal.error({ content: 'Module Not Added, Please Try Again' });
            }).catch((err) => Modal.error({ content: err.response.data.errorMessage }));
        }
    };

    const videoUpdateSuccess = (response) => {
        if (response.status === 200 && response.data.error === false) {
            setvideoVisible(false);
            setVideoConfirmLoading(false);
            Modal.success({ content: `${response.data.message}` });
            setSelectedVideo({ freeToWatch: false });
            getFolderInfo();
            resetForm();
        } else {
            Modal.error({ content: response.data.errorMessage });
            setavailableVideoForUpload(false);
            setSelectedVideo({ freeToWatch: false });
        }
    };

    const videoUpdateError = (err) => {
        Modal.error({ content: err.response.data.errorMessage });
        setavailableVideoForUpload(false);
        setSelectedVideo({ freeToWatch: false });
    };

    const onVideoFinish = () => {
        if (selectedVideo.title && selectedVideo._id) {
            const data = {
                title: selectedVideo.title,
                type: 'file',
                courseId: courseInfo._id || folderId,
                parent: (!courseInfo._id || courseInfo._id === folderId) ? '' : folderId,
                freeToWatch: selectedVideo.freeToWatch || false,
                fileId: selectedVideo._id,
                description: '',
            };
            const config = {
                headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                        const uploadPercentage = (progressEvent.loaded / progressEvent.total) * 100;
                        setavailableVideoForUpload(uploadPercentage);
                    }
                },
            };
            const _id = selectedRow?._id;
            if (_id) {
                const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/document/${_id}`;
                axios.put(url, data, config)
                    .then((response) => videoUpdateSuccess(response))
                    .catch((err) => videoUpdateError(err));
            } else {
                const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/document`;
                axios.post(url, data, config)
                    .then((response) => videoUpdateSuccess(response))
                    .catch((err) => videoUpdateError(err));
            }
        } else {
            notification.error({
                message: 'Error!',
                description: 'Please fill in the mandatory fields!',
                placement: 'bottom',
            });
        }
    };

    const deleteDocument = () => {
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/document/${selectedRow._id}`;
        const config = { headers: { Authorization: `Bearer ${AUTH_TOKEN}` } };
        axios.delete(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setShowDeleteModal(false);
                Modal.success({ content: 'Document deleted successfully' });
                fetchList();
                getFolderInfo();
            } else {
                Modal.error({ content: 'Unable to delete, Please Try Again' });
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
            setShowDeleteModal(false);
            getFolderInfo();
        });
    };

    const columns = [
        {
            title: 'Folder/File Name',
            dataIndex: ['title'],
            key: 'title',
            render: (descendant, currentDescendant) => <p style={currentDescendant?.type === 'file' ? {} : { cursor: 'pointer', color: 'blue' }}
                onClick={
                    currentDescendant?.type === 'file'
                        ? () => { }
                        : () => {
                            history.push({
                                pathname: `/directory/${currentDescendant._id}`,
                                state: {
                                    ...currentDescendant,
                                },
                            });
                            setFolderId(currentDescendant._id || '');
                        }
                }>
                <span style={{ marginRight: '10px' }}> {currentDescendant?.type === 'file' ? <PlayCircleFilled
                    style={{ color: 'tomato', fontSize: 20 }}
                    onClick={() => showplayVideoModal(currentDescendant)}
                /> : null}</span>
                {descendant}
                <span style={{ marginLeft: '10px', float: 'right' }}>
<span title={currentDescendant.description || currentDescendant?.fileId?.description || 'No description to show!'}>&#9432;</span>
                </span>
            </p>,
        },
        // {
        //     title: 'Description',
        //     dataIndex: ['description'],
        //     key: 'description',
        // },
        {
            title: 'Type',
            dataIndex: ['type'],
            key: 'type',
        },
        {
            title: 'Created At',
            dataIndex: ['createdAt'],
            render: (created) => <span title={moment(created).format('MMMM Do YYYY, h:mm:ss a')}>
                {created ? moment(created).format('MMM Do YY') : ''}
            </span>,
        },
        {
            title: 'Last Updated',
            dataIndex: ['lastUpdatedAt'],
            render: (updated) => <span title={moment(updated).format('MMMM Do YYYY, h:mm:ss a')}>
                {updated ? moment(updated).format('MMM Do YY') : ''}
            </span>,
        },
        {
            title: 'Edit',
            dataIndex: ['edit'],
            align: 'center',
            render: (_, row) => <EditTwoTone
                twoToneColor='tomato'
                style={{ fontSize: 22 }}
                onClick={() => {
                    if (row.type === 'file') {
                        setSelectedVideo(row);
                        showAddVideoModal(true);
                    } else {
                        setFormValues(row);
                        showAddFolderModal(true);
                    }
                    setselectedRow(row);
                }}
            />,
        },
        {
            title: 'Delete',
            dataIndex: ['delete'],
            align: 'center',
            render: (_, row) => <DeleteTwoTone
                twoToneColor='tomato'
                style={{ fontSize: 22 }}
                className='iconButton'
                onClick={() => {
                    setFormValues(row);
                    setselectedRow(row);
                    setShowDeleteModal(true);
                }}
            />,
        },
        // {
        //     title: 'Topics',
        //     dataIndex: ['topicCount'],
        //     key: 'topics',
        //
        // render: (topics, currentDescendant) => <p style={{ cursor: 'pointer', color: 'blue' }}
        //         onClick={
        //             () => {
        //                 history.push({
        //                     pathname: `/descendants/${currentDescendant._id}`,
        //                     state: {
        //                         moduleId: currentDescendant._id,
        //                         topics: currentDescendant.topics,
        //                         topicId: null,
        //                         conceptId: null,
        //                         lang: selectedLanguage,
        //                         courseId,
        //                     },
        //                 });
        //                 setFolderId(currentDescendant._id || '');
        //             }
        //         }>
        //         {topics}
        //     </p>,
        // },
    ];

    const components = {
        body: {
            row: (enableReorder) ? DraggableBodyRow : null,
        },
    };

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = descendants[dragIndex];
            setDescendants(
                update(descendants, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );
        },
        [descendants],
    );

    const reorderdescendants = () => {
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/document/reorder-sequence`;
        const config = { headers: { Authorization: `Bearer ${AUTH_TOKEN}` } };
        const data = { descendants };
        axios.put(url, data, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                // setDescendants(response.data.descendants);
                getFolderInfo();
                setEnableReorder(false);
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    };

    function search(value) {
        const filterTable = descendants.filter((o) => Object.keys(o).some((k) => String(o[k])
            .toLowerCase().includes(value.toLowerCase())));
        setFilter({ filterTable });
    }

    // const onEditVideoFinish = () => {
    //     const data = {
    //         title: selectedVideo.title,
    //         videoId: selectedVideo._id,
    //         moduleId: location.state.moduleId,
    //         topicId: location.state.topicId,
    //     };
    //     axios.put(url, data, config).then((response) => {
    //         if (response.status === 200 && response.data.error === false) {
    //             seteditVideoVisible(false);
    //             seteditVideoConfirmLoading(false);
    //             Modal.success({ content: `${response.data.message}` });
    //             fetchVideos();
    //         } else {
    //             Modal.error({ content: 'Video Not Edited, Please Try Again' });
    //         }
    //     }).catch((err) => {
    //         Modal.error({ content: err.response.data.errorMessage });
    //     });
    // };
    return (
        <div>
            <Header className='directoryHeader'>
                {/* {'} */}
            </Header>
            <Content className='directoryContent'>
                <div className='directoryScreenContainer'>
                    <div className='directoryScreenTop'>
                        <div className='directoryScreenTopBreadcrumbContainer'>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => { window.location = '/course'; }}
                                    style={{ cursor: 'pointer' }}>
                                    {/* <LeftOutlined
                                    style={{ paddingRight: 10 }}
                                    /> */}
                                    Courses</Breadcrumb.Item>
                                <Breadcrumb.Item
                                    onClick={() => {
                                        history.push({
                                            pathname: `/directory/${courseInfo?._id}`,
                                            state: {
                                                // ...currentDescendant,
                                            },
                                        });
                                        setFolderId(courseInfo?._id || '');
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >{courseInfo?.name}</Breadcrumb.Item>
                                {
                                    ancestors.map(({ _id, title }) => <Breadcrumb.Item key={_id}
                                        onClick={() => {
                                            history.push({
                                                pathname: `/directory/${_id}`,
                                                state: {
                                                    // ...currentDescendant,
                                                },
                                            });
                                            setFolderId(_id || '');
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >{title}</Breadcrumb.Item>)
                                }
                            </Breadcrumb>
                        </div>
                        <div>
                            <Button
                                onClick={showAddFolderModal}
                                shape="round"
                                // disabled={disallowModuleAddition}
                                style={{ background: 'tomato', color: 'white' }
                                }
                            >
                                Add Folder
                            </Button>
                            <Button
                                onClick={showAddVideoModal}
                                shape="round"
                                // disabled={disallowModuleAddition}
                                style={{ background: 'tomato', color: 'white' }
                                }
                            >
                                Add File
                            </Button>
                        </div>
                    </div>
                    <div className='directorySearchContainer' style={{ width: '100%' }}>
                        {availableLanguages && <Select
                            defaultValue={'select language'}
                            value={context.state.selectedLanguage}
                            style={{ width: 120, marginRight: 20 }}
                            onChange={(value) => {
                                setSelectedLanguage(value);
                                context.saveSelectedLanguage(value);
                            }}
                        >
                            {availableLanguages.map((language, index) => <Option
                                style={{ textTransform: 'capitalize' }}
                                key={index}
                                value={language.label.toLocaleLowerCase()}
                            >{language.label}
                            </Option>)}
                        </Select>}
                        <Input.Search
                            style={{
                                width: 300,
                            }}
                            placeholder="Search any field..."
                            enterButton
                            onChange={(e) => search(e.target.value)}
                        />
                    </div>
                    <div style={{ width: '100%', padding: 10 }}>
                        <Row>
                            <DndProvider backend={HTML5Backend}>
                                <Table
                                    style={{ width: '100%', paddingRight: 20 }}
                                    pagination={{
                                        position: ['bottomLeft'],
                                        defaultPageSize: 20,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['20', '50', '100'],
                                    }}
                                    columns={columns}
                                    components={components}
                                    onRow={(record, index) => ({
                                        index,
                                        moveRow,
                                    })}
                                    dataSource={filter.filterTable === null
                                        ? descendants : filter.filterTable}
                                />
                            </DndProvider>
                        </Row>
                        <Row style={{ justifyContent: 'flex-end', paddingRight: 20 }}>
                            <Button
                                onClick={() => setEnableReorder(!enableReorder)}
                                shape="round"
                                style={{
                                    background: 'tomato',
                                    color: 'white',
                                }}
                            >
                                {!enableReorder ? 'Enable reorder' : 'Disable reorder'}
                            </Button>
                            <Button
                                onClick={() => reorderdescendants()}
                                shape="round"
                                style={{
                                    background: 'tomato',
                                    color: 'white',
                                    marginLeft: 20,
                                }}
                            >
                                Save changes
                            </Button>
                        </Row>
                    </div>
                    <Modal
                        title={formValues._id ? 'Update Folder' : 'Add Folder'}
                        visible={folderModalVisible}
                        confirmLoading={folderConfirmLoading}
                        onCancel={folderHandleCancel}
                        footer={[]}
                        getContainer={false}
                    >
                        <p className='label'>Folder Name <span style={{ color: 'red' }}>*</span></p>
                        <input
                            className='input'
                            type='text'
                            name='name'
                            value={formValues?.title}
                            onChange={(e) => setFormValues({
                                ...formValues, title: e.target.value,
                            })}
                        />
                        <p className='label'>Module Description </p>
                        <textarea
                            className='input txtArea'
                            name='description'
                            value={formValues?.description}
                            onChange={(e) => setFormValues(
                                { ...formValues, description: e.target.value },
                            )}
                        />
                        <Row justify="end">
                            <Button
                                onClick={() => onFolderFinish()}
                                htmlType="submit"
                                shape="round"
                                style={{
                                    pointerEvents: (formValues.language === '' || formValues.name === '') ? 'none' : 'auto',
                                    background: (formValues.language === '' || formValues.name === '') ? 'lightgrey' : 'tomato',
                                    color: 'white',
                                }}
                            >
                                Submit
                            </Button>
                        </Row>
                    </Modal>
                    <Modal
                        title="Add File"
                        visible={fileModalVisible}
                        confirmLoading={fileConfirmLoading}
                        onCancel={fileHandleCancel}
                        footer={[]}
                        getContainer={false}
                    >
                        <p className='label'>Module Name <span style={{ color: 'red' }}>*</span></p>
                        <input
                            className='input'
                            type='text'
                            name='name'
                            value={formValues?.title}
                            onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                        />
                        <p className='label'>Module Description </p>
                        <textarea
                            className='input txtArea'
                            name='description'
                            value={formValues?.description}
                            onChange={(e) => setFormValues(
                                { ...formValues, description: e.target.value },
                            )}
                        />
                        <Row justify="end">
                            <Button
                                onClick={() => onFileFinish()}
                                htmlType="submit"
                                shape="round"
                                style={{
                                    pointerEvents: (formValues.language === '' || formValues.name === '') ? 'none' : 'auto',
                                    background: (formValues.language === '' || formValues.name === '') ? 'lightgrey' : 'tomato',
                                    color: 'white',
                                }}
                            >
                                Submit
                            </Button>
                        </Row>
                    </Modal>

                    {/* <Modal
                        title="Edit Video"
                        visible={editVideoVisible}
                        confirmLoading={editVideoConfirmLoading}
                        onCancel={editVideoHandleCancel}
                        footer={[]}
                        getContainer={false}
                    >
                        <Form
                            name="basic"
                            // initialValues={selectedRow}
                            onFinish={onEditVideoFinish}
                            // onFinishFailed={onEditVideoFinishFailed}
                            layout={'vertical'}
                        >
                            <Label >Select Video <span style={{ color: 'red' }}>*</span></Label>
                            <Select
                                placeholder="Select a option and change input text above"
                                allowClear
                                searchValue='title'
                                value={selectedVideo._id}
                                onChange={(_, i) => i && setSelectedVideo(videoList[i.key])}
                                style={{ width: '100%' }}
                            >
                                {
                                    videoList.map((item, i) => <Option
                                        style={{ textTransform: 'capitalize' }}
                                        value={item._id}
                                        key={i}>{item.title}</Option>)
                                }
                            </Select>
                            <Label
                            style={{ marginTop: 30 }}>
                            Video title <span style={{ color: 'red' }}>*</span></Label>
                            <Input
                                value={selectedVideo.title}
                                onChange={(e) => setSelectedVideo(
                                    { ...selectedVideo, title: e.target.value },
                                )}
                            />

                            <Form.Item>
                                <Row justify="end">
                                    <Button
                                        htmlType="submit"
                                        shape="round"
                                        style={{
                                            background: 'tomato',
                                            color: 'white',
                                            marginTop: 20,
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Modal> */}

                    {/* Upload video modal */}
                    <Modal
                        title={selectedRow?._id ? 'Update Video' : 'Upload Video'}
                        visible={videoVisible}
                        confirmLoading={videoConfirmLoading}
                        onCancel={videoHandleCancel}
                        footer={null}
                        getContainer={false}
                    >
                        <Form
                            name="basic"
                            initialValues={{
                                ['title']: selectedVideo.title,
                            }}
                            onFinish={onVideoFinish}
                            onFinishFailed={onVideoFinishFailed}
                            layout={'vertical'}
                        >
                            <Label >Select Video <span style={{ color: 'red' }}>*</span></Label>
                            <Select
                                placeholder="Select a option and change input text above"
                                allowClear
                                searchValue='title'
                                value={selectedVideo?.fileId?._id}
                                onChange={(_, i) => i && setSelectedVideo({
                                    ...selectedVideo,
                                    ...videoList[i.key],
                                })}
                                style={{ width: '100%' }}
                            >
                                {
                                    videoList.map((item, i) => <Option
                                        style={{ textTransform: 'capitalize' }}
                                        value={item._id}
                                        key={i}>{item.title}</Option>)
                                }
                            </Select>
                            <Label className='label'>Video title <span style={{ color: 'red' }}>*</span></Label>
                            <Input
                                value={selectedVideo?.title}
                                onChange={(e) => setSelectedVideo(
                                    { ...selectedVideo, title: e.target.value },
                                )}
                            />
                            <Label className='label'>Video free to watch? <span style={{ color: 'red' }}>*</span></Label>
                            <Switch style={{ marginLeft: '15px' }}
                                checked={selectedVideo.freeToWatch} defaultChecked={false}
                                onChange={(e) => {
                                    setSelectedVideo({ ...selectedVideo, freeToWatch: e });
                                }}
                            />
                            <Form.Item>
                                {
                                    availableVideoForUpload && <Progress
                                        strokeColor={{
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                        }}
                                        percent={availableVideoForUpload}
                                        format={() => ''}
                                    />
                                }
                            </Form.Item>

                            <Form.Item>
                                <Row justify="end">
                                    <Button
                                        htmlType="submit"
                                        shape="round"
                                        style={{ background: 'tomato', color: 'white' }}
                                    >
                                        Submit
                                    </Button>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal
                        title={'Delete Document 😲'}
                        centered
                        visible={showDeleteModal}
                        onOk={deleteDocument}
                        onCancel={() => {
                            setShowDeleteModal(false);
                            setselectedRow(null);
                        }}
                    >
                        <p>Are you sure you want to delete <strong>
                            {selectedRow?.title}
                        </strong>?</p>
                    </Modal>
                </div>
            </Content>
            <Modal
                title="File Preview"
                visible={playVideo}
                onCancel={playVideoHandleCancel}
                footer={[]}
                width={1000}
                getContainer={false}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <ReactPlayer
                        url={videoUrl || ''}
                        controls={true}
                    />
                </div>
            </Modal>
        </div>
    );
}
