/* eslint-disable comma-dangle */
/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React, {
    useEffect,
    useState,
    // useEffect,
} from 'react';
import './LibraryTable.css';
import {
    Table,
    Input,
    Form,
    Typography,
    Button,
    Modal,
    Select,
    Row,
    Upload,
    Switch,
    Col,
} from 'antd';
import FormData from 'form-data';
import {
    EditFilled,
    UploadOutlined,
    // PlayCircleFilled,
    DeleteFilled,
    PlusCircleFilled,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactPlayer from 'react-player';
import Swal from 'sweetalert2';
import moment from 'moment';
import availableLanguages from '../AvailableLanguages/AvailableLanguages';
import '../../Screens/LibraryScreen/LibraryScreen.css';

const AUTH_TOKEN = localStorage.getItem('token');

function LibraryTable({
    fileLibrary, fetchLibrary, businessIdeas, fetchBusinessIdeas, keywords, categories
    // filterValues, setFilterValues, INITIAL_FILTER_VALUES,
}) {
    const { Option } = Select;
    const [selectedFile, setselectedFile] = useState(false);
    const [fileVisible, setfileVisible] = useState(false);
    const [fileConfirmLoading, setfileConfirmLoading] = useState(false);
    const [formValVisibility, setFormValVisibility] = useState(false);
    const [confirmDeletion, setConfirmDeletion] = useState(false);
    const [fileForDeletion, setFileForDeletion] = useState(null);
    const [addQuestions, setaddQuestions] = useState(false);
    const [displayQuestions, setDisplayQuestions] = useState(false);
    const [avalableQuestions, setavalableQuestions] = useState([]);
    const [playVideo, setplayVideo] = useState(false);
    const [selectedRow, setselectedRow] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const playVideoHandleCancel = () => setplayVideo(false);
    const fileHandleCancel = () => setfileVisible(false);
    const showFileModal = (row) => {
        setfileVisible(true);
        setselectedFile(row);
        setFormValVisibility(row.visibility === 'Public');
    };
    const showplayVideoModal = (row) => {
        setplayVideo(true);
        setselectedRow(row);
    };
    const { TextArea } = Input;
    // const onFileFinishFailed = (errorInfo) => Modal.error({ content: errorInfo });

    async function fetchQuestions() {
        const url = `${process.env.REACT_APP_QUESTION_URL}/questions`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setavalableQuestions(response.data.questions);
            }
        }).catch((err) => console.log(err));
    }

    useEffect(() => {
        fetchQuestions();
    }, []);

    const onFileFinish = (values) => {
        setfileConfirmLoading(true);
        const { _id } = selectedFile;
        const url = `${process.env.REACT_APP_LIBRARY_URL}/media/${_id}`;
        const config = { headers: { Authorization: AUTH_TOKEN, 'x-access-token': localStorage.getItem('token') } };

        const fileForm = new FormData();
        fileForm.append('title', values.title);
        fileForm.append('description', values.description);
        fileForm.append('language', values.language);
        fileForm.append('businessIdeas', values.businessIdeas);
        fileForm.append('category', values.category);
        if (formValVisibility) {
            fileForm.append('status', values.status);
            fileForm.append('level', values.level);
        } else {
            fileForm.append('status', '');
            fileForm.append('level', 0);
        }
        fileForm.append('type', values.type);
        if (values.keywords) {
            fileForm.append('keywords', values.keywords);
        }
        if (values.source) {
            fileForm.append('source', values.source);
        }
        fileForm.append('visibility', values.visibility);

        if (values.fileUrl) {
            fileForm.append('fileType', 'link');
            fileForm.append('fileUrl', values.fileUrl);
        } else {
            if (values.uploadedFile.file.type.includes('audio')) fileForm.append('fileType', 'audio');
            else if (values.uploadedFile.file.type.includes('video')) fileForm.append('fileType', 'video');
            else fileForm.append('fileType', values.uploadedFile.file.type);
            fileForm.append('fileUrl', values.uploadedFile.file);
        }
        if (values.thumbnail && values.thumbnail.file) {
            if (values.thumbnail.file.type.includes('audio')) fileForm.append('thumbnail', 'audio');
            else if (values.thumbnail.file.type.includes('video')) fileForm.append('thumbnail', 'video');
            else fileForm.append('thumbnail', values.thumbnail.file.type);
            fileForm.append('thumbnail', values.thumbnail.file);
        } else fileForm.append('thumbnail', selectedFile.thumbnail || '');
        axios.put(url, fileForm, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setfileVisible(false);
                setfileConfirmLoading(false);
                fetchLibrary();
                fetchBusinessIdeas();
                Modal.success({ content: `${response.data.message}` });
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    };
    const props = {
        accept: 'video/*',
        beforeUpload: () => false,
        listType: 'picture',
    };
    const thumbnailProps = {
        accept: 'image/*',
        beforeUpload: () => false,
        listType: 'picture',
    };

    const deleteFile = (row) => {
        const { _id } = row;
        const url = `${process.env.REACT_APP_LIBRARY_URL}/media/${_id}`;
        const config = { headers: { Authorization: AUTH_TOKEN, 'x-access-token': localStorage.getItem('token') } };

        axios.delete(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                fetchLibrary();
                setConfirmDeletion(false);
                Modal.success({ content: `${response.data.message}` });
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    };

    const onAddQuestionsFinish = () => {
        const url = `${process.env.REACT_APP_LIBRARY_URL}/media/questions/${selectedRow._id}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        const data = {
            questionBank: selectedQuestions,
        };
        axios.post(url, data, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                Modal.success({ content: `${response.data.message}` });
            }
        }).catch((err) => Modal.error({ content: err }));
    };

    const columns = [
        {
            title: 'Video Id',
            dataIndex: 'fileId',
            align: 'center',
            // render: (_, record) => (
            //     <PlayCircleFilled
            //         style={{ color: 'tomato', fontSize: 20 }}
            //         onClick={() => showplayVideoModal(record)}
            //     />
            // ),
        },
        {
            title: 'File Name',
            dataIndex: 'title',
            align: 'center',
            sorter: (a, b) => a.title.localeCompare(b.title),
            render: (title, record) => <p style={{ width: 300 }}>
                <a onClick={() => showplayVideoModal(record)} style={{ color: 'tomato' }}>
                    {title}
                </a>
            </p>,
        },
        {
            title: 'Language',
            dataIndex: 'language',
            align: 'center',
            render: (cell) => <span style={{ textTransform: 'capitalize' }}>{cell}</span>,
            sorter: (a, b) => a.language.localeCompare(b.language),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            render: (cell) => <span style={{ textTransform: 'capitalize' }}>{cell}</span>,
            sorter: (a, b) => a.status.localeCompare(b.status),
        },
        {
            title: ' Visibility',
            dataIndex: 'visibility',
            align: 'center',
            render: (cell) => <span style={{ textTransform: 'capitalize' }}>{cell}</span>,
            sorter: (a, b) => a.visibility.localeCompare(b.visibility),
        },
        {
            title: ' Level',
            dataIndex: 'level',
            align: 'center',
            render: (cell) => <span style={{ textTransform: 'capitalize' }}>{cell === 0 ? '' : cell}</span>,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            align: 'center',
            render: (cell) => <span style={{ textTransform: 'capitalize' }}>{!cell || cell === 'undefined' ? '' : cell}</span>,
            sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
            title: 'Questions',
            dataIndex: ['questionBank'],
            align: 'center',
            render: (questionBank, row) => <a
                onClick={() => {
                    if (questionBank?.length) {
                        setDisplayQuestions(true);
                        setselectedRow(row);
                    }
                }}
            >
                {questionBank ? questionBank.length : 0}
            </a>,
        },
        {
            title: 'Add Questions',
            dataIndex: 'addQuestions',
            align: 'center',
            render: (_, row) => (
                <Typography.Link>
                    <PlusCircleFilled
                        style={{ color: 'tomato', fontSize: 20 }}
                        onClick={() => {
                            setaddQuestions(true);
                            setselectedRow(row);
                        }}
                    />
                </Typography.Link>
            )
        },
        {
            title: 'Add Transcripts',
            dataIndex: 'addTranscripts',
            align: 'center',
            render: (_, row) => (
                <Typography.Link>
                    <Typography.Link>
                        <Link
                            to={{
                                pathname: '/transcripts',
                                state: { videoId: row._id },
                            }}
                        >
                            <p className='transcriptIcon m-0' >Abc</p>
                        </Link>
                    </Typography.Link>
                </Typography.Link>
            )
        },
        {
            title: 'Update Moments',
            dataIndex: 'moments',
            align: 'center',
            render: (_, row) => (
                <Typography.Link>
                    <Link
                        to={{
                            pathname: '/videomoments',
                            state: {
                                file: row,
                            },
                        }}
                    >
                        <EditFilled style={{ color: 'tomato', fontSize: 20 }} />
                    </Link>
                </Typography.Link>
            ),
        },
        {
            title: 'Created At',
            dataIndex: ['metaInfo', 'created', 'at'],
            align: 'center',
            render: (createdAt) => <p style={{ width: 80 }}>
                <span title={moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}>
                    {moment(createdAt).format('MMM Do YY')}
                </span>
            </p>
        },
        {
            title: 'Last Updated',
            dataIndex: ['metaInfo', 'updated', 'at'],
            align: 'center',
            render: (updatedAt) => <p style={{ width: 80 }}>
                <span title={moment(updatedAt).format('MMMM Do YYYY, h:mm:ss a')}>
                    {moment(updatedAt).format('MMM Do YY')}
                </span>
            </p>
        },
        {
            title: 'Edit File',
            dataIndex: 'editFile',
            align: 'center',
            render: (_, row) => (
                <EditFilled
                    style={{ color: 'tomato', fontSize: 20 }}
                    onClick={() => showFileModal(row)}
                />
            ),
        },
        {
            title: 'Delete File',
            dataIndex: 'delete',
            align: 'center',
            render: (_, row) => (
                <DeleteFilled
                    style={{ color: 'tomato', fontSize: 20 }}
                    onClick={() => { setFileForDeletion(row); setConfirmDeletion(true); }}
                />
            ),
        },
    ];
    // SEARCH FILTER
    const [filter, setFilter] = useState({ filterTable: null });
    function search(value) {
        const filterTable = fileLibrary.filter((o) => Object.keys(o).some((k) => String(o[k])
            .toLowerCase().includes(value.toLowerCase())));
        setFilter({ filterTable });
    }

    const [isUploadVideo, setisUploadVideo] = useState(false);
    function onUploadChecked(checked) {
        setisUploadVideo(checked);
    }

    return (
        <div className='libraryTableContainer'>
            <div className='tableSearchContainer'>
                <Input.Search
                    style={{
                        width: 300,
                    }}
                    placeholder="Search any field..."
                    enterButton
                    onChange={(e) => search(e.target.value)}
                />
            </div>

            <Form component={false}>
                <div className='library-content'>      <Table
                    bordered
                    showSorterTooltip
                    showHeader={true}
                    className='fileLibraryTable'
                    dataSource={filter.filterTable === null ? fileLibrary : filter.filterTable}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={{
                        position: ['bottomLeft'],
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        pageSizeOptions: ['5', '10', '20', '50', '100'],
                    }}
                    rowKey='id' // fileId maybe
                /></div>
            </Form>
            <Modal
                visible={confirmDeletion}
                onCancel={() => setConfirmDeletion(false)}
                onOk={() => deleteFile(fileForDeletion)}
                width={'40%'}
                style={{ marginTop: 150 }}
                getContainer={false}
            >
                <p> Sure want to delete
                    <strong>{' '}{fileForDeletion && fileForDeletion.title}</strong> ?
                </p>
            </Modal>
            <Modal
                title="Edit File"
                visible={fileVisible}
                confirmLoading={fileConfirmLoading}
                onCancel={fileHandleCancel}
                footer={[]}
                getContainer={false}
                width={1000}
            >
                <Form
                    name="basic"
                    initialValues={selectedFile}
                    onFinish={onFileFinish}
                    // onFinishFailed={onFileFinishFailed}
                    layout={'vertical'}
                >
                    <Form.Item name="title" label="Video Title (Max 100 Characteristics)"
                        rules={[
                            {
                                required: true,
                                message: 'Enter a new name!',
                            },
                        ]}
                    >
                        <Input maxLength={100}/>
                    </Form.Item>
                    <Form.Item name="description" label="File Description (Max 1000 Characteristics)"
                        rules={[
                            {
                                required: true,
                                message: 'Enter a new description!',
                            },
                        ]}
                    >
                        <TextArea rows={4} maxLength={1000}/>
                    </Form.Item>
                    <Row>
                        <Col md={formValVisibility ? 6 : 12} lg={formValVisibility ? 6 : 12}>
                            <Form.Item name="language" label="Language"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    placeholder="Select a option and change input text above"
                                    allowClear
                                >
                                    {
                                        availableLanguages.map((language, index) => <Option
                                            value={language.value}
                                            key={index}>{language.text}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={formValVisibility ? 6 : 12} lg={formValVisibility ? 6 : 12}
                            style={{ paddingLeft: 10 }}
                        >
                            <Form.Item name="visibility" label="Visibility"
                                rules={[{ required: true, message: 'Please select video visibility' }]}
                            >
                                <Select
                                    placeholder="Select video visibility"
                                    allowClear
                                    onChange={(val) => setFormValVisibility(val === 'Public')}
                                >
                                    {['Public', 'Restricted'].map((visibility) => (
                                        <Option key={visibility} label={visibility}
                                            title={visibility}
                                            value={visibility}>{visibility}</Option>
                                    ))}
                                </Select>
                            </Form.Item></Col>
                        {formValVisibility && <><Col md={6} lg={6} style={{ paddingLeft: 10 }}>
                            <Form.Item name="level" label="Level"
                                rules={[{ required: formValVisibility, message: 'Please select a level' }]}
                            >
                                <Select
                                    placeholder="Select business level"
                                    allowClear
                                >
                                    {[1, 2, 3, 4, 5, 6, 7].map((level) => (
                                        <Option key={level} label={level}
                                            title={level}
                                            value={level}>{`Level - ${level}`}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                            <Col md={6} lg={6} style={{ paddingLeft: 10 }}>
                                <Form.Item name="type" label="Type"
                                    rules={[{ required: formValVisibility, message: 'Please select video type' }]}
                                >
                                    <Select
                                        placeholder="Select video type"
                                        allowClear
                                    >
                                        {['Demo/Walkthrough',
                                            // 'Interview',
                                            // 'Informational',
                                            'Knowledge sharing', 'Informative/educational', 'Interview'].map((type) => (
                                                <Option key={type} label={type}
                                                    title={type}
                                                    value={type}>{type}</Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col></>}
                    </Row>
                    {formValVisibility && <>
                        <Row>
                            <Col md={12} lg={12}>
                                <Form.Item name="status" label="Status"
                                    rules={[{ required: formValVisibility }]}
                                >
                                    <Select
                                        placeholder="Select a option and change input text above"
                                        allowClear
                                    >
                                        <Option value='live'>
                                            Live
                                        </Option>
                                        <Option value='draft'>
                                            Draft
                                        </Option>
                                        <Option value='hidden'>
                                            Hidden
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={12} lg={12} style={{ paddingLeft: 10 }}>
                                <Form.Item name="category" label="Category" >
                                    <Select
                                        mode='multiple'
                                        placeholder="Select a category"
                                        allowClear
                                    >
                                        {categories.map((cat) => <Option
                                            key={cat._id}
                                            value={cat._id}
                                        >
                                            {cat.category.english}
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item name="businessIdeas" label="Business Ideas"
                            rules={[{ required: formValVisibility, message: 'Please select at least one business idea that goes with this video.' }]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Select one or more business ideas that apply"
                                allowClear
                                showSearch
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                onSearch={(searchText) => {
                                    console.log('🚀 ~ file: LibraryTable.js:564 ~ searchText:', searchText);
                                }}
                            >
                                {businessIdeas.map((idea) => (
                                    <Option key={idea.combine} label={idea._inEnglish}
                                        title={idea._inEnglish}
                                        value={idea._id}>{idea.inEnglish}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="keywords" label="Groups"
                            rules={[{ required: true, message: 'Add some Groups' }]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Select one or more Groups"
                                allowClear
                            >
                                {keywords.map((word) => <Option key={word._id} value={word._id}>
                                    {word.keyword}
                                </Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="source" label="Source"
                        // rules={[{ required: true, message: 'Add some keywords' }]}
                        >
                            <Input />
                        </Form.Item>
                    </>}
                    {selectedFile.thumbnail || '' ? <Form.Item label="Thumbnail">
                        <Row> <Col > <img width={300} height={200} src={selectedFile.thumbnail || ''} /></Col> <Button onClick={() => {
                            Swal.fire({
                                title: 'Are you sure you want to remove thumnail for this video?',
                                focusConfirm: false,
                                showCancelButton: true,
                                cancelButtonText: 'No, Cancel',
                                confirmButtonText: 'Yes, Delete',
                                cancelButtonAriaLabel: 'No, Cancel',
                                confirmButtonAriaLabel: 'Yes, Delete',
                            }).then((result) => {
                                if (result.value) {
                                    setselectedFile({ ...selectedFile, thumbnail: '' });
                                }
                            });
                        }}><i className="fa fa-trash" aria-hidden="true" /> Remove Thumbnail
                        </Button></Row>
                    </Form.Item> : <Form.Item
                        label="Thumbnail (1280 x 720p)"
                        name="thumbnail"
                    >
                        <Upload {...thumbnailProps} >
                            <Button icon={<UploadOutlined />}>Upload Thumbnail</Button>
                        </Upload>
                    </Form.Item>}
                    <Form.Item>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div style={{ paddingRight: 10 }}>File Url</div>
                            <Switch onChange={onUploadChecked} />
                            <div style={{ paddingLeft: 10 }}>File Upload</div>
                        </div>
                    </Form.Item>
                    {
                        isUploadVideo
                            ? <Form.Item
                                label="File Upload"
                                name="uploadedFile"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter a new file!',
                                        pattern: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig
                                    },
                                ]}
                            >
                                <Upload {...props} >
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <ReactPlayer
                                        url={selectedFile.fileUrl}
                                        controls={true}
                                    />
                                </div>
                            </Form.Item>
                            : <Form.Item
                                label="File Url"
                                // name="uploadedfileUrl"
                                name="fileUrl"

                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter a new file url!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                    }
                    <Form.Item>
                        <Row justify="end">
                            <Button
                                htmlType="submit"
                                shape="round"
                                style={{ background: 'tomato', color: 'white' }}
                            >
                                Save
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
            {/* PREVIEW VIDEO MODAL */}
            <Modal
                title="File Preview"
                visible={playVideo}
                onCancel={playVideoHandleCancel}
                footer={[]}
                width={1000}
                getContainer={false}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <ReactPlayer
                        url={selectedRow.fileUrl}
                        controls={true}
                    />
                </div>
            </Modal>

            {/* Add questions to video */}
            <Modal
                title="Add Question"
                visible={addQuestions}
                // confirmLoading={addQuestionsConfirmLoading}
                onCancel={() => setaddQuestions(false)}
                footer={[]}
                getContainer={false}
            >
                <Form
                    name="basic"
                    initialValues={{ remember: false }}
                    onFinish={onAddQuestionsFinish}
                    // onFinishFailed={onAddQuestionsFinishFailed}
                    layout={'vertical'}
                >
                    <Form.Item
                        name="selectQuestion"
                        label="Select Question"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a Question!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select a option and change input text above"
                            allowClear
                            mode="multiple"
                            onChange={(e) => setSelectedQuestions(e)}
                        >
                            {
                                avalableQuestions.map((question, index) => <Option
                                    value={question._id}
                                    key={index}
                                >{question.question}</Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Row justify="end">
                            <Button
                                htmlType="submit"
                                shape="round"
                                style={{ background: 'tomato', color: 'white' }}
                            // onClick={onAddQuestionsFinish}
                            >
                                Add
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Tagged Questions"
                visible={displayQuestions}
                onCancel={() => {
                    setDisplayQuestions(false);
                    setselectedRow(false);
                }}
                footer={null}
                getContainer={false}
            >
                {avalableQuestions
                    .filter((x) => selectedRow?.questionBank?.includes(x._id))
                    .map((question) => <p key={question._id}>{question.question}</p>)}
            </Modal>
        </div>
    );
}

export default LibraryTable;
