/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { React, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import {
    Container, Button,
} from 'react-bootstrap';
import moment from 'moment';
import { message, Layout } from 'antd';
import { getCookie } from '../../cookie';
import { AlertMessage, ConfirmAlert } from '../../Reusable/Alert';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import AdminForm from './AdminForm';

const { Header, Content } = Layout;
const AUTH_TOKEN = localStorage.getItem('token');
const USER_DETAILS = JSON.parse(localStorage.getItem('userDetails'));

const Users = () => {
    const [userRole, setUserRole] = useState('');
    const [users, setUsers] = useState([]);
    // const [cities, setCities] = useState([]);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [userToDelete, setUserToDelete] = useState(''); // id
    const INITIAL_USER_TO_ADD = {
        name: '',
        email: '',
        phoneNo: '',
        role: '',

        status: '',
    };
    const [userToAdd, setUserToAdd] = useState(INITIAL_USER_TO_ADD); // id
    const [userToUpdate, setUserToUpdate] = useState({
        id: '',
        name: '',
        email: '',
        phoneNo: '',
        role: '',
        status: '',
    });
    const [showConfirmationMessage, setshowConfirmationMessage] = useState(false);
    const [answer, setAnswer] = useState(false);
    const getUsers = async () => {
        let data = await fetch(`${process.env.REACT_APP_API_URL}/users`, { headers: { Authorization: AUTH_TOKEN } });
        data = await data.json();

        let computedUsers = data;

        if (userRole === '"Admin"') {
            computedUsers = computedUsers.filter((user) => user.role === 'User');
        }

        setUsers(computedUsers);
    };

    // const getCities = () => {
    //     fetch(`${process.env.REACT_APP_API_URL}city`)
    //         .then((data) => data.json())
    //         .then((data) => {
    //             if (!data.error) {
    //                 const tempCitiesArray = data.cities.map((cityInfo, i) => {
    //                     const { city } = cityInfo;
    //                     const { state } = cityInfo;
    //                     return { id: i, city, state };
    //                 });
    //                 setCities(tempCitiesArray);
    //             }
    //         })
    //         .catch((error) => {
    //             // console.log(error);
    //         });
    // };
    const getUserRole = () => {
        const userrole = getCookie('role');
        setUserRole(userrole);
    };
    useEffect(() => {
        getUserRole();
        getUsers();
        // getCities();
    }, [userRole]);

    // ---------------------------DELETE BUTTON---------------------------------//
    const deleteUser = () => {
        const id = userToDelete;
        // const token = localStorage.getItem('token');
        axios
            .delete(`${process.env.REACT_APP_API_URL}/delete/${id}`, {
                // data: { id },
                //    {
                headers: {
                    Authorization: AUTH_TOKEN,
                },
                // }
            })
            .then((data) => {
                if (data.data.status) {
                    getUsers();
                    setAnswer(false);
                    message.success('User has been Deleted!!');
                    // setAlertMessage('User has been Deleted!!');
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 2000);
                } else {
                    message.error(data.data.message || 'Couldn\'t delete user');
                }
            })
            .catch((error) => {
                // console.log(error);
            });
    };

    const ConfirmDelete = (id) => {
        setshowConfirmationMessage(true);
        setUserToDelete(id);
    };

    useEffect(() => {
        if (answer) {
            deleteUser();
        }
        if (answer === false) {
            setshowConfirmationMessage(false);
        }
    }, [answer]);
    const deletebtn = (e) => (e.data && e.value !== USER_DETAILS.id ? <button
        className="user-btn delete-btn btn-circle btn-danger"
        style={{
            justifyContent: 'center',
            width: 35,
            height: 35,
            alignSelf: 'center',
        }}
        onClick={() => ConfirmDelete(e.value)}
    >
        <AiOutlineDelete size={20} style={{ alignSelf: 'center', marginTop: -12 }} />
    </button> : null);

    // ---------------------------EDIT BUTTON---------------------------------//

    const editBtn = (e) => {
        const getSelectedUser = async (id) => {
            // const token = localStorage.getItem('token');
            const res = await fetch(`${process.env.REACT_APP_API_URL}/get-user/${id}`, { headers: { Authorization: AUTH_TOKEN } });
            const data = await res.json();
            const userdata = data;

            setUserToUpdate({
                id,
                name: userdata?.name,
                email: userdata?.email,
                phoneNo: userdata?.phoneNo,
                role: userdata?.role,
                status: userdata?.status,
            });
            setShowUpdateModal(true);
        };
        return (
            <button
                className="user-btn edit-btn btn-circle btn-info"
                onClick={(row) => getSelectedUser(e.value)}
                style={{
                    justifyContent: 'center',
                    width: 35,
                    height: 35,
                    alignSelf: 'center',
                }}
            >
                <AiOutlineEdit size={20} color='white' style={{ alignSelf: 'center' }} />
            </button>
        );
    };

    /// ---------handleupdate--------------////

    const handleUpdate = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        axios
            .put(`${process.env.REACT_APP_API_URL}/update`, userToUpdate, {
                headers: {
                    'x-access-token': token,
                },
            })
            .then(() => {
                setShowUpdateModal(false);
                getUsers();
                setAlertMessage('User has been Updated');
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
            })
            .catch((error) => {
                // console.log(error);
            });
    };

    // -------------------------------------handleAdd--------------------------//

    const handleAdd = (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        axios
            .post(`${process.env.REACT_APP_API_URL}/register`, userToAdd, {
                headers: {
                    'x-access-token': token,
                },
            })
            .then(() => {
                setShowAddModal(false);
                getUsers();
                setAlertMessage('User has been Added');
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
            })
            .catch((error) => {
                // console.log(error);
            });
    };
    const columnDefs = users && [
        {
            headerName: 'Edit',
            field: '_id',
            cellRendererFramework: editBtn,
            width: '70px',
        },
        {
            headerName: 'Del.',
            field: '_id',
            cellRendererFramework: deletebtn,
            width: '70px',
        },
        { headerName: 'Name', field: 'name', width: '180px' },
        { headerName: 'Email', field: 'email', width: '180px' },
        { headerName: 'Phone No', field: 'phoneNo', width: '120px' },
        { headerName: 'Role', field: 'role', width: '120px' },
        { headerName: 'Status', field: 'status', width: '80px' },
        { headerName: 'Created By', field: 'addedBy.name', width: '160px' },
        { headerName: 'Updated By', field: 'updatedBy.name', width: '160px' },
        {
            headerName: 'Created At', field: 'addedAt', width: '80px', cellRendererFramework: (e) => <span>{moment(e.data.addedAt).format('hh:mm a DD/MM/YYYY')}</span>,
        },
        {
            headerName: 'Updated At', field: 'updatedAt', width: '80px', cellRendererFramework: (e) => <span>{moment(e.data.updatedAt).format('hh:mm a DD/MM/YYYY')}</span>,
        },
    ];

    return (
        <>
            {showAlert && <AlertMessage message={alertMessage} />}

            <ConfirmAlert
                confirmMessage="Are you sure to delete this user?"
                name="Delete"
                show={showConfirmationMessage}
                setAnswer={setAnswer}
                setshowConfirmationMessage={setshowConfirmationMessage}
            />
            <div className="user-container">
                <Header
                    style={{
                        padding: 0,
                        textAlign: 'center',
                        fontSize: 30,
                        fontWeight: 300,
                        background: 'white',
                        boxShadow: '2px 1px 10px lightgrey',
                    }} >
                    {'Admins'}
                </Header>
                <Content>
                    <Button
                        onClick={() => setShowAddModal(true)}
                        shape="round"
                        style={{
                            background: 'tomato',
                            color: 'white',
                            alignSelf: 'flex-end',
                            marginLeft: 20,
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    >
                        Add User
                    </Button>
                    <div style={{ marginLeft: 20 }}>
                        <div
                            className="ag-theme-alpine"
                            style={{ height: '80vh', width: '80vw' }}
                        >
                            {users ? (
                                <AgGridReact
                                    rowData={users}
                                    gridOptions={{
                                        defaultColDef: {
                                            resizable: true,
                                        },
                                    }}
                                    columnDefs={columnDefs}
                                    paginationPageSize={10}
                                    pagination={true}
                                    style={{ borderRadius: '5px' }}
                                ></AgGridReact>
                            ) : (
                                <h2>Loading</h2>
                            )}
                        </div>

                        {/* -------------------------------------
                    --------------Addusers/UpdateUser------------
                    -----------------------------------------------*/}
                        <AdminForm
                            getUsers={getUsers}
                            setShowModal={showAddModal ? setShowAddModal : setShowUpdateModal}
                            showAddModal={showAddModal}
                            showUpdateModal={showUpdateModal}
                            user={showAddModal ? userToAdd : userToUpdate}
                            INITIAL_USER_TO_ADD={INITIAL_USER_TO_ADD}
                            setUser={showAddModal ? setUserToAdd : setUserToUpdate}
                            handleSubmit={showAddModal ? handleAdd : handleUpdate}
                        />
                    </div></Content>
            </div>
        </>
    );
};

export default Users;
