/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import './TranscriptsScreen.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Layout,
    Breadcrumb,
    Button,
    Collapse,
    TimePicker,
    Form,
    Row,
    Modal,
    Input,
    Select,
    // Tag,
} from 'antd';
import { LeftOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';
import moment from 'moment';
// import { languageContext } from '../../helpers/LangContext';
// import moment from 'moment';

const AUTH_TOKEN = localStorage.getItem('token');

function TranscriptScreen() {
    const [transcripts, setTranscripts] = useState([]);
    const [allTranscripts, setAllTranscripts] = useState([]);
    const [transcriptLanguages, setTranscriptLanguages] = useState([]);
    const [selectedTranscriptLanguage, setSelectedTranscriptLanguage] = useState(null);
    const [deletetranscript, setDeleteTranscript] = useState(false);
    const [transcriptVisible, setTranscriptVisible] = useState(false);
    const [availableLanguages, setAvailableLanguages] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [timeStamp, setTimeStamp] = useState(null);
    const [captionText, setCaptionText] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('english');

    const history = useHistory();
    const location = useLocation();
    // const context = useContext(languageContext);

    const { Panel } = Collapse;
    const { TextArea } = Input;
    const { Option } = Select;
    const { Header, Content } = Layout;

    const transcriptsByLanguage = () => {
        const x = allTranscripts.filter((v) => v.language === selectedTranscriptLanguage);
        setTranscripts(x);
    };

    useEffect(() => {
        transcriptsByLanguage();
    }, [allTranscripts, selectedTranscriptLanguage]);

    const getTranscripts = () => {
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/transcript/${location.state.videoId}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            setAllTranscripts(response.data.transcripts);
            setTranscriptLanguages(response.data.languages);
            setSelectedTranscriptLanguage(response.data.languages[0]);
            transcriptsByLanguage();
        }).catch((err) => {
            Modal.error({ content: err });
        });
    };

    async function fetchLanguages() {
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/languages`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setAvailableLanguages(response.data.languages);
            }
        }).catch((err) => {
            // // console.log(err);
            Modal.error({ content: err.response.data.errorMessage });
        });
    }

    const addTranscript = () => {
        const data = {
            videoId: location.state.videoId,
            timeStamp: moment(timeStamp).format('HH:mm:ss').substring(3).replace(':', '.'),
            captionText,
            language: selectedLanguage,
        };
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/transcript`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.post(url, data, config).then((response) => {
            if (response.status === 200) {
                getTranscripts();
                setTranscriptVisible(false);
                window.location.reload();
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    };

    const editTrascript = () => {
        const data = {
            timeStamp: moment(timeStamp).format('HH:mm:ss').substring(3).replace(':', '.'),
            captionText,
        };
        // eslint-disable-next-line no-underscore-dangle
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/transcript/${selectedItem._id}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.put(url, data, config).then((response) => {
            if (response.status === 200) {
                getTranscripts();
                setTranscriptVisible(false);
                window.location.reload();
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    };

    const deleteTranscriptById = (id) => {
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/transcript/${id}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.delete(url, config).then((response) => {
            if (response.status === 200) {
                getTranscripts();
                setDeleteTranscript(false);
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    };

    useEffect(() => {
        getTranscripts();
        fetchLanguages();
    }, []);

    return (
        <div>
            <Header
                style={{
                    padding: 0,
                    textAlign: 'center',
                    fontSize: 30,
                    fontWeight: 300,
                    background: 'white',
                }} >
                {'Video Transcripts'}
            </Header>
            <Content style={{
                margin: '24px 16px 0',
                overflow: 'initial',
                background: '#fff',
                borderRadius: 10,
                boxShadow: '2px 1px 10px lightgrey',
            }}>
                <div className='topicsScreenContainer'>
                    <div className='topicsScreenTop'>
                        <div className='topicsScreenTopBreadcrumbContainer'>
                            <LeftOutlined
                                onClick={() => history.goBack()}
                                style={{ paddingRight: 10 }}
                            />
                            <Breadcrumb>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item
                                    onClick={() => history.go(-4)}
                                >
                                    <a>Modules</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    onClick={() => history.go(-3)}
                                >
                                    <a>Topics</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    onClick={() => history.go(-2)}
                                >
                                    <a>Concepts</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    onClick={() => history.go(-1)}
                                >
                                    <a>Videos</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Transcripts</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Button
                            onClick={() => {
                                setCaptionText('');
                                setTimeStamp(null);
                                setSelectedItem(null);
                                setTranscriptVisible(true);
                            }}
                            shape="round"
                            style={{ background: 'tomato', color: 'white' }}
                        >
                            Add Moment
                        </Button>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 'auto',
                        marginRight: 100,
                    }}>
                        {transcriptLanguages.map((lang, index) => <div
                            className='chips'
                            style={selectedTranscriptLanguage === lang
                                ? { backgroundColor: 'tomato', color: 'white' } : null}
                            key={index}
                            onClick={() => setSelectedTranscriptLanguage(lang)}
                        >
                            {lang}
                        </div>)}
                    </div>
                    <Collapse
                        style={{ width: '95%', margin: 20 }}
                        defaultActiveKey={['0']}
                        expandIconPosition={'right'}
                    >
                        {
                            transcripts.map((item, index) => <Panel
                                header={
                                    <div className='momentValueText'>
                                        {item.timeStamp}
                                    </div>
                                }
                                key={index}
                            >
                                <div className='momentContainer'>
                                    <div className='momentContainerTop'>
                                        <div className='momentKeyValue' style={{ minHeight: 80 }}>
                                            <div className='momentValueText'>{item.captionText}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='panelButtons' >
                                    <Button
                                        onClick={() => {
                                            setCaptionText(item.captionText);
                                            setSelectedItem(item);
                                            setTranscriptVisible(true);
                                        }}
                                        shape="round"
                                        style={{ background: 'tomato', color: 'white' }}
                                        icon={<EditFilled />}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setSelectedItem(item);
                                            setDeleteTranscript(true);
                                        }}
                                        shape="round"
                                        style={{ background: 'tomato', color: 'white', marginLeft: 10 }}
                                        icon={<DeleteFilled />}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </Panel>)
                        }
                    </Collapse>
                </div>

                <Modal
                    title={'Delete Transcript'}
                    visible={deletetranscript}
                    onCancel={() => setDeleteTranscript(false)}
                    footer={[]}
                    getContainer={false} // amazing
                >
                    {selectedItem && <div>
                        <p style={{ fontSize: 18, alignSelf: 'center' }}>
                            Sure want to Delete Transcript {selectedItem.timeStamp}?</p>
                        <Row justify="space-around" style={{ marginTop: 50 }}>
                            <Button
                                htmlType="submit"
                                onClick={() => setDeleteTranscript(false)}
                                style={{
                                    border: '1px solid tomato',
                                    color: 'tomato',
                                    minWidth: 140,
                                    fontSize: 18,
                                    height: 40,
                                }}
                            >
                                No
                            </Button>
                            <Button
                                // eslint-disable-next-line no-underscore-dangle
                                onClick={() => deleteTranscriptById(selectedItem._id)}
                                style={{
                                    backgroundColor: 'tomato',
                                    color: 'white',
                                    minWidth: 140,
                                    fontSize: 18,
                                    height: 40,
                                }}
                            >
                                Yes
                            </Button>
                        </Row>
                    </div>}
                </Modal>
                <Modal
                    title={selectedItem ? 'Edit Transcript' : 'Add Transcript'}
                    visible={transcriptVisible}
                    footer={false}
                    onCancel={() => setTranscriptVisible(false)}
                >
                    <Form
                        name="basic"
                        layout={'vertical'}
                    >
                        <Form.Item
                            label="Language"
                            name="language"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Language',
                                },
                            ]}
                        >
                            {selectedItem ? <p style={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                marginLeft: 10,
                                textTransform: 'capitalize',
                            }}
                            >
                                {selectedItem.language}
                            </p>
                                : <Select defaultValue={'Select Language'} style={{ width: '100%', marginBottom: 5 }}
                                    // eslint-disable-next-line no-confusing-arrow
                                    onChange={(val) => selectedItem ? Modal.warn({ title: 'Cannot edit transcript language' }) : setSelectedLanguage(val)}>
                                    {availableLanguages.map((language, index) => <Option
                                        style={{ textTransform: 'capitalize' }}
                                        key={index}
                                        value={language.label.toLocaleLowerCase()}
                                    >{language.label}
                                    </Option>)}
                                </Select>
                            }
                        </Form.Item>

                        <Form.Item
                            label="Transcript Timestamp ( HH:mm:ss )"
                            name="timeStamp"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter a new timestamp!',
                                },
                                () => ({
                                    validator(_, value) {
                                        const correctFormat = true;
                                        if (!value || correctFormat) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Timestamp Format not correct!'));
                                    },
                                }),
                            ]}
                        >
                            <TimePicker style={{ width: '100%' }}
                                defaultValue={moment('00:00:00', 'HH:mm:ss')}
                                size="large" onChange={(t) => setTimeStamp(t)}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Transcript Caption"
                            name="captionText"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter a new description!',
                                },
                            ]}
                        >
                            <TextArea rows={4}
                                value={captionText}
                                onChange={(e) => setCaptionText(e.target.value)}
                            />
                            <p style={{ fontSize: 0, color: 'white' }}>{captionText}</p>
                        </Form.Item>

                        <Form.Item>
                            <Row justify="end">
                                <Button
                                    htmlType="submit"
                                    shape="round"
                                    style={{ background: 'tomato', color: 'white' }}
                                    // eslint-disable-next-line no-confusing-arrow
                                    onClick={() => selectedItem ? editTrascript() : addTranscript()}
                                >
                                    {selectedItem ? 'Save Changes' : 'Submit'}
                                </Button>
                            </Row>
                        </Form.Item>
                    </Form>
                </Modal>
            </Content>
        </div>
    );
}
export default TranscriptScreen;
