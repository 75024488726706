// const availableLanguages = [
//     {
//         text: 'English',
//         value: 'English',
//     },
//     {
//         text: 'Hindi',
//         value: 'Hindi',
//     },
//     {
//         text: 'Telugu',
//         value: 'Telugu',
//     },
//     {
//         text: 'Odia',
//         value: 'Odia',
//     },
//     {
//         text: 'Marathi',
//         value: 'Marathi',
//     },
// ];

const availableLanguages = [
    {
        text: 'English',
        value: 'English',
        label: 'English',
    },
    {
        text: 'Hindi',
        value: 'Hindi',
        label: 'Hindi',
    },
    {
        text: 'Marathi',
        value: 'Marathi',
        label: 'Marathi',
    },
    {
        text: 'Tamil',
        value: 'Tamil',
        label: 'Tamil',
    },
    {
        text: 'Bengali',
        value: 'Bengali',
        label: 'Bengali',
    },
    {
        text: 'Kannada',
        value: 'Kannada',
        label: 'Kannada',
    },
    {
        text: 'Malayalam',
        value: 'Malayalam',
        label: 'Malayalam',
    },
    {
        text: 'Gujarati',
        value: 'Gujarati',
        label: 'Gujarati',
    },
    {
        text: 'Telugu',
        value: 'Telugu',
        label: 'Telugu',
    },
    {
        text: 'Punjabi',
        value: 'Punjabi',
        label: 'Punjabi',
    },
    {
        text: 'Assamese',
        value: 'Assamese',
        label: 'Assamese',
    },
    {
        text: 'Odia',
        value: 'Odia',
        label: 'Odia',
    },
];
export default availableLanguages;
