/* eslint-disable no-undef */
import { React, useState, useEffect } from 'react';
import { Button } from 'antd';
import axios from 'axios';
import { Content } from 'antd/lib/layout/layout';
import MentorshipModal from './MentorshipModal';
import TableHeader from './TableHeader';

const AUTH_TOKEN = localStorage.getItem('token');

function MentorshipEventScreen() {
    const [showModal, setShowModal] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [roles, setRoles] = useState([]);
    const [businessIdeas, setBusinessIdeas] = useState([]);
    const [mentorDetails, setMentorDetails] = useState([]);
    async function fetchSessions() {
        const url = `${process.env.REACT_APP_SESSION_API_URL}/sessions/get-all-sessions-for-admin`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
          if (response.status === 200 && response.data.error === false) {
            setSessions(response.data.session);
            setRoles(response.data.roles);
          }
        });
      }

      async function fetchMentorDetails() {
        const url = `${process.env.REACT_APP_SESSION_API_URL}/session-users/get-session-user`;
        // const config = { headers: { Authorization: AUTH_TOKEN } };
        // console.log('url ==== >>>', url, config);
        axios.get(url).then((response) => {
          if (response.status === 200 && response.data.error === false) {
            setMentorDetails(response.data.sessionUsers);
        // console.log('response.data.sessionUsers', response.data.sessionUsers);
            }
        });
      }
      async function fetchBusinessIdeas() {
        const url = `${process.env.REACT_APP_SWATI_API_URL}/api/v1/bussinessIdeas/all-detailed`;
        axios.get(url).then((response) => {
            if (response.status) {
                setBusinessIdeas(response.data.bussinessIdeas || []);
            }
        });
    }

    useEffect(async () => {
        await fetchSessions();
        await fetchBusinessIdeas();
        await fetchMentorDetails();
    }, []);
    //  const [permittedAttendees, setPermittedAttendees] = useState([]);
    return (
        <div >
        <Content className="site-layout-background header" style={{ boxShadow: '2px 1px 10px lightgrey' }}>
                <p></p>
                <h3>Events</h3>
                <Button
                    onClick={() => { setShowModal(true); }}
                    shape="round"
                    style={{ background: 'tomato', color: 'white' }}
                >
                    Add Event
                </Button>
            </Content>

     <div className="libraryScreenMiddle">
  <TableHeader sessions={sessions}
  roles={roles} businessIdeas={businessIdeas}
  fetchSessions={fetchSessions}
  mentorDetails={mentorDetails}
  />
     </div>
            <MentorshipModal
                fetchSessions={fetchSessions}
                businessIdeas={businessIdeas}
                mentorDetails={mentorDetails}
                close={() => {
                    setShowModal(false);
                }}
                show={showModal}
            />
        </div>
        );
}

export default MentorshipEventScreen;
