import React from 'react';
import '../LibraryScreen/LibraryScreen.css';
import {
    Layout,
    Breadcrumb,
    // Modal,
} from 'antd';
// import axios from 'axios';
import UserList from '../../Components/UserList/UserList';
// import availableLanguages from '../../Components/AvailableLanguages/AvailableLanguages';

const UsersScreen = () => {
        const { Header, Content } = Layout;
        // const [users, setUsers] = useState(null);

    return (
        <div>
            <Header
                style={{
                    padding: 0,
                    textAlign: 'center',
                    fontSize: 30,
                    fontWeight: 300,
                    background: 'white',
                }} >
                { 'Users' }
            </Header>
            <Content style={{
                margin: '24px 16px 0',
                overflow: 'initial',
                background: '#fff',
                borderRadius: 10,
                boxShadow: '2px 1px 10px lightgrey',
            }}>
                <div className='libraryScreenContainer'>
                    <div className='libraryScreenTop'>
                        <div className='libraryScreenTopBreadcrumbContainer'>
                            <Breadcrumb>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Users</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                    <div style={{ width: '100%', paddingLeft: 20 }}>
                        <UserList />
                    </div>
                </div>
            </Content>
        </div>
        );
};

export default UsersScreen;
