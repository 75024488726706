/* eslint-disable no-undef */
/* eslint-disable no-useless-escape */
/* eslint-disable no-underscore-dangle */
import {
  Button, Modal, Select,
  Form, Input, DatePicker, TimePicker, Upload,
} from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import FormData from 'form-data';
import { UploadOutlined } from '@ant-design/icons';
import availableLanguages from '../../Components/AvailableLanguages/AvailableLanguages';

const { Option } = Select;
const AUTH_TOKEN = localStorage.getItem('token');
// eslint-disable-next-line comma-dangle, object-curly-newline
function MentorshipModal({ show, close, fetchSessions, businessIdeas, mentorDetails, }) {
  const [fileConfirmLoading, setfileConfirmLoading] = useState(false);
  const [groupsKeywords, setGroupsKeywords] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [eventDate, setEventDate] = useState(null);

  // const [selectedkeywords, setSelectedKeywords] = useState([]);
  // eslint-disable-next-line no-trailing-spaces
  const { TextArea } = Input;

  const sessionProps = {
    accept: 'image/*',
    beforeUpload: () => false,
    listType: 'picture',
  };

  async function fetchKeywords() {
    const url = `${process.env.REACT_APP_LIBRARY_URL}/synonyms/keywords`;
    axios.get(url).then((response) => {
      if (response.status) {
        setGroupsKeywords(response.data.keywords);
      }
    });
  }
  // eslint-disable-next-line no-trailing-spaces
  useEffect(() => {
    fetchKeywords();
  }, []);
  // eslint-disable-next-line no-trailing-spaces

  const changeEventDate = (date) => {
    setEventDate(date);
  };

  const getTimeDiff = (sTime, eTime) => {
    let time = '';
    const hours = parseInt(
      moment
        .duration(moment(eTime, 'HH:mm').diff(moment(sTime, 'HH:mm')))
        .asHours(),
      10,
    );
    if (hours > 1) time = `${hours * 60}  hours `;
    else if (hours === 1) time = `${hours * 60}  hour`;
    const mins = parseInt(
      moment
        .duration(moment(eTime, 'HH:mm').diff(moment(sTime, 'HH:mm')))
        .asMinutes() % 60,
      10,
    );
    if (mins > 1) time += `${mins} minutes `;
    else if (mins === 1) time += `${mins} minute `;
    if (time === '') return false;
    return time;
  };

  const onFinish = (values) => {
    setfileConfirmLoading(true);
    // eslint-disable-next-line quotes
    const sessionName = new RegExp(`^[\.a-zA-Z0-9_:' +-]*$`);
    const name = values.eventName;
    if (sessionName.test(name) === false) {
      Modal.error({ content: `${'Special characters are not allowed'}` });
      return;
    }
    const url = `${process.env.REACT_APP_SESSION_API_URL}/sessions`;
    const config = { headers: { Authorization: AUTH_TOKEN } };
    const fileForm = new FormData();
    const EndTime = moment(endTime).format('HH:mm');
    const StartTime = moment(startTime).format('HH:mm');
    const durationEvent = getTimeDiff(StartTime, EndTime);
    fileForm.append('eventName', values.eventName);
    fileForm.append('description', values.description);
    fileForm.append('keywords', values.keywords);
    fileForm.append('startTime', moment(startTime).format('HH:mm'));
    fileForm.append('endTime', moment(endTime).format('HH:mm'));
    fileForm.append('eventDuration', durationEvent.split(' ')[0]);
    fileForm.append('eventDate', moment(eventDate).format('D-MM-YYYY'));
    fileForm.append('businessIdeas', values.businessIdeas);
    fileForm.append('language', values.language);
    fileForm.append('email', values.email);
    fileForm.append('mobileNumber', values.mobileNumber);
    fileForm.append('organisation', values.organisation);
    fileForm.append('designation', values.designation);
    fileForm.append('attendees', values.attendees);

    if (values.level) {
      fileForm.append('level', values.level);
    } else {
      fileForm.append('level', 0);
    }

    if (values.events && values.events.file) {
      if (values.events.file.type.includes('audio')) fileForm.append('thumbnail', 'audio');
      else if (values.events.file.type.includes('video')) fileForm.append('thumbnail', 'video');
      else fileForm.append('thumbnail', values.events.file.type);
      fileForm.append('thumbnail', values.events.file);
    } else fileForm.append('thumbnail', values.events || '');
    if (startTime && endTime && startTime < endTime) {
      axios.post(url, fileForm, config).then(async (response) => {
        if (response) {
          await fetchSessions();
          Modal.success({ content: `${response.data.message}` });
          close();
        } else {
          Modal.error({ content: 'File Not Added, Please Try Again' });
        }
      }).catch((err) => {
        console.log('Error :', err);
        Modal.error({ content: 'Error in Post, Please Try Again' });
      });
    } else {
      Modal.error({ content: 'Start time cannot be more than end time!' });
    }
  };

  return (
    <Modal
      title="Add Event"
      visible={show}
      onCancel={close}
      width="60%"
      confirmLoading={fileConfirmLoading}
      footer={[]}
      getContainer={false}
    >
      <Form
        name="basic-event"
        onFinish={onFinish}
        layout={'vertical'}
      >
        <div className="test" style={{ padding: 10 }}>
          <Form.Item
            name="eventName"
            label="Event Title (Max 200 characters. No special characters.)"
            rules={[
              {
                required: true,
                message: 'Enter a Event Title!',
              },
            ]}
          >
            <Input maxLength={200} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description (Max 2000 characters. No special characters.)"
            rules={[
              {
                required: true,
                message: 'Enter a Event Description!',
              },
            ]}
          >
            <TextArea className='textArea' rows={6} maxLength={2000} />
          </Form.Item>
          <div className='event-box'>
            <Form.Item
              name="eventDate"
              label="Event Date"
              rules={[
                {
                  required: true,
                  message: 'Enter a  Event Date!',
                },
              ]}
            >
              <DatePicker picker='date' onChange={(d) => changeEventDate(d)} />
            </Form.Item>
            <Form.Item
              name="startTime"
              label="Start Time"
              rules={[
                {
                  required: true,
                  message: 'Enter a Start Time!',
                },
              ]}
            >
              <TimePicker use12Hours format="h:mm a" picker='time' onChange={(t) => setStartTime(t)} />
            </Form.Item>
            <Form.Item
              name="endTime"
              label="End Time"
              rules={[
                {
                  required: true,
                  message: 'Enter a End Time!',
                },
              ]}
            >
              <TimePicker use12Hours format="h:mm a" picker='time' onChange={(t) => setEndTime(t)} />
            </Form.Item>
            {endTime && startTime && (startTime < endTime) ? <Form.Item
              name="eventDuration"
              label="Event Duration"
            >
              <Input style={{ color: 'black' }} disabled={true} defaultValue={moment.duration(endTime.diff(startTime, 'minutes'))} />
            </Form.Item> : null}

          </div>
          <div>
            <Form.Item label="Event Thumbnail (800 x 800)" name="events">
              <Upload listType="picture-card" {...sessionProps}>
                <Button icon={<UploadOutlined />}>Upload Image</Button>
              </Upload>
            </Form.Item>
          </div>
          <div>
            <Form.Item name="language" label="Language"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select a option and change input text above"
                allowClear
              >
                {
                  availableLanguages.map((language, index) => <Option
                    value={language.value}
                    key={index}>{language.text}</Option>)
                }
              </Select>
            </Form.Item>
            <Form.Item
              name="keywords"
              label="Group"
              rules={[{ required: true, message: 'Add Groups' }]}
            >
              <Select
                placeholder="Select one or more groups"
                allowClear
                mode="multiple"
              >
                {groupsKeywords.map((word) => (
                  // eslint-disable-next-line no-underscore-dangle
                  <Option key={word._id} value={word._id}>
                    {word.keyword}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="businessIdeas" label="Business Ideas"
              rules={[{ required: true, message: 'Please select at least one business idea that goes with this video.' }]}
            >
              <Select
                mode="multiple"
                placeholder="Select one or more business ideas that apply"
                allowClear
              >
                {businessIdeas.map((idea) => (
                  <Option key={idea.combine} label={idea._inEnglish}
                    title={idea._inEnglish}
                    value={idea._id}>{idea.inEnglish}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="level" label="Level"
              rules={[{ required: true, message: 'Please select a level' }]}
            >
              <Select
                placeholder="Select business level"
                allowClear
              >
                {[1, 2, 3, 4, 5, 6, 7].map((level) => (
                  <Option key={level} label={level}
                    title={level}
                    value={level}>{`Level - ${level}`}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item name="attendees"
          label="Mentor Name"
              rules={[{ required: true }]}
            >
              <Select
              mode="multiple"
                placeholder="Select an option "
                allowClear
              >
                {
                  mentorDetails.map((mentor, index) => <Option
                    value={mentor._id}
                    key={index}>{mentor.name}</Option>)
                }
              </Select>
            </Form.Item>
          <div className="mt-4 align-right">
            <Button
              // onClick={() => handleSubmit(keyword, synonyms)}
              shape="round"
              htmlType="submit"
              style={{
                background: 'tomato',
                color: 'white',
                alignSelf: 'flex-end',
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
export default MentorshipModal;
