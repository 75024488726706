import { React, useState } from 'react';
import { Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import QuotationModal from './QuotationModal';
import TableHeader from './TableHeader';

function QuotationScreen() {
    const [showModal, setShowModal] = useState(false);
    return (

        <div >
        <Content className="site-layout-background header" style={{ boxShadow: '2px 1px 10px lightgrey' }}>
                <p></p>
                <h3>Quotes</h3>
                <Button
                    onClick={() => { setShowModal(true); }}
                    shape="round"
                    style={{ background: 'tomato', color: 'white' }}
                >
                    Add Quote
                </Button>
            </Content>

     <div className="libraryScreenMiddle">
  <TableHeader />

     </div>

            <QuotationModal
                close={() => {
                    setShowModal(false);
                }}
                show={showModal}
                // editGroup={selectedGroup}
                // handleSubmit={handleSubmit}
            />
        </div>
        );
}

export default QuotationScreen;
