/* eslint-disable no-undef */
/* eslint-disable react/display-name */
/* eslint-disable no-underscore-dangle */
/* eslint-disable object-shorthand */
import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import './TopicsScreen.css';
import {
    Space,
    Breadcrumb,
    Layout,
    Button,
    Modal,
    // Form,
    Input,
    Row,
    Table,
    Select,
} from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import {
    useHistory,
    useLocation,
} from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import PropTypes from 'prop-types';

const AUTH_TOKEN = localStorage.getItem('token');

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({
    index,
    moveRow,
    className,
    style,
    ...restProps
}) => {
    const ref = useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: (item) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));

    DraggableBodyRow.propTypes = {
        index: PropTypes.any,
        moveRow: PropTypes.any,
        className: PropTypes.any,
        style: PropTypes.any,
        ...restProps,
    };

    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{ cursor: 'move', ...style }}
            {...restProps}
        />
    );
};

function TopicsScreen() {
    const [topicVisible, settopicVisible] = useState(false);
    const [topicsArray, setTopicsArray] = useState([]);
    const [topicConfirmLoading, setTopicConfirmLoading] = useState(false);
    const [filter, setFilter] = useState({ filterTable: null });
    const [enableReorder, setEnableReorder] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('english');
    const [availableLanguages, setAvailableLanguages] = useState([]);
    const [formValues, setFormValues] = useState({ name: '', description: '' });
    const [disallowTopicAddition, setDisallowTopicAddition] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const { moduleId, lang, courseId } = location.state;
    const { Header, Content } = Layout;
    const { Option } = Select;
    const showAddTopicModal = () => settopicVisible(true);
    const topicHandleCancel = () => settopicVisible(false);

    async function fetchTopics() {
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/topic/admin/${moduleId}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200) {
                setTopicsArray(response.data.topics);
                setDisallowTopicAddition(response.data.disallowTopicAddition || false);
            }
        }).catch((err) => {
            // console.log(err);
            Modal.error({ content: err.response.data.errorMessage });
        });
    }

    const reorderTopics = () => {
        const dataArr = [];
        for (let i = 0; i < topicsArray.length; i += 1) {
            const e = topicsArray[i];
            dataArr.push(e.topic);
        }
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/topic/update-sequence`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        const data = { topics: dataArr };
        axios.put(url, data, config).then((response) => {
            if (response.status === 200) {
                fetchTopics();
                setEnableReorder(false);
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    };

    async function fetchLanguages() {
        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/languages`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        await axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setAvailableLanguages(response.data.languages);
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    }

    useEffect(() => {
        fetchTopics();
        fetchLanguages();
        setSelectedLanguage(lang);
    }, []);

    const onTopicFinish = () => {
        setTopicConfirmLoading(true);
        const data = formValues;
        data.moduleId = moduleId;

        const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/topic`;
        const config = { headers: { Authorization: AUTH_TOKEN } };

        axios.post(url, data, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                settopicVisible(false);
                setTopicConfirmLoading(false);
                Modal.success({ content: `${response.data.message}` });
                fetchTopics();
            } else {
                Modal.error({ content: 'Topic Not Added, Please Try Again' });
            }
        }).catch((err) => {
            // console.log(err);
            Modal.error({ content: err.response.data.errorMessage });
        });
    };

    const IconText = ({ icon, text }) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );
    IconText.propTypes = {
        icon: PropTypes.any,
        text: PropTypes.any,
    };

    const columns = [
        {
            title: 'Topic Name',
            dataIndex: ['name'],
            key: 'name',
            render: (topic, currentTopic) => <p style={{ cursor: 'pointer', color: 'blue' }}
                onClick={
                    () => {
                        history.push({
                            pathname: `/topics/${currentTopic._id}`,
                            state: {
                                topicId: currentTopic._id,
                                moduleId: moduleId,
                                lang: selectedLanguage,
                                courseId,
                            },
                        });
                    }
                }>
                {topic}
            </p>,
        },
        {
            title: 'Description',
            dataIndex: ['description'],
            key: 'description',
        },
        {
            title: 'Videos',
            dataIndex: ['videoCount'],
            key: 'videos',
            render: (topic, currentTopic) => <p style={{ cursor: 'pointer', color: 'blue' }}
                onClick={
                    () => {
                        history.push({
                            pathname: `/topics/${currentTopic._id}`,
                            state: {
                                topicId: currentTopic._id,
                                moduleId: moduleId,
                                lang: selectedLanguage,
                                courseId,
                            },
                        });
                    }
                }>
                {topic}
            </p>,
        },
    ];

    const components = {
        body: {
            row: (enableReorder) ? DraggableBodyRow : null,
        },
    };

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = topicsArray[dragIndex];
            setTopicsArray(
                update(topicsArray, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );
        },
        [topicsArray],
    );

    function search(value) {
        const filterTable = topicsArray.filter((o) => Object.keys(o).some((k) => String(o[k])
            .toLowerCase().includes(value.toLowerCase())));
        setFilter({ filterTable });
    }

    return (
        <div>
            <Header
                style={{
                    padding: 0,
                    textAlign: 'center',
                    fontSize: 30,
                    fontWeight: 300,
                    background: 'white',
                }} >
                {'Topics'}
            </Header>
            <Content style={{
                margin: '24px 16px 0',
                overflow: 'initial',
                background: '#fff',
                borderRadius: 10,
                boxShadow: '2px 1px 10px lightgrey',
            }}>
                <div className='topicsScreenContainer'>
                    <div className='topicsScreenTop'>
                        <div className='topicsScreenTopBreadcrumbContainer'>
                            <LeftOutlined
                                onClick={() => history.goBack()}
                                style={{ paddingRight: 10 }}
                            />
                            <Breadcrumb>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item
                                    onClick={() => history.goBack()}
                                >
                                    <a>Modules</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Topics</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Button
                            onClick={showAddTopicModal}
                            shape="round"
                            disabled={disallowTopicAddition}
                            style={disallowTopicAddition ? { background: 'grey', color: 'white' } : { background: 'tomato', color: 'white' }}
                        >
                            Add Topic
                        </Button>
                    </div>
                    <div className='moduleSearchContainer' style={{ width: '100%' }}>
                        <Select
                            defaultValue={'Select Language'}
                            style={{ width: 120, marginRight: 20 }}
                            onChange={(value) => setSelectedLanguage(value)}
                        >
                            {availableLanguages.map((language, index) => <Option
                                style={{ textTransform: 'capitalize' }}
                                key={index}
                                value={language.label.toLocaleLowerCase()}
                            >{language.label}
                            </Option>)}
                        </Select>
                        <Input.Search
                            style={{
                                width: 300,
                            }}
                            placeholder="Search any field..."
                            enterButton
                            onChange={(e) => search(e.target.value)}
                        />
                    </div>
                    <div style={{ width: '100%', padding: 10 }}>
                        <Row>
                            <DndProvider backend={HTML5Backend}>
                                <Table
                                    style={{ width: '100%', paddingRight: 20 }}
                                    pagination={{
                                        position: ['bottomLeft'],
                                        defaultPageSize: 20,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['20', '50', '100'],
                                    }}
                                    columns={columns}
                                    dataSource={filter.filterTable === null
                                        ? topicsArray : filter.filterTable}
                                    components={components}
                                    onRow={(record, index) => ({
                                        index,
                                        moveRow,
                                    })}
                                />
                            </DndProvider>
                        </Row>
                        <Row style={{ justifyContent: 'flex-end', paddingRight: 20 }}>
                            <Button
                                onClick={() => setEnableReorder(!enableReorder)}
                                shape="round"
                                style={{
                                    background: 'tomato',
                                    color: 'white',
                                }}
                            >
                                {!enableReorder ? 'Enable reorder' : 'Disable reorder'}
                            </Button>
                            <Button
                                onClick={() => reorderTopics()}
                                shape="round"
                                style={{
                                    background: 'tomato',
                                    color: 'white',
                                    marginLeft: 20,
                                }}
                            >
                                Save changes
                            </Button>
                        </Row>
                    </div>
                    <Modal
                        title="Add Topic"
                        visible={topicVisible}
                        confirmLoading={topicConfirmLoading}
                        onCancel={topicHandleCancel}
                        footer={[]}
                        getContainer={false}
                    >
                        <p style={{ marginTop: 20 }}>Topic Name <span style={{ color: 'red' }}>*</span></p>
                        <input
                            required={true}
                            style={{
                                width: '100%',
                                borderColor: 'lightgrey',
                                borderWidth: 1,
                                height: 30,
                            }}
                            type='text'
                            name='name'
                            onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                        // onChange={(e) => // console.log(e.target.value)}
                        />
                        <p style={{ marginTop: 20 }}>Topic Description</p>
                        <textarea style={{
                            height: 60,
                            width: '100%',
                            borderColor: 'lightgrey',
                            borderWidth: 1,
                        }}
                            name='description'
                            onChange={(e) => setFormValues(
                                { ...formValues, description: e.target.value },
                            )}
                        />
                        <Row justify="end">
                            <Button
                                onClick={() => onTopicFinish()}
                                htmlType="submit"
                                shape="round"
                                style={{
                                    pointerEvents: (formValues.name === '') ? 'none' : 'auto',
                                    background: (formValues.name === '') ? 'lightgrey' : 'tomato',
                                    color: 'white',
                                }}
                            >
                                Submit
                            </Button>
                        </Row>
                    </Modal>
                </div>
            </Content>
        </div>
    );
}

export default TopicsScreen;
