/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, {
    useState,
    useEffect,
} from 'react';
import {
    Table,
    Form,
    Typography,
    Modal,
    Button,
    Select,
    Tooltip,
} from 'antd';
import './TableHeader.css';
import axios from 'axios';
import moment from 'moment';
import {
    DeleteFilled,
} from '@ant-design/icons';
import availableLanguages from '../../Components/AvailableLanguages/AvailableLanguages';

const AUTH_TOKEN = localStorage.getItem('token');

function QuestionTable() {
    const [quotes, setQuotes] = useState([]);
    const [groupsKeywords, setGroupsKeywords] = useState([]);
    const [fileForDeletion, setFileForDeletion] = useState(null);
    const [confirmDeletion, setConfirmDeletion] = useState(false);
    // const [availableCatagories, setavailableCatagories] = useState([]);
    async function fetchQuotes() {
        const url = `${process.env.REACT_APP_LIBRARY_URL}/quotes`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setQuotes(response.data.quote);
            }
        });
    }
    async function fetchKeywords() {
        const url = `${process.env.REACT_APP_LIBRARY_URL}/synonyms/keywords`;
        axios.get(url).then((response) => {
          if (response.status) {
            setGroupsKeywords(response.data.keywords);
          }
        });
      }
      const deleteFile = (row) => {
        const { _id } = row;
        const url = `${process.env.REACT_APP_LIBRARY_URL}/quotes/${_id}`;
        const config = { headers: { Authorization: AUTH_TOKEN, 'x-access-token': localStorage.getItem('token') } };

        axios.delete(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                // fetchLibrary();
                fetchQuotes();
                setConfirmDeletion(false);
                Modal.success({ content: `${response.data.message}` });
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    };
    useEffect(() => {
        fetchQuotes();
        fetchKeywords();
    }, []);

    // ------------------------- CONFIRMATION MODAL -------------------------
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
    const [selectedQuotes, setselectedQuotes] = useState(false);
    const [editingQuestion, seteditingQuestion] = useState({});
    const showConfirmationModal = () => setIsConfirmationModalVisible(true);
    const handleOk = () => {
        setIsConfirmationModalVisible(false);

        const { _id } = selectedQuotes;
        const url = `${process.env.REACT_APP_QUESTION_URL}/questions/${_id}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.put(url, editingQuestion, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                Modal.success({ content: `${response.data.message}` });
                fetchQuestions();
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
        setselectedQuotes(false);
    };
    const handleCancel = () => {
        setIsConfirmationModalVisible(false);
        setselectedQuotes(false);
    };

 // eslint-disable-next-line no-trailing-spaces
 
    const columns = [
        {
            title: 'Quote ID',
            dataIndex: 'id',
            align: 'center',
        },
        {
            title: 'Language',
            dataIndex: 'language',
            align: 'center',
             editable: true,
            filters: availableLanguages,
            render: (_, record) => <span style={{ textTransform: 'capitalize' }}>{record.language}</span>,
            onFilter: (value, record) => record.language.indexOf(value).toUpperCase() === 0,
        },
        {
            title: 'Quote',
            dataIndex: 'title',
            align: 'center',
            ellipsis: true,
            render: (_, record) => (
                <Typography.Link >
                 <Tooltip placement="topLeft" title={record.title}>
                 <a href={record.thumbnail} target='_blank' rel="noopener noreferrer"><span>{record.title}</span> </a>
                 </Tooltip>
                </Typography.Link>
                ),
        },
        // {
        //     title: 'Image URL',
        //     dataIndex: 'thumbnail',
        //     align: 'center',
        //     ellipsis: true,
        //     render: (_, record) => (
        //         <Typography.Link
        //         > <a href={record.thumbnail} target='_blank'
        //         rel = "noopener noreferrer">Image Url</a>
        //        </Typography.Link>
        //     ),
        // },
        {
            title: 'Added By',
            dataIndex: ['metaInfo', 'created', 'by'],
            align: 'center',
            render: (_, record) => {
                const { metaInfo } = record;
                return (
                     <span>{metaInfo.created.by.name}</span>
                );
            },
         },
        {
            title: 'Added On',
            dataIndex: ['metaInfo', 'created', 'at'],
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                const { metaInfo } = record;
                return (
                    <span>
                        { moment(metaInfo.created.at).format('DD/MM/YYYY HH:mm') }
                    </span>
                );
            },
        },
        {
            title: 'Updated On',
            dataIndex: ['metaInfo', 'updated', 'at'],
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                const { metaInfo } = record;
                return (
                    <span>
                        { moment(metaInfo.updated.at).format('DD/MM/YYYY HH:mm') }
                    </span>
                );
            },
        },
         {
            title: 'Delete',
            dataIndex: 'delete',
            align: 'center',
              // eslint-disable-next-line react/display-name
            render: (_, record) => (
                <Typography.Link
                onClick={() => { setFileForDeletion(record); setConfirmDeletion(true); }} >
                <DeleteFilled
                    style={{ color: 'tomato', fontSize: 20 }}
                   />
                   </Typography.Link>
            ),
        },
    ];
    useEffect(() => {
        if (selectedQuotes !== false) showQuestionModal();
    }, [selectedQuotes]);

    // SEARCH FILTER
    // eslint-disable-next-line no-unused-vars
    const [filter, setFilter] = useState({ filterTable: null });
   return (
        <div className='questionTableContainer'>

            <Form component={false}>
                <Table
                    bordered
                    showHeader={true}
                    className='questionBankTable'
                    dataSource={filter.filterTable === null ? quotes : filter.filterTable}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={{ position: ['bottomLeft'], pageSize: ['500'] } }
                    rowKey='id'
                />
            </Form>

            {/* CONFIRMATION MODAL */}
            <Modal
                title="ARE YOU SURE?"
                visible={isConfirmationModalVisible}
                onCancel={handleCancel}
                footer={[]}
            >
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <Button
                        htmlType="submit"
                        shape="round"
                        style={{ background: 'tomato', color: 'white' }}
                        onClick={handleOk}
                    >
                        YES
                    </Button>

                </div>
            </Modal>

            <Modal
                visible={confirmDeletion}
                onCancel={() => setConfirmDeletion(false)}
                onOk={() => deleteFile(fileForDeletion)}
                width={'40%'}
                style={{ marginTop: 150 }}
                getContainer={false}
            >
                <p> Sure want to delete
                    <strong>{' '}{fileForDeletion && fileForDeletion.id}</strong> ?
                </p>
            </Modal>
        </div>
    );
}

export default QuestionTable;
