import React, { createContext, useState } from 'react';

export const languageContext = createContext({});

const LangContext = (props) => {
  const [selectedLanguage, setSelectedLanguage] = useState('english');

  const saveSelectedLanguage = (language) => {
    setSelectedLanguage(language);
  };

  return (
    <languageContext.Provider
      value={{
        state: { selectedLanguage },
        // eslint-disable-next-line  object-shorthand
        saveSelectedLanguage: saveSelectedLanguage,
      }}
      {...props}
    />
  );
};

export default LangContext;
