/* eslint-disable no-undef */
import React, {
    useState,
    useEffect,
} from 'react';
import {
    Table,
    Input,
    Form,
    Typography,
    Modal,
    Button,
    Select,
    Checkbox,
    Row,
} from 'antd';
import './QuestionTable.css';
import axios from 'axios';
import moment from 'moment';
import {
    EditFilled,
    PlusOutlined,
    MinusOutlined,
} from '@ant-design/icons';
import availableLanguages from '../AvailableLanguages/AvailableLanguages';

const AUTH_TOKEN = localStorage.getItem('token');

function QuestionTable() {
    const { Option } = Select;

    const [questionBank, setquestionBank] = useState([]);
    const [availableCatagories, setavailableCatagories] = useState([]);
    async function fetchQuestions() {
        const url = `${process.env.REACT_APP_QUESTION_URL}/questions`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                // console.log(response.data.questions);
                setquestionBank(response.data.questions);
            }
        });
    }
    async function fetchCategories() {
        const url = `${process.env.REACT_APP_QUESTION_URL}/categories`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setavailableCatagories(response.data.categories);
            }
        }).catch((err) => {
            // console.log(err);
            Modal.error({ content: err.response.data.errorMessage });
        });
    }
    useEffect(() => {
        fetchQuestions();
        fetchCategories();
    }, []);

    // ------------------------- CONFIRMATION MODAL -------------------------
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
    const [selectedQuestion, setselectedQuestion] = useState(false);
    const [editingQuestion, seteditingQuestion] = useState({});
    const showConfirmationModal = () => setIsConfirmationModalVisible(true);
    const handleOk = () => {
        setIsConfirmationModalVisible(false);

        const { _id } = selectedQuestion;
        const url = `${process.env.REACT_APP_QUESTION_URL}/questions/${_id}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.put(url, editingQuestion, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                Modal.success({ content: `${response.data.message}` });
                fetchQuestions();
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
        setselectedQuestion(false);
    };
    const handleCancel = () => {
        setIsConfirmationModalVisible(false);
        setselectedQuestion(false);
    };

    // ------------------------- EDIT QUESTION MODAL -------------------------
    const [questionVisible, setQuestionVisible] = useState(false);
    const [questionConfirmLoading, setQuestionConfirmLoading] = useState(false);
    const [correctOptionsIndices, setcorrectOptionsIndices] = useState([]);

    const showQuestionModal = () => setQuestionVisible(true);
    const questionHandleCancel = () => {
        setQuestionVisible(false);
        setselectedQuestion(false);
    };
    const onQuestionFinishFailed = (errorInfo) => Modal.error({ content: errorInfo });

    const onQuestionFinish = (values) => {
        const correctOptionsList = [];
        for (let i = 0; i < values.options.length; i += 1) {
            const tempOptionObj = {
                option: values.options[i],
                isTrue: correctOptionsIndices.includes(i),
            };
            correctOptionsList.push(tempOptionObj);
        }
        const editedQuestion = {
            question: values.question,
            language: values.language,
            categoryId: values.categoryId,
            helpText: values.helpText,
            optionFormat: values.optionFormat,
            options: correctOptionsList,
        };
        setQuestionConfirmLoading(true);
        seteditingQuestion(editedQuestion);

        showConfirmationModal();

        setQuestionVisible(false);
        setQuestionConfirmLoading(false);
    };

    function onCheckedOptions(e, fieldName) {
        let tempIndices = [...correctOptionsIndices];
        if (e.target.checked === true) tempIndices.push(fieldName);
        else {
            tempIndices = tempIndices.filter((i) => i !== fieldName);
        }
        setcorrectOptionsIndices(tempIndices);
    }
    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 20, offset: 4 },
        },
    };

    const columns = [
        {
            title: 'Question ID',
            dataIndex: 'id',
        },
        {
            title: 'Question Language',
            dataIndex: 'language',
            editable: true,
            filters: availableLanguages,
            onFilter: (value, record) => record.language.indexOf(value) === 0,
        },
        {
            title: 'Question Label',
            dataIndex: 'question',
        },
        {
            title: 'Difficulty',
            dataIndex: 'difficulty',
        },
        {
            title: 'Options',
            dataIndex: ['options', 'length'], // No usedIn variable from backend, thus using this options.length
            // sorter: {
            //     compare: (a, b) => a.options.length - b.options.length,
            //     multiple: 1,
            // },
            sorter: (a, b) => a.options.length - b.options.length,
            sortDirections: ['descend'],
        },
        {
            title: 'Added On',
            dataIndex: ['metaInfo', 'created', 'at'],
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                const { metaInfo } = record;
                return (
                    <span>
                        { moment(metaInfo.created.at).format('DD/MM/YYYY HH:mm') }
                    </span>
                );
            },
        },
        // {
        //     title: 'Added By',
        //     dataIndex: 'addedBy',
        //     // width: '10%',
        // },
        {
            title: 'Updated On',
            dataIndex: ['metaInfo', 'updated', 'at'],
            // sorter: {
            //     compare: (a, b) => a.metaInfo.updated.at - b.metaInfo.updated.at,
            //     multiple: 1,
            // },
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                const { metaInfo } = record;
                return (
                    <span>
                        { moment(metaInfo.updated.at).format('DD/MM/YYYY HH:mm') }
                    </span>
                );
            },
        },
        // {
        //     title: 'Updated By',
        //     dataIndex: ['metaInfo', 'updated', 'by', 'name'],
        // },
        {
            title: 'Edit',
            dataIndex: 'edit',
            // eslint-disable-next-line react/display-name
            render: (_, record) => (<Typography.Link
                    onClick={() => {
                        setselectedQuestion(record);
                    }}
                    >
                    <EditFilled style={{ color: 'tomato', fontSize: 20 }}/>
                </Typography.Link>),
        },
    ];
    useEffect(() => {
        if (selectedQuestion !== false) showQuestionModal();
    }, [selectedQuestion]);

    // SEARCH FILTER
    const [filter, setFilter] = useState({ filterTable: null });
    function search(value) {
        const filterTable = questionBank.filter((o) => Object.keys(o).some((k) => String(o[k])
            .toLowerCase().includes(value.toLowerCase())));
        setFilter({ filterTable });
    }

    function selectedQuestionOptions() {
        const options = [];
        if (selectedQuestion) {
            for (let index = 0; index < selectedQuestion.options.length; index += 1) {
                options.push(selectedQuestion.options[index].option);
            }
        }
        return options;
    }
    return (
        <div className='questionTableContainer'>
            <div className='tableSearchContainer'>
                <Input.Search
                    style={{
                        width: 300,
                    }}
                    placeholder="Search any field..."
                    enterButton
                    onChange={(e) => search(e.target.value)}
                />
            </div>
            <Form component={false}>
                <Table
                    bordered
                    showHeader={true}
                    className='questionBankTable'
                    dataSource={filter.filterTable === null ? questionBank : filter.filterTable}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={{ position: ['bottomLeft'] }}
                    rowKey='id'
                />
            </Form>

            {/* CONFIRMATION MODAL */}
            <Modal
                title="ARE YOU SURE?"
                visible={isConfirmationModalVisible}
                onCancel={handleCancel}
                footer={[]}
            >
                <div className='confirmationModalText'>
                    {
                        selectedQuestion.options
                        ? <div>
                            THIS QUESTION IS USED IN {selectedQuestion.options.length} VIDEOS.
                            UPDATING THIS WILL IMPACT THOSE VIDEOS.
                            ARE YOU SURE YOU WANT TO PROCEED?
                        </div>
                        : <div>
                            THIS QUESTION IS USED IN VARIOUS VIDEOS.
                            UPDATING THIS WILL IMPACT THOSE VIDEOS.
                            ARE YOU SURE YOU WANT TO PROCEED?
                        </div>
                    }
                </div>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <Button
                        htmlType="submit"
                        shape="round"
                        style={{ background: 'tomato', color: 'white' }}
                        onClick={handleOk}
                    >
                        YES
                    </Button>

                </div>
            </Modal>

            {/* EDIT QUESTION MODAL */}
            <Modal
                title="Edit Question"
                visible={questionVisible}
                confirmLoading={questionConfirmLoading}
                onCancel={questionHandleCancel}
                footer={[]}
                getContainer={false}
            >
                <Form
                    name="basic"
                    initialValues={{
                        language: selectedQuestion.language,
                        categoryId: selectedQuestion.categoryId,
                        question: selectedQuestion.question,
                        helpText: selectedQuestion.helpText,
                        optionFormat: selectedQuestion.optionFormat,
                        options: selectedQuestionOptions(),
                    }}
                    onFinish={onQuestionFinish}
                    onFinishFailed={onQuestionFinishFailed}
                    layout={'vertical'}
                >

                    <Form.Item name="language" label="Select Language"
                        rules={[{ required: true }]}
                    >
                        <Select
                            placeholder="Select a option and change input text above"
                            allowClear
                        >
                            {
                                availableLanguages.map((language, index) => <Option
                                    value={language.value}
                                    key={index}>{language.text}</Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="categoryId" label="Select Category"
                        rules={[{ required: true }]}
                    >
                        <Select
                            placeholder="Select a option and change input text above"
                            allowClear
                        >
                            {
                                availableCatagories.map((category, index) => <Option
                                    // eslint-disable-next-line no-underscore-dangle
                                    value={category._id}
                                    key={index}>{category.name}</Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="question" label="Question"
                        rules={[{
                            required: true,
                            message: 'Enter a new Question!',
                        }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item label="Help Text" name="helpText">
                        <Input />
                    </Form.Item>
                    <Form.Item name="optionFormat" label="Options Format"
                        rules={[{ required: true }]}
                    >
                        <Select
                            placeholder="Select a option and change input text above"
                            allowClear
                        >
                            <Option value="radio">Radio</Option>
                            <Option value="dropdown">Dropdown</Option>
                            <Option value="checkbox">Checkbox</Option>
                        </Select>
                    </Form.Item>

                    <Form.List
                        name="options"
                        rules={[
                        {
                            // eslint-disable-next-line consistent-return
                            validator: async (_, options) => {
                            if (!options || options.length < 2) {
                                return Promise.reject(new Error('At least 2 options'));
                            }
                            },
                        },
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    { ...formItemLayoutWithOutLabel }
                                    label={index === 0 ? 'Options ( Select the correct options )' : ''}
                                    required={false}
                                    key={field.key}
                                >
                                    <Checkbox
                                        defaultChecked={index < selectedQuestion.options.length
                                            && selectedQuestion.options[index].isTrue === 'true'}
                                        onChange={(e) => onCheckedOptions(e, field.name)}
                                        style={{ marginRight: 10 }}
                                    />
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: 'Please enter an option or delete this field.',
                                            },
                                        ]}
                                        noStyle
                                    >
                                        <Input
                                            placeholder="Option"
                                            style={{ width: '60%' }}
                                        />
                                    </Form.Item>
                                    {
                                        fields.length > 1
                                        ? (
                                            <Button
                                                className="dynamic-delete-button"
                                                style={{ background: 'tomato', color: 'white' }}
                                                onClick={() => remove(field.name)}
                                                icon={<MinusOutlined />}
                                                shape="circle"
                                            />
                                        ) : null
                                    }
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Row justify="end">
                                    <Button
                                        onClick={() => add()}
                                        style={{
                                            background: 'tomato',
                                            color: 'white',
                                            justifySelf: 'center',
                                            alignSelf: 'center',
                                        }}
                                        icon={<PlusOutlined />}
                                        shape="circle"
                                    />
                                    <Form.ErrorList errors={errors} />
                                </Row>
                            </Form.Item>
                        </>
                        )}
                    </Form.List>

                    <Form.Item>
                        <Row justify="end">
                            <Button
                                htmlType="submit"
                                shape="round"
                                style={{ background: 'tomato', color: 'white' }}
                            >
                                Save
                            </Button>
                        </Row>
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    );
}

export default QuestionTable;
