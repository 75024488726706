/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import './MomentsScreen.css';
import {
    Layout,
    Breadcrumb,
    Button,
    Collapse,
    Form,
    Modal,
    Input,
    Row,
    TimePicker,
    // Select,
} from 'antd';
import { LeftOutlined, EditFilled, DeleteFilled } from '@ant-design/icons';
// import moment from 'moment';
import axios from 'axios';
import {
    useHistory,
    useLocation,
} from 'react-router-dom';
import moment from 'moment';
// import moment from 'moment';

const AUTH_TOKEN = localStorage.getItem('token');

function MomentsScreen() {
    const history = useHistory();
    const location = useLocation();
    // const { Option } = Select;
    const { Header, Content } = Layout;
    const { Panel } = Collapse;
    // const [availableModules, setavailableModules] = useState([]);
    // const [availableTopics, setavailableTopics] = useState([]);
    // const [availableConcepts, setavailableConcepts] = useState([]);
    const [moments, setmoments] = useState(null);
    const [deleteMoment, setDeleteMoment] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);

    // async function fetchModules() {
    //     const url = `${process.env.REACT_APP_VIDEO_REPOSITORY_URL}/module`;
    //     const config = { headers: { Authorization: AUTH_TOKEN } };
    //     axios.get(url, config).then((response) => {
    //         if (response.status === 200 && response.data.error === false) {
    //             setavailableModules(response.data.modules);
    //         }
    //     }).catch((err) => {
    //         Modal.error({ content: err.response.data.errorMessage });
    //     });
    // }

    async function fetchMoments() {
        const { _id } = location.state.file;
        const url = `${process.env.REACT_APP_LIBRARY_URL}/moment/getmoment/${_id}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setmoments(response.data.videoMoments);
            }
        }).catch((err) => {
            // console.log(err);
            Modal.error({ content: err.response.data.errorMessage });
        });
    }

    useEffect(() => {
        // fetchModules();
        fetchMoments();
    }, []);

    // // console.log('STATE', location.state.file);

    // Dummy data for testing
    // const moments = [
    //     {
    //         _id: '60fa8d1184a95aca11b74014',
    //         moment: {
    //             _id: '60fa8d41ff413aca6f2c5003',
    //             timestamp: moment('01/01/01 00:02:25').format('HH:mm:ss'),
    //             description: 'Moment Description1',
    //             transcript: 'Moment Transcript1',
    //             moduleId: 'new module 2',
    //             topicId: 'new topic 2',
    //             conceptId: 'new concept 2',
    //             fileId: '60fa8d1184a95aca11b74014',
    //             __v: 0,
    //         },
    //     },
    //     {
    //         _id: '60fa8d1184a95aca11b74014',
    //         moment: {
    //             _id: '60fa8d41ff413aca6f2c5003',
    //             moduleId: '60fa7943a2da48a43c806d55',
    //             topicId: '60fa7943a2da48a43c806d55',
    //             conceptId: '60fa7943a2da48a43c806d55',
    //             timestamp: '00:10:15',
    //             description: 'test',
    //             transcript: 'test transcript',
    //             fileId: '60fa8d1184a95aca11b74014',
    //             __v: 0,
    //         },
    //     },
    //     {
    //         _id: '60fa8d1184a95aca11b74014',
    //         moment: {
    //             _id: '60fa9001d0b7d6d05e29b08b',
    //             moduleId: '60fa7943a2da48a43c806d55',
    //             topicId: '60fa7943a2da48a43c806d55',
    //             conceptId: '60fa7943a2da48a43c806d55',
    //             timestamp: '00:10:20',
    //             description: 'test2',
    //             transcript: 'updated transcript3',
    //             fileId: '60fa8d1184a95aca11b74014',
    //             __v: 0,
    //         },
    //     },
    // ];

    // --------------------------- ADD / EDIT MOMENT MODAL ---------------------------
    const [selectedRow, setselectedRow] = useState(false);
    const [momentVisible, setmomentVisible] = useState(false);
    const [momentConfirmLoading, setmomentConfirmLoading] = useState(false);

    const showMomentModal = (momentItem) => {
        setmomentVisible(true);
        setselectedRow(momentItem);
    };
    const momentHandleCancel = () => setmomentVisible(false);

    const onMomentFinishFailed = (errorInfo) => Modal.error({ content: errorInfo });
    const onMomentFinish = (values) => {
        setmomentConfirmLoading(true);
        if (selectedRow) {
            const { _id } = selectedRow; // gtting moment id to update that moment
            const url = `${process.env.REACT_APP_LIBRARY_URL}/moment/${_id}`;
            const config = { headers: { Authorization: AUTH_TOKEN } };
            const newMoment = {
                timestamp: moment(values.timestamp).format('HH:mm:ss'),
                description: values.description,
                transcript: values.transcript,
            };
            axios.put(url, newMoment, config).then((response) => {
                if (response.status === 200 && response.data.error === false) {
                    setmomentVisible(false);
                    setmomentConfirmLoading(false);
                    fetchMoments();
                    Modal.success({ content: `${response.data.message}` });
                }
            }).catch((err) => {
                Modal.error({ content: err.response.data.errorMessage });
            });
        } else {
            const { _id } = location.state.file; // Getting video id to add moment to that video
            const url = `${process.env.REACT_APP_LIBRARY_URL}/moment/addmoment/${_id}`;
            const config = { headers: { Authorization: AUTH_TOKEN } };
            const newMoment = {
                timestamp: moment(values.timestamp).format('HH:mm:ss'),
                description: values.description,
                transcript: values.transcript,
            };
            axios.post(url, newMoment, config).then((response) => {
                if (response.status === 200 && response.data.error === false) {
                    setmomentVisible(false);
                    setmomentConfirmLoading(false);
                    fetchMoments();
                    Modal.success({ content: `${response.data.message}` });
                } else {
                    Modal.error({ content: 'Moment Not Added, Please Try Again' });
                }
            }).catch((err) => {
                Modal.error({ content: err.response.data.errorMessage });
            });
        }
    };

    const deleteMomentItem = () => {
        const { _id } = deleteItem; // gtting moment id to delete that moment
        const url = `${process.env.REACT_APP_LIBRARY_URL}/moment/${_id}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.delete(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setmomentVisible(false);
                setmomentConfirmLoading(false);
                fetchMoments();
                setDeleteMoment(false);
                Modal.success({ content: `${response.data.message}` });
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    };
    // function onChangeDropdown(e, field) {
    //     if (field === 'moduleChange') {
    //         for (let index = 0; index < availableModules.length; index += 1) {
    //             // eslint-disable-next-line no-underscore-dangle
    //             if (availableModules[index]._id === e) {
    //                 setavailableTopics(availableModules[index].topics);
    //             }
    //         }
    //     } else if (field === 'topicChange') {
    //         for (let index = 0; index < availableTopics.length; index += 1) {
    //             // eslint-disable-next-line no-underscore-dangle
    //             if (availableTopics[index]._id === e) {
    //                 setavailableConcepts(availableTopics[index].concepts);
    //             }
    //         }
    //     }
    // }
    return (
        <div>
            <Header
                style={{
                    padding: 0,
                    textAlign: 'center',
                    fontSize: 30,
                    fontWeight: 300,
                    background: 'white',
                }} >
                {'Video Moments'}
            </Header>
            <Content style={{
                margin: '24px 16px 0',
                overflow: 'initial',
                background: '#fff',
                borderRadius: 10,
                boxShadow: '2px 1px 10px lightgrey',
            }}>
                <div className='topicsScreenContainer'>
                    <div className='topicsScreenTop'>
                        <div className='topicsScreenTopBreadcrumbContainer'>
                            <LeftOutlined
                                onClick={() => history.goBack()}
                                style={{ paddingRight: 10 }}
                            />
                            <Breadcrumb>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item
                                    onClick={() => history.go(-4)}
                                >
                                    <a>Modules</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    onClick={() => history.go(-3)}
                                >
                                    <a>Topics</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    onClick={() => history.go(-2)}
                                >
                                    <a>Concepts</a>
                                </Breadcrumb.Item>
                                {/* <Breadcrumb.Item>Videos</Breadcrumb.Item> */}
                                <Breadcrumb.Item
                                    onClick={() => history.go(-1)}
                                >
                                    <a>Videos</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Moments</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Button
                            onClick={() => showMomentModal(false)}
                            shape="round"
                            style={{ background: 'tomato', color: 'white' }}
                        >
                            Add Moment
                        </Button>
                    </div>
                    {moments && <div className='topicsListContainer'>
                        <Collapse
                            defaultActiveKey={['0']}
                            expandIconPosition={'right'}
                        >
                            {
                                moments.map((momentItem, index) => <Panel
                                    header={
                                        <div className='momentValueText'>
                                            {`${momentItem.timestamp} : ${momentItem.description}`}
                                        </div>
                                    }
                                    key={index}
                                >
                                    <div className='momentContainer'>
                                        <div className='momentContainerTop'>
                                            <div className='momentKeyValue'>
                                                <div className='momentKeyText'>Transcript:</div>
                                                <div className='momentValueText'>{momentItem.transcript}</div>
                                            </div>
                                            <div>
                                                <Button
                                                    onClick={() => showMomentModal(momentItem)}
                                                    shape="round"
                                                    style={{ background: 'tomato', color: 'white' }}
                                                    icon={<EditFilled />}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setDeleteItem(momentItem);
                                                        setDeleteMoment(true);
                                                    }}
                                                    shape="round"
                                                    style={{ background: 'tomato', color: 'white', marginLeft: 10 }}
                                                    icon={<DeleteFilled />}
                                                >
                                                    Delete
                                                </Button>
                                            </div>
                                        </div>
                                        {/* <div className='momentContainerBottom'>
                                            <div className='momentKeyValue'>
                                                <div className='momentKeyText'>Module:</div>
                                                <div className='momentValueText'>
                                                {momentItem.moduleId}</div>
                                            </div>
                                            <div className='momentKeyValue'>
                                                <div className='momentKeyText'>Topic:</div>
                                                <div className='momentValueText'>
                                                {momentItem.topicId}</div>
                                            </div>
                                            <div className='momentKeyValue'>
                                                <div className='momentKeyText'>Concept:</div>
                                                <div className='momentValueText'>
                                                {momentItem.conceptId}</div>
                                            </div>
                                        </div> */}
                                    </div>
                                </Panel>)
                            }
                        </Collapse>
                    </div>}
                </div>
                <Modal
                    title={selectedRow ? 'Edit Moment' : 'Add Moment'}
                    visible={momentVisible}
                    confirmLoading={momentConfirmLoading}
                    onCancel={momentHandleCancel}
                    footer={[]}
                    getContainer={false} // amazing
                >
                    <Form
                        name="basic"
                        initialValues={selectedRow
                            ? {
                                description: selectedRow.description,
                                transcript: selectedRow.transcript,

                            }
                            : null
                        }
                        onFinish={onMomentFinish}
                        onFinishFailed={onMomentFinishFailed}
                        layout={'vertical'}
                    >
                        {/* <Form.Item
                            label="Moment Timestamp ( HH:mm:ss )"
                            name="timestamp"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter a new timestamp!',
                                },
                                () => ({
                                    validator(_, value) {
                                    // let correctFormat = false;
                                    const correctFormat = true;
                                    // Write an algo for correct format.
                                    if (!value || correctFormat) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject
                                    (new Error('Timestamp Format not correct!'));
                                    },
                                }),
                            ]}
                        >
                            <Input />
                        </Form.Item> */}
                        <Form.Item
                            label="Moment Timestamp ( HH:mm:ss )"
                            name="timestamp"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter a new timestamp!',
                                },
                                () => ({
                                    validator(_, value) {
                                        // let correctFormat = false;
                                        const correctFormat = true;
                                        // Write an algo for correct format.
                                        if (!value || correctFormat) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Timestamp Format not correct!'));
                                    },
                                }),
                            ]}
                        >
                            <TimePicker style={{ width: '100%' }} defaultValue={moment('00:00:00', 'HH:mm:ss')} size="large" />
                        </Form.Item>
                        <Form.Item
                            label="Moment Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter a new description!',
                                },
                            ]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item
                            label="Moment Transcript"
                            name="transcript"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter a new transcript!',
                                },
                            ]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                        {/* <Form.Item
                            label="Select Moment Module"
                            name="moduleId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select a new Module!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select a option and change input text above"
                                allowClear
                                onChange={(e) => onChangeDropdown(e, 'moduleChange')}
                            >
                                {
                                    availableModules.map((module, index) => <Option
                                        // eslint-disable-next-line no-underscore-dangle
                                        value={module._id}
                                        key={index}>{module.name.english}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Select Moment Topic"
                            name="topicId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select a new Topic!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select a option and change input text above"
                                allowClear
                                onChange={(e) => onChangeDropdown(e, 'topicChange')}
                            >
                                {
                                    availableTopics.map((topic, index) => <Option
                                        // eslint-disable-next-line no-underscore-dangle
                                        value={topic._id}
                                        key={index}>{topic.name.english}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Select Moment Concept"
                            name="conceptId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select a new Concept!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select a option and change input text above"
                                allowClear
                                onChange={(e) => onChangeDropdown(e, 'conceptChange')}
                            >
                                {
                                    availableConcepts.map((concept, index) => <Option
                                        // eslint-disable-next-line no-underscore-dangle
                                        value={concept._id}
                                        key={index}>{concept.name.english}</Option>)
                                }
                            </Select>
                        </Form.Item> */}
                        <Form.Item>
                            <Row justify="end">
                                <Button
                                    htmlType="submit"
                                    shape="round"
                                    style={{ background: 'tomato', color: 'white' }}
                                >
                                    Submit
                                </Button>
                            </Row>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={'Delete Moment'}
                    visible={deleteMoment}
                    confirmLoading={momentConfirmLoading}
                    onCancel={() => setDeleteMoment(false)}
                    footer={[]}
                    getContainer={false} // amazing
                >
                    {deleteItem && <div>
                        <p style={{ fontSize: 18, alignSelf: 'center' }}>Sure want to Delete {deleteItem.transcript}?</p>
                        <Row justify="space-around">
                            <Button
                                // htmlType="submit"
                                onClick={() => setDeleteMoment(false)}
                                shape="round"
                                style={{ background: 'green', color: 'white', width: 100 }}
                            >
                                No
                            </Button>
                            <Button
                                // htmlType="submit"
                                onClick={() => deleteMomentItem()}
                                shape="round"
                                style={{ background: 'red', color: 'white', width: 100 }}
                            >
                                Yes
                            </Button>
                        </Row>
                    </div>}
                </Modal>
            </Content>
        </div>
    );
}

export default MomentsScreen;
