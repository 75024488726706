/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, {
    useState,
    useEffect,
} from 'react';
import {
    Table,
    Form,
    Typography,
    Modal,
    Button,
    Select,
    Tooltip,
    Upload,
    Row,
    Col,Input
} from 'antd';
import './TableHeader.css';
import axios from 'axios';
import moment from 'moment';
import {
    DeleteFilled,EditFilled
} from '@ant-design/icons';
import availableLanguages from '../../Components/AvailableLanguages/AvailableLanguages';
  import Swal from 'sweetalert2';
  import { UploadOutlined } from '@ant-design/icons';
  import FormData from 'form-data';
  import JsonToFormdata from 'json-form-data';

const AUTH_TOKEN = localStorage.getItem('token');
const { Option } = Select;

function QuestionTable({fetchBusinessIdeas,businessIdeas}) {
    const [quotes, setQuotes] = useState([]);
    const [groupsKeywords, setGroupsKeywords] = useState([]);
    const [fileForDeletion, setFileForDeletion] = useState(null);
    const [confirmDeletion, setConfirmDeletion] = useState(false);
    const [fileForEdit, setFileForEdit] = useState(null);
    const [editModal,setEditModal] = useState(false);
    const [fileConfirmLoading, setfileConfirmLoading] = useState(false);
    const [quoteLangauge,setQuoteLangauge] = useState(null); 
    const [quoteText,setQuoteText] = useState(null);
    const [filter, setFilter] = useState({ filterTable: null });

     
    // const [availableCatagories, setavailableCatagories] = useState([]);
    async function fetchQuotes() {
        const url = `${process.env.REACT_APP_LIBRARY_URL}/quotes`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                console.log("response.data.quote",response.data.quote)
                setQuotes(response.data.quote);
            }
        });
    }
    async function fetchKeywords() {
        const url = `${process.env.REACT_APP_LIBRARY_URL}/synonyms/keywords`;
        axios.get(url).then((response) => {
          if (response.status) {
            setGroupsKeywords(response.data.keywords);
          }
        });
      }
      const deleteFile = (row) => {
        const { _id } = row;
        const url = `${process.env.REACT_APP_LIBRARY_URL}/quotes/${_id}/${row?.quotes?._id}`;
        const config = { headers: { Authorization: AUTH_TOKEN, 'x-access-token': localStorage.getItem('token') } };

        axios.delete(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                // fetchLibrary();
                fetchQuotes();
                setConfirmDeletion(false);
                Modal.success({ content: `${response.data.message}` });
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
    };
    // SEARCH FILTER
    function search(value) {
        const filterTable = quotes.filter((o) => Object.keys(o).some((k) => String(o[k])
            .toLowerCase().includes(value.toLowerCase())));
        setFilter({ filterTable });
    }


    
    useEffect(() => {
        fetchQuotes();
        fetchKeywords();
    }, []);

    // ------------------------- CONFIRMATION MODAL -------------------------
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
    const [selectedQuotes, setselectedQuotes] = useState(false);
    const [editingQuestion, seteditingQuestion] = useState({});
    const showConfirmationModal = () => setIsConfirmationModalVisible(true);
    const handleOk = () => {
        setIsConfirmationModalVisible(false);

        const { _id } = selectedQuotes;
        const url = `${process.env.REACT_APP_QUESTION_URL}/questions/${_id}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.put(url, editingQuestion, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                Modal.success({ content: `${response.data.message}` });
                fetchQuestions();
            }
        }).catch((err) => {
            Modal.error({ content: err.response.data.errorMessage });
        });
        setselectedQuotes(false);
    };
    const handleCancel = () => {
        setIsConfirmationModalVisible(false);
        setselectedQuotes(false);
    };
    const quotesProps = {
        accept: 'image/*',
        beforeUpload: () => false,
        listType: 'picture',
      };
      async function fetchKeywords() {
        const url = `${process.env.REACT_APP_LIBRARY_URL}/synonyms/keywords`;
        axios.get(url).then((response) => {
          if (response.status) {
            setGroupsKeywords(response.data.keywords);
          }
        });
      }
      useEffect(() => {
        fetchKeywords();
      }, []);
    
      const onFinish = (values) => {
        setfileConfirmLoading(true);
        const {_id} = fileForEdit
    
        const url = `${process.env.REACT_APP_LIBRARY_URL}/quotes/${_id}/${fileForEdit?.quotes?._id}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        let quoteToSend = {};

           if(values.keywords){
            quoteToSend.keywords = values.keywords
           }
           if(quoteLangauge){
                quoteToSend.language= quoteLangauge
           } 
           if(quoteText){
            quoteToSend.text = quoteText
           }
           if(values.businessIdea){
            quoteToSend.businessIdeas = values.businessIdea
           }


        if (values.backgroundImage && values.backgroundImage.file) {
          quoteToSend.backgroundImage = values.backgroundImage.file;
        } else quoteToSend.backgroundImage = fileForEdit?.backgroundImage;
    
        const fileForm = JsonToFormdata(quoteToSend);
    
        axios.put(url, fileForm, config).then((response) => {
          if (response) {
            Modal.success({ content: `${response.data.message}` });
            // window.location.reload();
            fetchQuotes();
            setfileConfirmLoading(false);
            setFileForEdit(null);
            setQuoteLangauge(null);
            setQuoteText(null);
            setEditModal(false);
          } else {
            Modal.error({ content: 'Tip Not Edited, Please Try Again' });
          }
        }).catch((err) => {
          console.log(err);
          Modal.error({ content: 'Error while Edit, Please Try Again' });
        });
    
        
    
      };

 // eslint-disable-next-line no-trailing-spaces
 
    const columns = [
        {
            title: 'Tip ID',
            dataIndex: '_id',
            align: 'center',
        },
        {
            title: 'Language',
            dataIndex: ['quotes', 'language'],
            align: 'center',
             editable: true,
            filters: availableLanguages,
            render: (_, record) => {
                const {language} = record.quotes

            return <span style={{ textTransform: 'capitalize' }}>{language}</span>
        },
            onFilter: (value, record) => {
                const {language} = record.quotes
                return   language.indexOf(value).toUpperCase() === 0
            },
        },
        {
            title: 'Tip',
            dataIndex: ['quotes', 'language'],
            align: 'center',
            ellipsis: true,
            render: (_, record) => {
              
                const {text} = record.quotes
            
               return <Typography.Link >
                 <Tooltip placement="topLeft" title={text}>
                 <a href={record.backgroundImage} target='_blank' rel="noopener noreferrer"><span>{text}</span> </a>
                 </Tooltip>
                </Typography.Link>
            },
        },
        {
            title: 'Added By',
            dataIndex: ['metaInfo', 'created', 'by'],
            align: 'center',
            render: (_, record) => {
                const { metaInfo } = record;
                return (
                     <span>{metaInfo.created.by.name}</span>
                );
            },
         },
        {
            title: 'Added On',
            dataIndex: ['metaInfo', 'created', 'at'],
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                const { metaInfo } = record;
                return (
                    <span>
                        { moment(metaInfo.created.at).format('DD/MM/YYYY HH:mm') }
                    </span>
                );
            },
        },
        {
            title: 'Updated On',
            dataIndex: ['metaInfo', 'updated', 'at'],
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                const { metaInfo } = record;
                return (
                    <span>
                        { moment(metaInfo.updated.at).format('DD/MM/YYYY HH:mm') }
                    </span>
                );
            },
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            align: 'center',
              // eslint-disable-next-line react/display-name
            render: (_, record) => (
                <EditFilled
                    style={{ color: 'tomato', fontSize: 20 }}
                    onClick={() => {setFileForEdit(record),setEditModal(true),setQuoteLangauge(record?.quotes?.language),setQuoteText(record?.quotes?.text)}}
                />
            ),
        },
         {
            title: 'Delete',
            dataIndex: 'delete',
            align: 'center',
              // eslint-disable-next-line react/display-name
            render: (_, record) => (
                <Typography.Link
                onClick={() => { setFileForDeletion(record); setConfirmDeletion(true); }} >
                <DeleteFilled
                    style={{ color: 'tomato', fontSize: 20 }}
                   />
                   </Typography.Link>
            ),
        },
        
    ];
    useEffect(() => {
        if (selectedQuotes !== false) showQuestionModal();
    }, [selectedQuotes]);

    
   return (
        <div className='questionTableContainer'>
            <div className='tableSearchContainer'>
                <Input.Search
                    style={{
                        width: 300,
                    }}
                    placeholder="Search any field..."
                    enterButton
                    onChange={(e) => search(e.target.value)}
                />
            </div>

            <Form component={false}>
                <Table
                    bordered
                    showHeader={true}
                    className='questionBankTable'
                    dataSource={filter.filterTable === null ? quotes : filter.filterTable}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={{ position: ['bottomLeft'], pageSize: ['500'] } }
                    rowKey='id'
                />
            </Form>

            {/* CONFIRMATION MODAL */}
            <Modal
                title="ARE YOU SURE?"
                visible={isConfirmationModalVisible}
                onCancel={handleCancel}
                footer={[]}
            >
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <Button
                        htmlType="submit"
                        shape="round"
                        style={{ background: 'tomato', color: 'white' }}
                        onClick={handleOk}
                    >
                        YES
                    </Button>

                </div>
            </Modal>

            <Modal
                visible={confirmDeletion}
                onCancel={() => setConfirmDeletion(false)}
                onOk={() => deleteFile(fileForDeletion)}
                width={'40%'}
                style={{ marginTop: 150 }}
                getContainer={false}
            >
                <p> Sure want to delete
                    <strong>{' '}{fileForDeletion && fileForDeletion?.quotes?._id}</strong> ?
                </p>
            </Modal>
           

           {/* Edit Tips */}

           <Modal
        title="Edit Tips"
        visible={editModal}
        onCancel={() =>  setEditModal(false)}
        width="60%"
        confirmLoading={fileConfirmLoading}
        footer={[]}
        getContainer={false}
      >
        <Form
          name="basic-quote"
          onFinish={onFinish}
          layout={'vertical'}
          initialValues={fileForEdit}

        >
          <div className="test" style={{ padding: 10 }}>
            <div>
              {/* <Form.Item label="Tips Thumbnail (Background Image - 800 x 800)" name="backgroundImage">
            
                <Upload listType="picture-card" {...quotesProps}>
                  <Button icon={<UploadOutlined />}>Edit Image</Button>
                </Upload>
              </Form.Item> */}
              {fileForEdit?.backgroundImage || '' ? <Form.Item label="Thumbnail">
                        <Row> <Col > 
                        <img width={300} height={200} src={fileForEdit?.backgroundImage || ''} />
                        </Col> <Button 
                          style={{marginLeft:10,marginTop:170}}
                        
                        onClick={() => {
                            Swal.fire({
                                title: 'Are you sure you want to remove background Image for this tips?',
                                focusConfirm: false,
                                showCancelButton: true,
                                cancelButtonText: 'No, Cancel',
                                confirmButtonText: 'Yes, Delete',
                                cancelButtonAriaLabel: 'No, Cancel',
                                confirmButtonAriaLabel: 'Yes, Delete',
                            }).then((result) => {
                                if (result.value) {
                                    setFileForEdit({ ...fileForEdit, backgroundImage: '' });
                                }
                            });
                        }}><i className="fa fa-trash" aria-hidden="true" /> Remove background image
                        </Button></Row>
                    </Form.Item> : <Form.Item
                       label="Tips Thumbnail (Background Image - 800 x 800)" name="backgroundImage" >
                         <Upload listType="picture-card" {...quotesProps}>
                  <Button icon={<UploadOutlined />}>Edit Image</Button>
                </Upload>
                    </Form.Item>}
            </div>
            <div>
              <Form.Item
                name="keywords"
                label="Group(s)"
                rules={[{ required: true, message: 'Add Groups' }]}
              >
                <Select mode='multiple' placeholder="Select one or more Groups" allowClear>
                  {groupsKeywords.map((word) => (
                    // eslint-disable-next-line no-underscore-dangle
                    <Option key={word._id} value={word._id}>
                      {word.keyword}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="businessIdea" label="Business Idea(s)"
              rules={[{ required: true, message: 'Add business Ideas' }]}
              >
                              <Select
                                  mode="multiple"
                                  placeholder="Select one or more business ideas that apply"
                                  allowClear
                                  showSearch
                                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                  onSearch={(searchText) => {
                                      console.log('🚀 ~ file: LibraryTable.js:564 ~ searchText:', searchText);
                                  }}
                              >
                                  {businessIdeas.map((idea) => (
                                      <Option key={idea.combine} label={idea._inEnglish} 
                                          title={idea._inEnglish}
                                          value={idea._id}>{idea.inEnglish}</Option>
                                  ))}
                              </Select>
                          </Form.Item>
            </div>
            {/* <div ></div> */}
            <Form.Item name="quotes" label="Daily Tip(s)"
            //  rules={[{ required: true, message: 'Add daily tips' }]}
             >
            
            <Row style={{marginTop:5}}>
                  <Col span={4}>
                    <Select value={fileForEdit?.quotes?.language} style={{ width: '100%' }} onChange={(value) => {
                      const updatedObject = { ...fileForEdit, quotes: { ...fileForEdit.quotes,language:value} };
                      setFileForEdit(updatedObject)
                      setQuoteLangauge(value);

                    }} placeholder="Select language"
                    >
                      {availableLanguages.filter(lang => !fileForEdit?.quotes?.language.includes(lang.value)).map((language, index) => {
                          return   <Option value={language.value} key={index}>
                            {language.text}
                          </Option>
                      }
                      )}
                    </Select>
                  </Col>
                  <Col offset={1} />
                  <Col span={14}>
                    <Input value={fileForEdit?.quotes?.text} placeholder='Enter the tip here' onChange={(event) => {
                        const updatedObject = { ...fileForEdit, quotes: { ...fileForEdit.quotes,text:event.target.value} };
                        setFileForEdit(updatedObject)
                        setQuoteText(event.target.value)
                    
                    }} />
                  </Col>
                </Row>
            <div className="mt-4 align-right">
              <Button
                disabled={!(quoteLangauge && quoteText)}
                // onClick={() => handleSubmit(keyword, synonyms)}
                shape="round"
                htmlType="submit"
                style={{
                  background: !(quoteLangauge && quoteText) ? 'grey' : 'tomato',
                  cursor: !(quoteLangauge && quoteText) ? 'not-allowed' : 'pointer',
                  color: 'white',
                  alignSelf: 'flex-end',
                }}>
                Edit
              </Button>
            </div>
            </Form.Item>
          </div>
        </Form>
      </Modal>

        </div>
    );
}

export default QuestionTable;
