/* eslint-disable no-undef */
import React from 'react';
import {
    Col,
    Row,
    Modal,
} from 'react-bootstrap';

import {
    Form, Input,
    // Select,
    Button, message, Switch,
} from 'antd';
import axios from 'axios';

// const { Option } = Select;
const token = localStorage.getItem('token');

function AdminForm(props) {
    const {
        showAddModal,
        showUpdateModal,
        user,
        setUser,
        // handleSubmit,
        getUsers,
        setShowModal,
        INITIAL_USER_TO_ADD,
    } = props;
    // if (!user.status) delete user.status;
    // if (!user.role) delete user.role;
    const handleAdd = (values) => {
        // e.preventDefault();
        const {
            name,
            email,
            phoneNo,
        } = values;

        let {
            role = 'Admin',
            status = 'Inactive',
        } = user;
        if (!role) role = 'Admin';
        if (!status) status = 'Inactive';
        const userToAdd = {
            name,
            email,
            phoneNo,
            role,
            // status,
            status,
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/register`, userToAdd, {
                headers: {
                    Authorization: token,
                },
            })
            .then((data) => {
                // setShowUpdateModal(false);
                if (data.data.status) {
                    getUsers();
                    // setAlertMessage('User has been Updated');
                    message.info('User updated successfully!');
                    setShowModal(false);
                    setUser(INITIAL_USER_TO_ADD);
                    // setShowAlert(true);
                    // setTimeout(() => {
                    //     setShowAlert(false);
                    // }, 2000);
                } else {
                    message.error(data.data.message || 'Something went wrong. Please try again!');
                }
            })
            .catch((error) => {
                message.error('Something went wrong. Please try again!:', error);
                // console.log(error);
            });
    };

    const handleUpdate = (values) => {
        // e.preventDefault();
        const {
            name,
            email,
            phoneNo,
        } = values;

        const {
            id,
            role,
            status,
        } = user;
        // const {  } = user;

        const userToUpdate = {
            id,
            name,
            email,
            phoneNo,
            role,
            status,
            // status: status ? 'Active' : 'Inactive',
        };
        axios
            .put(`${process.env.REACT_APP_API_URL}/update`, userToUpdate, {
                headers: {
                    Authorization: token,
                },
            })
            .then((data) => {
                // setShowUpdateModal(false);
                if (data.data.status) {
                    getUsers();
                    // setAlertMessage('User has been Updated');
                    message.info('User updated successfully!');
                    setShowModal(false);
                    setUser(INITIAL_USER_TO_ADD);
                    // setShowAlert(true);
                    // setTimeout(() => {
                    //     setShowAlert(false);
                    // }, 2000);
                } else {
                    message.error('Something went wrong. Please try again!');
                }
            })
            .catch((error) => {
                message.error('Something went wrong. Please try again!:', error);
                // console.log(error);
            });
    };

    const onFileFinish = (values) => {
        if (showAddModal) handleAdd(values);
        else { handleUpdate(values); }
    };

    return (<>
        <Modal show={showAddModal || showUpdateModal}>
            <Modal.Header>
                {/* {`Please ${showAddModal ? 'Add' : 'Edit'
                    } the details of the user to be ${showAddModal ? 'added' : 'updated'
                    } here`} */}
                {
                    `${showAddModal ? 'Add user' : 'Update user'}`
                }
            </Modal.Header>
            <Modal.Body>
                <Form
                    name="basic"
                    onFinish={onFileFinish}
                    initialValues={user}
                    // onFinishFailed={onFileFinishFailed}
                    layout={'vertical'}
                >
                    <Form.Item name="name" label="Full Name"
                        rules={[
                            {
                                required: true,
                                message: 'Enter name!',
                            },
                        ]}
                    >
                        <Input placeholder='Enter full name'/>
                    </Form.Item>
                    <Form.Item name="email" label="Email"
                        rules={[
                            {
                                required: true,
                                message: 'Enter valid email!',
                                // eslint-disable-next-line no-useless-escape
                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            },
                        ]}
                    >
                        <Input placeholder='Enter email. Format: abc@xyz.com'/>
                    </Form.Item>
                    <Form.Item name="phoneNo" label="Phone No."
                        rules={[
                            {
                                required: true,
                                message: 'Enter valid Phone No.!',
                                // eslint-disable-next-line no-useless-escape
                                pattern: /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/,
                            },
                        ]}
                    >
                        <Input placeholder='Enter phone number'/>
                    </Form.Item>
                    <Row>
                        <Col>
                    <Form.Item name="status" label="Status"
                        rules={[
                            // {
                            //     required: true,
                            //     message: 'Select a status',
                            // },
                        ]}
                    >
                        {/* <Select
                            placeholder="Select business level"
                            allowClear
                        >
                            {[1, 2, 3, 4, 5, 6, 7].map((level) => (
                                <Option key={level} label={level}
                                    title={level}
                                    value={level}>{`Level - ${level}`}</Option>
                            ))}
                        </Select> */}
                                <Switch defaultChecked={user.status === 'Active'}
                                    checkedChildren="Active" unCheckedChildren="Inactive"
                                    onChange={(e) => { setUser({ ...user, status: e ? 'Active' : 'Inactive' }); }} />
                            </Form.Item></Col><Col>
                    <Form.Item name="role" label="Role"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Select a role',
                        //     },
                        // ]}
                    >
                                <Row>
                                    {/* <Col>Admin</Col> */}
                                    <Col> <Switch defaultChecked={user.role === 'Super Admin'}
                            checkedChildren="Super Admin" unCheckedChildren="Admin"
                                        onChange={(e) => { setUser({ ...user, role: e ? 'Super Admin' : 'Admin' }); }} /></Col>
                                    {/* <Col>Super Admin</Col> */}
                                </Row></Form.Item></Col>
                    </Row>
                    <Form.Item>
                        <Row justify="end" style={{ justifyContent: 'flex-end' }}>
                            <Col>
                                <Button
                                    htmlType="submit"
                                    shape="round"
                                    style={{
                                        background: 'tomato',
                                        color: 'white',
                                        height: 30,
                                        width: 140,
                                        fontSize: 14,
                                    }}
                                >
                                    {`${(showAddModal && 'Add User')
                                        || (showUpdateModal && 'Update User')
                                        }`}
                                </Button></Col> <Col>  <Button variant="secondary" shape="round" style={{
                                // background: 'tomato',
                                // color: 'white',
                                height: 30,
                                width: 140,
                                fontSize: 14,
                            }} onClick={() => setShowModal(false)}>
                                Close
                            </Button></Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal.Body>
        </Modal>

        {/* <Form className="fill-form" onSubmit={handleSubmit}> */}
        {/* id, name, email, phoneNo, role, status */}
        {/* <Form.Row> */}
        {/* <Form.Group as={Col} md={12}>
                            <Form.Label className="select-address-label">Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter Name"
                                onChange={(e) => setUser({
                                    ...user,
                                    name: e.target.value,
                                })
                                }
                                value={user.name}
                                size="sm"
                                reauired
                            ></Form.Control>
                        </Form.Group> */}

        {/* <Form.Group as={Col} md={12}>
                            <Form.Label className="Edit Email">Email</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                placeholder="userabc@gmail.com"
                                onChange={(e) => setUser({
                                    ...user,
                                    email: e.target.value,
                                })
                                }
                                value={user.email}
                                size="sm"
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md={12}>
                            <Form.Label>Phone No.</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter Phone Number"
                                onChange={(e) => setUser({
                                    ...user,
                                    phoneNo: e.target.value,
                                })
                                }
                                value={user.phoneNo}
                                size="sm"
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} md={6}>
                            <Form.Label className="Edit Email">Status</Form.Label>
                            <Form.Check
                                type="radio"
                                label="Active"
                                name="formHorizontalRadios"
                                id="Active"
                                value={user.status}
                                onClick={(e) => setUser({
                                    ...user,
                                    status: e.target.id,
                                })
                                }
                            />
                            <Form.Check
                                type="radio"
                                label="Inactive"
                                name="formHorizontalRadios"
                                id="Inactive"
                                value={user.status}
                                onClick={(e) => {
                                    setUser({
                                        ...user,
                                        status: e.target.value,
                                    });
                                }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                            <Form.Control
                                required
                                as="select"
                                className="my-1 mr-sm-2"
                                id="inlineFormCustomSelectPref"
                                value={user.role}
                                onChange={(e) => setUser({
                                    ...user,
                                    role: e.target.value,
                                })
                                }
                            >
                                <option value="" disabled>
                                    Select Role
                                </option>
                                <option value="User">User</option>
                                <option value="Admin">Admin</option>
                                <option value="Super Admin">Super Admin</option>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md={2}>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Button
                                className="default-btn"
                                type="submit"
                                onSubmit={handleSubmit}
                            >
                                {`${(showAddModal && 'Add User')
                                    || (showUpdateModal && 'Update User')
                                    }`}
                            </Button>
                        </Form.Group>
                    </Form.Row>
                </Form> */}
    </>);
}
export default AdminForm;
