/* eslint-disable no-undef */
import { React, useState, useEffect } from 'react';
import { Button } from 'antd';
import axios from 'axios';
import { Content } from 'antd/lib/layout/layout';
import MentorDetailsModal from './MentorDetailsModal';
import MentorDetailsTableHeader from './MentorDetailsTableHeader';

// const AUTH_TOKEN = localStorage.getItem('token');

function MentorshipEventScreen() {
    const [showModal, setShowModal] = useState(false);
    const [mentorDetails, setMentorDetails] = useState([]);
    const [businessIdeas, setBusinessIdeas] = useState([]);

    async function fetchMentorDetails() {
        const url = `${process.env.REACT_APP_SESSION_API_URL}/session-users/get-session-user`;
        // const config = { headers: { Authorization: AUTH_TOKEN } };
        // console.log('url ==== >>>', url, config);
        axios.get(url).then((response) => {
          if (response.status === 200 && response.data.error === false) {
            setMentorDetails(response.data.sessionUsers);
        console.log('response.data.sessionUsers', response.data.sessionUsers);
            }
        });
      }

      async function fetchBusinessIdeas() {
        const url = `${process.env.REACT_APP_SWATI_API_URL}/api/v1/bussinessIdeas/all-detailed`;
        axios.get(url).then((response) => {
            if (response.status) {
                setBusinessIdeas(response.data.bussinessIdeas || []);
            }
        });
    }

    useEffect(async () => {
        await fetchMentorDetails();
        await fetchBusinessIdeas();
    }, []);

    return (
        <div >
        <Content className="site-layout-background header" style={{ boxShadow: '2px 1px 10px lightgrey' }}>
                <p></p>
                <h3>Mentors</h3>
                <Button
                    onClick={() => { setShowModal(true); }}
                    shape="round"
                    style={{ background: 'tomato', color: 'white' }}
                >
                    Add Mentor
                </Button>
            </Content>

     <div className="libraryScreenMiddle">
  <MentorDetailsTableHeader mentorDetails = {mentorDetails}
   businessIdeas={businessIdeas}
  fetchMentorDetails={fetchMentorDetails} />
     </div>
            <MentorDetailsModal
                fetchMentorDetails={fetchMentorDetails}
                businessIdeas={businessIdeas}
                close={() => {
                    setShowModal(false);
                }}
                show={showModal}
            />
        </div>
        );
}

export default MentorshipEventScreen;
