/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';

import axios from 'axios';
import CryptoJS from 'crypto-js';
// import { Redirect } from 'react-router';
import { getCookie } from '../../cookie';
import { AlertMessage } from '../../Reusable/Alert';

const ChangePassword = () => {
  const [email, setEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [id, setId] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY_SECRET;

    // Encrypt
    const encryptedOldPassword = CryptoJS.AES.encrypt(
      oldPassword,
      encryptionKey,
    ).toString();

    const encryptedNewPassword = CryptoJS.AES.encrypt(
      newPassword,
      encryptionKey,
    ).toString();

    axios
      .post(`${process.env.REACT_APP_API_URL}/change-password`, {
        id,
        email,
        encryptedOldPassword,
        encryptedNewPassword,
      })
      .then((response) => {
        // console.log('response is : ', response.data);
        if (response.data.status === 'error') {
          // Display the message (response.message)
          // console.log(`Error is : ${response.data.message}`);
          return;
        }
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          window.location = '/';
        }, 2000);
      });
      // .catch((error) => {
      //   // console.log(`response is : ${error}`);
      // });
  };
  useEffect(() => {
    setId(JSON.parse(getCookie('id')) || null);
  }, []);

  return (
    <div className="login-div">
      {showAlert && <AlertMessage message="Password has been Changed" />}

      <form className="login-form">
        <div>
          <input
            id="email"
            type="email"
            placeholder="Enter email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div>
          <input
            id="old-password"
            placeholder="Enter Old Password"
            type="password"
            onChange={(e) => {
              setOldPassword(e.target.value);
            }}
          />
        </div>
        <div>
          <input
            id="new-password"
            placeholder="Enter New Password"
            type="password"
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
        </div>
        <button type="submit" onClick={submitHandler}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
