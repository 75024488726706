/* eslint-disable no-undef */
import React, { useState } from 'react';
import axios from 'axios';
import { AiOutlineHome, AiOutlineLogin } from 'react-icons/ai';
import { Button, message } from 'antd';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';

const ForgotPassword = () => {
    // eslint-disable-next-line no-undef
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const submitHandler = (e) => {
        e.preventDefault();
        const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY_SECRET;
        // // console.log(
        //   `Encryption key is ${process.env.REACT_APP_ENCRYPTION_KEY_SECRET}`,
        // );
        // Encrypt
        const encryptedPassword = CryptoJS.AES.encrypt(
            password,
            encryptionKey,
        ).toString();

        // // console.log(`Encrypted password is ${encryptedPassword}`,
        // `${process.env.REACT_APP_API_URL}login`);
        axios
            .post(`${process.env.REACT_APP_API_URL}/reset-password`, {
                encryptedPassword,
                token,
            })
            .then((response) => {
                // // console.log('response is : ', response.data);
                // message.info(response.data.status);
                if (!response.data.status) {
                    // Display the message (response.message)
                    // // console.log(`Error is : ${response.data.message}`);
                    message.error(response.data.message);
                    return;
                }
                // localStorage.setItem('token', response.data.token);
                // localStorage.setItem('userDetails', JSON.stringify(response.data.userDetails));
                // message.success(response.data.message);
                Swal.fire({
                    title: 'Done!',
                    text: response.data.message,
                    type: 'success',
                    // eslint-disable-next-line no-unused-vars
                }).then((done) => {
                    // eslint-disable-next-line no-undef
                    window.location.pathname = '';
                });
                // eslint-disable-next-line no-undef
                window.location = '/';
                // setCookie('role', JSON.stringify(response.data.role), 10);
                // setCookie('id', JSON.stringify(response.data.id), 10);
                // setCookie('name', JSON.stringify(response.data.name), 10);

                // <Redirect to="/" />;
                // // console.log(`Token is ${localStorage.getItem('token')}`);
                // if (localStorage.getItem('token') !== '') {
                //     window.location = '/';
                // }
            });
            // .catch((error) => {
            //     // console.log(`response is : ${error}`);
            // });
    };

    return (
        <div className="login-div">
            <form className="login-form">
                <h2>Reset Password :</h2>
                <div>
                    <input
                        id="password"
                        placeholder="Enter Password"
                        type="password"
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                    />
                </div>
                <div>
                    <input
                        id="confirm-password"
                        placeholder="Enter Password Again"
                        type="password"
                        onChange={(e) => {
                            setConfirmPassword(e.target.value);
                        }}
                    />
                </div>
                <button type="submit" className='reset-password' onClick={submitHandler} disabled={password !== confirmPassword || password.length < 8}>
                    Reset Password <AiOutlineLogin />
                </button>
                <note className='note'
                    style={{ marginTop: '2%' }}
                    // className='forgot-password'
                >Enter Password of at least 8 characters.
                    Password and Confirm password inputs should match.</note>
                <Button id='go-home' ><span onClick={() => { window.location = '/'; }}>Go back home <AiOutlineHome /></span></Button>
            </form>
        </div>
    );
};

export default ForgotPassword;
