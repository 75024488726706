/* eslint-disable no-undef */
/* eslint-disable no-useless-escape */
/* eslint-disable no-underscore-dangle */
import React, {
    useState,
    useEffect,
} from 'react';
import './LibraryScreen.css';
import {
    Layout,
    Breadcrumb,
    Button,
    Modal,
    Select,
    Form,
    Upload,
    Switch,
    Input,
    InputNumber,
    Spin,
} from 'antd';
import FormData from 'form-data';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';

import LibraryTable from '../../Components/LibraryTable/LibraryTable';
import availableLanguages from '../../Components/AvailableLanguages/AvailableLanguages';

const AUTH_TOKEN = localStorage.getItem('token');
const { Option } = Select;

function LibraryScreen() {
    const { Header, Content } = Layout;
    const INITIAL_TIME_DURATION = {
        hours: 0,
        minutes: 0,
        seconds: 0,
    };
    const [fileVisible, setfileVisible] = useState(false);
    const [fileConfirmLoading, setfileConfirmLoading] = useState(false);
    const [businessIdeas, setBusinessIdeas] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedBids, setSelectedBids] = useState([]);
    const [duration, setDuration] = useState(null);
    const [formValVisibility, setFormValVisibility] = useState(true);
    const [availableVideoForUpload, setavailableVideoForUpload] = useState(false);
    const [durationInTime, setDurationInTime] = useState(INITIAL_TIME_DURATION);
    const [urlExists, setUrlExist] = useState([]);
    const showFileModal = () => setfileVisible(true);
    const fileHandleCancel = () => setfileVisible(false);
    // const onFileFinishFailed = (errorInfo) => Modal.error({ content: errorInfo });

    const [fileLibrary, setfileLibrary] = useState([]);

    const { TextArea } = Input;
    const INITIAL_FILTER_VALUES = {
        status: '',
        language: '',
        type: '',
        visibility: '',
        level: '',
    };
    const [filterValues, setFilterValues] = useState(INITIAL_FILTER_VALUES);
    async function fetchLibrary(queryObj = filterValues) {
        // eslint-disable-next-line no-unused-vars
        let query = '';
        Object.keys(queryObj).forEach((param) => { query += queryObj[param] ? `${param}=${queryObj[param]}&` : ''; });
        const url = `${process.env.REACT_APP_LIBRARY_URL}/media?${query}`;
        const config = { headers: { Authorization: AUTH_TOKEN } };
        axios.get(url, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setfileLibrary(response.data.files);
            }
        });
    }
    useEffect(() => {
        fetchLibrary();
    }, []);

    async function checkIfUrlExists(fileUrl) {
        const config = {
            headers: { Authorization: AUTH_TOKEN },
            params: { url: fileUrl },
        };
        const url = `${process.env.REACT_APP_LIBRARY_URL}/media/check/url-exists`;
        axios.get(url, config).then((response) => {
            setUrlExist(response.data.existingFile);
            if (response.status === 200 && response.data.existingFile.length > 0) {
                Modal.error({ content: `URL already exists with video name ${response.data.existingFile[0].title}` });
            }
        });
    }

    const onFileFinish = (values) => {
        if (urlExists.length > 0) {
            Modal.error({ content: `URL already exists with video name ${urlExists[0].title}` });
            return;
        }
        setfileConfirmLoading(true);
        const url = `${process.env.REACT_APP_LIBRARY_URL}/media`;
        const config = {
            headers: { Authorization: AUTH_TOKEN },
            onUploadProgress: (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    const uploadPercentage = (progressEvent.loaded / progressEvent.total) * 100;
                    setavailableVideoForUpload(uploadPercentage);
                }
            },
        };
        const fileForm = new FormData();
        fileForm.append('title', values.title);
        fileForm.append('description', values.description);
        fileForm.append('language', values.language);
        fileForm.append('businessIdeas', (selectedBids || []));
        fileForm.append('category', values.category);
        if (formValVisibility) {
            fileForm.append('level', values.level);
            fileForm.append('status', values.status);
        } else {
            fileForm.append('level', 0);
            fileForm.append('status', '');
        }
        fileForm.append('type', values.type);
        if (values.keywords) {
            fileForm.append('keywords', values.keywords);
        }
        if (values.source) {
            fileForm.append('source', values.source);
        }
        fileForm.append('visibility', values.visibility);

        if (values.fileUrl) {
            fileForm.append('fileType', 'link');
            fileForm.append('fileUrl', values.fileUrl);
            const dur = durationInTime.hours * 60 * 60
                + durationInTime.minutes * 60 + durationInTime.seconds;
            fileForm.append('duration', dur || 0);
        } else {
            fileForm.append('duration', duration);
            if (values.uploadedFile.file.type.includes('audio')) fileForm.append('fileType', 'audio');
            else if (values.uploadedFile.file.type.includes('video')) fileForm.append('fileType', 'video');
            else fileForm.append('fileType', values.uploadedFile.file.type);
            fileForm.append('fileUrl', values.uploadedFile.file);
        }
        if (values.thumbnail && values.thumbnail.file) {
            if (values.thumbnail.file.type.includes('audio')) fileForm.append('thumbnail', 'audio');
            else if (values.thumbnail.file.type.includes('video')) fileForm.append('thumbnail', 'video');
            else fileForm.append('thumbnail', values.thumbnail.file.type);
            fileForm.append('thumbnail', values.thumbnail.file);
        } else fileForm.append('thumbnail', values.thumbnail || '');
        // // console.log(values, url, config);
        axios.post(url, fileForm, config).then((response) => {
            if (response.status === 200 && response.data.error === false) {
                setfileVisible(false);
                setfileConfirmLoading(false);
                fetchLibrary();
                setavailableVideoForUpload(false);
                Modal.success({ content: `${response.data.message}` });
            } else {
                fetchLibrary();
                setavailableVideoForUpload(false);
                Modal.error({ content: 'File Not Added, Please Try Again' });
            }
        }).catch((err) => {
            // console.log(err);
            setavailableVideoForUpload(false);
            fetchLibrary();
            Modal.error({ content: err.response.data.errorMessage });
        });
    };

    const props = {
        // accept: 'audio/*,video/*,image/*',
        accept: 'video/*',
        beforeUpload: () => false,
        listType: 'picture',
    };

    const thumbnailProps = {
        accept: 'image/*',
        beforeUpload: () => false,
        listType: 'picture',
    };

    async function fetchBusinessIdeas() {
        const url = `${process.env.REACT_APP_SWATI_API_URL}/api/v1/bussinessIdeas/all-detailed`;
        axios.get(url).then((response) => {
            if (response.status) {
                setBusinessIdeas(response.data.bussinessIdeas || []);
            }
        });
    }

    async function fetchKeywords() {
        const url = `${process.env.REACT_APP_LIBRARY_URL}/synonyms/keywords`;
        axios.get(url).then((response) => {
            if (response.status) {
                setKeywords(response.data.keywords);
            }
        });
    }

    async function fetchCategories() {
        const config = { headers: { Authorization: AUTH_TOKEN } };
        const url = `${process.env.REACT_APP_LIBRARY_URL}/category`;
        axios.get(url, config).then((response) => {
            if (response.status) {
                setCategories(response.data.categories);
            }
        });
    }

    useEffect(() => {
        fetchCategories();
        fetchKeywords();
        fetchBusinessIdeas();
    }, []);
    const [isUploadVideo, setisUploadVideo] = useState(false);
    function onUploadChecked(checked) {
        // if (checked) {
        setDurationInTime(INITIAL_TIME_DURATION);
        setDuration(null);
        // }
        setisUploadVideo(checked);
    }

    const handleSelectAll = (value) => {
        if (value.includes('all')) {
            const selected = businessIdeas?.map((x) => x._id);
            setSelectedBids(selected);
        } else if (value.includes('none')) {
            setSelectedBids([]);
        } else setSelectedBids(value);
    };

    return (
        <div>
            <Header
                style={{
                    padding: 0,
                    textAlign: 'center',
                    fontSize: 30,
                    fontWeight: 300,
                    background: 'white',
                }} >
                {'Video Library'}
            </Header>
            <Content style={{
                margin: '24px 16px 0',
                overflow: 'initial',
                background: '#fff',
                borderRadius: 10,
                boxShadow: '2px 1px 10px lightgrey',
            }}>
                <div className='libraryScreenContainer'>
                    <div className='libraryScreenTop'>
                        <div className='libraryScreenTopBreadcrumbContainer'>
                            <Breadcrumb>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Video Library</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Button
                            onClick={() => showFileModal()}
                            shape="round"
                            style={{ background: 'tomato', color: 'white' }}
                        >
                            Add File
                        </Button>
                    </div>
                    <div>
                        <Row
                            // style={{ margin: '0% 10% auto' }}
                            className='media-filter-container'>
                            <Col md={2}>
                                <Select style={{ width: '100px', marginRight: '5px' }} placeholder="Status" defaultValue={filterValues.status || undefined} value={filterValues.status || undefined} onChange={(e) => { setFilterValues({ ...filterValues, status: e }); }}>
                                    {
                                        ['Live', 'Draft', 'Hidden'].map((status) => <Option key={status} value={status}>{status}</Option>)
                                    }
                                </Select>
                            </Col>
                            <Col md={2}>
                                <Select
                                    style={{ width: '120px', marginRight: '5px' }}
                                    placeholder="Language"
                                    defaultValue={filterValues.language || undefined}
                                    value={filterValues.language || undefined}
                                    onChange={(e) => {
                                        setFilterValues({ ...filterValues, language: e });
                                    }}>
                                    {availableLanguages.map((status) => <Option
                                        key={status.text}
                                        value={status.value}
                                    >{status.value}</Option>)}
                                </Select>
                            </Col>
                            <Col md={2}>
                                <Select style={{ width: '120px', marginRight: '5px' }} placeholder="Type" defaultValue={filterValues.type || undefined} value={filterValues.type || undefined} onChange={(e) => { setFilterValues({ ...filterValues, type: e }); }}>
                                    {
                                        ['Demo/Walkthrough',
                                            // 'Interview',
                                            // 'Informational',
                                            'Knowledge sharing', 'Informative/educational', 'Interview'].map((status) => <Option key={status} value={status}>{status}</Option>)
                                    }
                                </Select>
                            </Col>
                            <Col md={2}>
                                <Select style={{ width: '120px', marginRight: '5px' }} placeholder="Visibility" defaultValue={filterValues.visibility || undefined} value={filterValues.visibility || undefined} onChange={(e) => { setFilterValues({ ...filterValues, visibility: e }); }}>
                                    {
                                        ['Public', 'Restricted'].map((status) => <Option key={status} value={status}>{status}</Option>)
                                    }
                                </Select>
                            </Col>
                            <Col md={1}>
                                <Select style={{ width: '60px', marginRight: '5px' }} placeholder="Level" defaultValue={filterValues.level || undefined} value={filterValues.level || undefined} onChange={(e) => { setFilterValues({ ...filterValues, level: e }); }}>
                                    {
                                        [1, 2, 3, 4, 5, 6, 7].map(
                                            (status) => <Option key={status} value={status}>
                                                {status}
                                            </Option>,
                                        )
                                    }
                                </Select>
                            </Col>
                            <Col md={1} style={{ width: '60px', marginRight: '40px' }}>
                                <Button style={{ background: 'tomato', color: 'white' }} onClick={() => { fetchLibrary(filterValues); }}
                                    size='middle'>
                                    Submit
                                </Button>
                            </Col>
                            <Col md={1}>
                                <Button color='red' size='middle' style={{ background: 'red', color: 'white' }} onClick={() => { setFilterValues(INITIAL_FILTER_VALUES); fetchLibrary(INITIAL_FILTER_VALUES); }} >
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <div className='libraryScreenMiddle'>
                        <LibraryTable fileLibrary={fileLibrary}
                            fetchLibrary={fetchLibrary}
                            businessIdeas={businessIdeas}
                            fetchBusinessIdeas={fetchBusinessIdeas}
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            INITIAL_FILTER_VALUES={INITIAL_FILTER_VALUES}
                            keywords={keywords}
                            categories={categories}
                        />
                    </div>
                </div>
            </Content>
            <Modal
                title="Add File"
                visible={fileVisible}
                confirmLoading={fileConfirmLoading}
                onCancel={fileHandleCancel}
                footer={null}
                getContainer={false}
                width={1000}
            >
                {availableVideoForUpload
                    && <Spin size="large" style={{
                        transform: 'scale(4)',
                        position: 'absolute',
                        top: '50%',
                        left: '45%',
                        zIndex: 1,
                    }} tip='Uploading...' />}
                <Form
                    name="basic"
                    onFinish={onFileFinish}
                    // onFinishFailed={onFileFinishFailed}
                    layout={'vertical'}
                >
                    <fieldset disabled={availableVideoForUpload}>
                        <Form.Item name="title" label="Video Title (Max 100 Characters)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter a new name!',
                                },
                                // {
                                //     len: 100,
                                //     message: 'Only 100 characters are allowed',
                                // },
                            ]}
                        >
                            <Input maxLength={100}/>
                        </Form.Item>
                        <Form.Item name="description" label="Video Description (Max 1000 Characters)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Description is mandatory',
                                },
                            ]}
                        >
                            <TextArea rows={4} maxLength={1000}/>
                        </Form.Item>
                        <Row>
                            <Col md={formValVisibility ? 3 : 6} lg={formValVisibility ? 3 : 6}>
                                <Form.Item name="language" label="Language"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select a language!',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select a option and change input text above"
                                        allowClear
                                    >
                                        {
                                            availableLanguages.map((language, index) => <Option
                                                value={language.value}
                                                key={index}>{language.text}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={formValVisibility ? 3 : 6} lg={formValVisibility ? 3 : 6}>
                                <Form.Item name="visibility" label="Visibility"
                                    rules={[{ required: true, message: 'Please select video visibility' }]}
                                >
                                    <Select
                                        placeholder="Select video visibility"
                                        allowClear
                                        onChange={(val) => setFormValVisibility(val === 'Public')}
                                    >
                                        {['Public', 'Restricted'].map((visibility) => (
                                            <Option key={visibility} label={visibility}
                                                title={visibility}
                                                value={visibility}>{visibility}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {formValVisibility && <><Col md={3} lg={3}>
                                <Form.Item name="level" label="Level"
                                    rules={[{ required: formValVisibility, message: 'Please select a level' }]}
                                >
                                    <Select
                                        placeholder="Select business level"
                                        allowClear
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7].map((level) => (
                                            <Option key={level} label={level}
                                                title={level}
                                                value={level}>{`Level - ${level}`}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                                <Col md={3} lg={3}>
                                    <Form.Item name="type" label="Type"
                                        rules={[{ required: formValVisibility, message: 'Please select video type' }]}
                                    >
                                        <Select
                                            placeholder="Select video type"
                                            allowClear
                                        >
                                            {['Demo/Walkthrough',
                                                // 'Interview',
                                                // 'Informational',
                                                'Knowledge sharing', 'Informative/educational', 'Interview'].map((type) => (
                                                    <Option key={type} label={type}
                                                        title={type}
                                                        value={type}>{type}</Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col></>}
                        </Row>
                        {formValVisibility && <>
                            {/* <Form.Item name="businessIdeas" label="Business Ideas"
                            rules={[{ required: formValVisibility, message:
                                'Please select at least one
                                business idea that goes with this video.' }]}
                        > */}
                            <p className='mb-1'><span style={{ color: 'red' }}>* </span>Business Ideas</p>
                            <Select
                                style={{ width: '100%', marginBottom: 20 }}
                                mode="multiple"
                                placeholder="Select one or more business ideas that apply"
                                allowClear
                                value={selectedBids}
                                onChange={handleSelectAll}
                                filterOption={(input, option) => {
                                    console.log('🚀 ~ file: LibraryScreen.js:492 ~ LibraryScreen ~ input, option:', input, option);
                                    return ((option?.label)?.toLowerCase() ?? '').includes((input).toLowerCase());
                                }}
                            >
                                <Option value='all' key='all' >{'Select All'}</Option>
                                <Option value='none' key='none' >{'Deselect All'}</Option>
                                {businessIdeas.map((idea) => (
                                    <Option key={idea.combine} label={idea.inEnglish}
                                        title={idea.inEnglish}
                                        value={idea._id}>{idea.inEnglish}</Option>
                                ))}
                            </Select>
                            {/* </Form.Item> */}
                            <Row>
                                <Col md={6} lg={6}>
                                    <Form.Item name="status" label="Status"
                                        rules={[
                                            {
                                                required: formValVisibility,
                                                message: 'Enter a new status!',
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select a option"
                                            allowClear
                                        >
                                            <Option value='live'>
                                                Live
                                            </Option>
                                            <Option value='draft'>
                                                Draft
                                            </Option>
                                            <Option value='hidden'>
                                                Hidden
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={6} lg={6}>
                                    <Form.Item name="category" label="Category" >
                                        <Select
                                            mode='multiple'
                                            placeholder="Select a category"
                                            allowClear
                                        >
                                            {categories.map((cat) => <Option
                                                key={cat._id}
                                                value={cat._id}
                                            >
                                                {cat.category.english}
                                            </Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item name="keywords" label="Groups"
                                rules={[{ required: true, message: 'Add some Groups' }]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select one or more Groups"
                                    allowClear
                                >
                                    {keywords.map((word) => (
                                        <Option key={word._id} value={word._id}>
                                            {word.keyword}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="source" label="Source"
                            // rules={[{ required: true, message: 'Add some keywords' }]}
                            >
                                <Input />
                            </Form.Item>
                        </>}
                        <Form.Item
                            label="Thumbnail (1280 x 720p)"
                            name="thumbnail"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Enter a new url!',
                        //     },
                        // ]}
                        >
                            <Upload listType="picture-card" {...thumbnailProps} >
                                <Button icon={<UploadOutlined />}>Upload Thumbnail</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <div style={{ paddingRight: 10 }}>File Url</div>
                                <Switch onChange={onUploadChecked} />
                                <div style={{ paddingLeft: 10 }}>File Upload</div>
                            </div>
                        </Form.Item>
                        {
                            isUploadVideo
                                ? <Form.Item
                                    label="File Upload"
                                    name="uploadedFile"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter a new file!',
                                        },
                                    ]}
                                >
                                    <Upload
                                        {...props}
                                        onChange={(e) => {
                                            const { file } = e;
                                            if (file && file.status !== 'removed') {
                                                const reader = new FileReader();
                                                reader.onload = function () {
                                                    const aud = new Audio(reader.result);
                                                    aud.onloadedmetadata = function () {
                                                        const videoDuration = aud.duration;
                                                        // console.log('Duration:', videoDuration);
                                                        setDuration(videoDuration);
                                                    };
                                                };
                                                reader.readAsDataURL(file);
                                            } else {
                                                setDuration(null);
                                            }
                                        }}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </Form.Item>
                                : <Form.Item
                                    label="File Url"
                                    name="fileUrl"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter a new url!',
                                            pattern: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig,
                                        },
                                    ]}
                                >
                                    <Input onBlur={(e) => checkIfUrlExists(e.target.value)} />
                                </Form.Item>
                        }
                        {
                            isUploadVideo ? <Row>
                                <Col md={3}>Duration (in seconds): </Col><Col md={9}>
                                    <InputNumber
                                        disabled defaultValue={0}
                                        value={duration || 0} /></Col></Row> : <Form.Item
                                            label="Video Duration"
                                            name="duration"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter video duration!',
                                                    // _\+~#=]{2, 256}\.[a-z]{2, 6}\b([-a-zA-Z0-
                                                    // 9@:%_\+.~#?&//=]*)/ig,
                                                },
                                            ]}
                                        >
                                <Col><InputNumber
                                    min={0} max={12} defaultValue={durationInTime.hours}
                                    onChange={(e) => {
                                        setDurationInTime({ ...durationInTime, hours: e });
                                    }} /> h:
                                    <InputNumber
                                        min={0} max={59}
                                        defaultValue={durationInTime.minutes}
                                        onChange={(e) => {
                                            setDurationInTime({ ...durationInTime, minutes: e });
                                        }} /> m :
                                    <InputNumber
                                        min={0} max={59}
                                        defaultValue={durationInTime.seconds}
                                        onChange={(e) => {
                                            setDurationInTime({ ...durationInTime, seconds: e });
                                        }} /> s</Col>
                            </Form.Item>
                        }
                        <Form.Item>
                            <Row justify="end" style={{ justifyContent: 'flex-end' }}>
                                <Button
                                    htmlType="submit"
                                    shape="round"
                                    style={{
                                        background: 'tomato',
                                        color: 'white',
                                        height: 35,
                                        width: 150,
                                        margin: 30,
                                        marginBottom: 0,
                                    }}
                                >
                                    Save
                                </Button>
                            </Row>
                        </Form.Item>
                    </fieldset>
                </Form>
            </Modal>
        </div>
    );
}

export default LibraryScreen;
